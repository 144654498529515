import { useEffect } from "react";
import { useNavigate } from "react-router";

const SettingsPage = ({selectedOrgId}) => {

  const navigate = useNavigate();

  useEffect(() => {
    const orgId = selectedOrgId;
    navigate(`/settings/organisation/edit/${selectedOrgId}`);
  }, [navigate]);

  return null;
};

export default SettingsPage;
