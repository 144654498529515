import { useEffect, useState } from "react";
import { WBButton, WBCollector, WBDropdown, WBDropdownOption, WBIOSToggle } from "../../../../Components/UIComponents/WBComponents";
import { PostDB } from "../../../../Components/DBTools";
import { toast } from "react-toastify";
import { validateStr, validateInt } from "../../../../lib/js/validateInput";

const AddFilter = ({ closeModalMethod, preloadIndustryId=false, returnFunc }) => {
    const [industry, setIndustry] = useState(0);
    const [industriesFormatted, formatIndustries] = useState([]);
    const [filtName, setFiltName] = useState();
    const [allFilters, setAllFilters] = useState();
    const [selectedSubFilters, setSelectedSubFilters] = useState([]);
    const [active, setActive] = useState(true);
    const [changesMade, setChangesMade] = useState(false);

    const toggleStatus = () => setActive(status => { setChangesMade(true); return !status });

    const GetIndustries = async () => {
        const response = await PostDB({
            branch: "/settings/industries",
            json: {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }
            }
        });
        if (response !== undefined && response !== null) {
            formatIndustries(
                response.map((ind) => (
                    <option key={ind.id} value={ind.id}>{ind.department}</option>
                ))
            );
            if (preloadIndustryId) {
                setIndustry(preloadIndustryId);
            }
        }
    }

    useEffect(() => {
        GetIndustries();
    }, []);

    const Save = async () => {
        if (!validateInt("Trade", industry)) return;
        if (!validateStr("Name", filtName)) return;

        let bodyObj = {
            filter_id: null,
            filter_name: filtName,
            trade_id: industry, // I dont think they are bound by trade????
            sub_filters: selectedSubFilters.map((subFilter) => ({
                sub_filter_id: subFilter.id,
                sub_filter_name: subFilter.name,
                status: 1
            })),
            description: "",
            active: active === true ? 1 : 0
        }
        let response = await PostDB({
            branch: "/editFilter", json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(bodyObj)
            }
        });
        if (response === "success") {
            toast.success("Filter has been successfully added");
            if (returnFunc) returnFunc();
            closeModalMethod();
        }
        else {
            toast.error("There was an error saving this Filter. Check your fields and try again");
        }
    }

    const handleBGClick = (e) => {
        const background = document.querySelector(".bg-darkblue");
        if (background === e.target && !changesMade) {
            closeModalMethod();
        }
    }

    return (
        <div className="add-utility-page">
            <div className="bg-darkblue" onClick={(e) => handleBGClick(e)}>
                <div className="content">
                    <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="button-row left">
                            <h4 className="title"><b>Add Filter</b></h4>
                        </div>
                    </div>
                    <div className="body">
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="filtName" className="form-label">
                                        Trade
                                    </label>
                                    <select className="cat_select" name="selTrade" value={industry} style={{ width: "300px" }} onChange={(selected) => {
                                            setChangesMade(true); 
                                            setIndustry(selected.target.value);
                                        }}>
                                        <option value="0" disabled>None</option>
                                        {industriesFormatted.reverse()}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="hr" />
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="filtName" className="form-label">
                                        Filter Name
                                    </label>
                                    {/* <p>Max 20 Characters (Remaining 20)</p> */}
                                    <input
                                        type="text"
                                        name="filtName"
                                        id="filtName"
                                        placeholder="enter filter name"
                                        className="half"
                                        value={filtName}
                                        onChange={(e) => { setChangesMade(true); setFiltName(e.target.value) }}
                                        autoFocus
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="hr" />
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="subFilters" className="form-label">
                                        Sub Filters
                                    </label>

                                    <div className={"pt-10"}>
                                        <WBCollector allCollectorItems={allFilters} setAllCollectorItems={setSelectedSubFilters} selectedTitle="Sub Filter" returnFunc={(e) => { setSelectedSubFilters(e) }} changeMade={(e) => { setChangesMade(true)}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer flex flex-row space-between" style={{ flexWrap: "wrap" }}>
                        <div className="flex flex-row gap-10" style={{ alignItems: "center"}}>
                            <p>Status</p>
                            <WBIOSToggle active={active} func={() => toggleStatus()} />
                        </div>
                        <div className="flex flex-row gap-10">
                            <WBButton title="Cancel" color="white" func={() => { if (!changesMade) closeModalMethod(); else { if (window.confirm("You are about to leave this page? Are you sure?")) closeModalMethod() } }} />
                            <WBButton title="Save" color={"green"} func={() => Save()} />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AddFilter;