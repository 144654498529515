import { useEffect, useState } from "react";
import ServiceItems from "../../../Components/Settings/Services/ServiceItems.js";
import AddService from "./Add/AddService.js";
import EditService from "./Edit/EditService.js";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents.js";
import { useNavigate } from "react-router-dom";

const ServicesPage = () => {
	const [tableData, setTableData] = useState();

	const [industry, setIndustry] = useState();
	const [category, setCategory] = useState();
	const [subCategory, setSubCategory] = useState();

	const [itemRemoved, setItemRemoved] = useState(false);

	const headers = ["Image", "Code", "Service", "Price Range", "Status", "Action"];

	const navigate = useNavigate();
	const toAddService = () => {
		navigate("add", { state: { preload: [industry, category, subCategory] } });
	};
	const toEditService = (id) => {
		navigate(`edit/${id}`, { state: { preload: [industry, category, subCategory] } });
	};

	useEffect(() => {
		setTableData(
			<WBTable tableData="/servicesJson" tableHeaders={headers} services servicesReturn={(e) => { setIndustry(e.industry); setCategory(e.category); setSubCategory(e.subCategory); }}>
				<ServiceItems editFunc={(id) => toEditService(id)} setItemRemoved={setItemRemoved}/>
			</WBTable >
		);
	}, [industry, category, subCategory, itemRemoved]);

	return (
		<div className="content-area-full">
			<div className="flex floating-box flex-column bg-white gap-20" style={{ padding: "42px 36px 35px 36px"}}>
				<div className="flex justify-between">
					<div className="text-base">
						<b>Services</b>
					</div>
					<WBButton title="Add New Service" color="green" func={toAddService} />
				</div>
				{tableData}
			</div>
		</div>
	);
};

export default ServicesPage;