import { useState, useEffect } from "react";
import TableItems from "../../../Components/UIComponents/TableItems";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import AddUtility from "./Add/AddUtility";
import EditUtility from "./Edit/EditUtility";

const UtilitiesPage = () => {
  const [tableData, setTableData] = useState();
  const [refreshTable, setRefreshTable] = useState(false);

  const [isModalAddOpen, setIsModal1Open] = useState(false);
  const [isModalEditOpen, setIsModal2Open] = useState(false);

  const toggleAddModal = () => { setIsModal1Open(!isModalAddOpen); }
  const toggleEditModal = () => { setIsModal2Open(!isModalEditOpen); }

  const [editedID, setEditedID] = useState();

  const headers = ["Code", "Title", "Description", "Cost", "Status", "Action"];

  useEffect(() => {
    setTableData(
      <WBTable tableData="/utilitiesJson" tableHeaders={headers} >
          <TableItems 
            itemTitle="Utility" itemsAt="utilities" identifiers={["code", "title", "description", "cost"]} removeBranch="/removeUtility"
            editFunc={toggleEditModal} setEditedID={setEditedID} setRefreshTable={setRefreshTable}
          />
        </WBTable>
    );

    // <td style={{ padding: "8px 36px" }}>
    //   <div className="serviceCompanyLogo">
    //       <img
    //           src={utility.image ? utility.image : compDefault}
    //           alt="Thumbnail"
    //           width={utility.image ? "95%" : "50%"}
    //       />
    //   </div>
    // </td>
  }, [refreshTable]);

  return (
    <div className="content-area-full">
      {isModalAddOpen && <AddUtility closeModalMethod={toggleAddModal} returnFunc={() => {
        setRefreshTable(!refreshTable)
      }}/>}
      {isModalEditOpen && <EditUtility closeModalMethod={toggleEditModal} editedID={editedID} returnFunc={() => {
        setRefreshTable(!refreshTable)
      }}/>}
      <div className="flex floating-box flex-column bg-white gap-20" style={{ padding: "42px 36px 35px 36px"}}>
        <div className="flex justify-between">
          <div className="text-base">
            <b>Utilities</b>
          </div>
          <WBButton title="Add Utility" color="white" func={toggleAddModal} />
        </div>
        {tableData}
      </div>
    </div>
  );
};

export default UtilitiesPage;