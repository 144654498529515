import { useState, useEffect } from "react";
import TableItems from "../../../Components/UIComponents/TableItems";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import AddFilter from "./Add/AddFilter";
import EditFilter from "./Edit/EditFilter";
import { PostDB } from "../../../Components/DBTools";

const FiltersPage = () => {
  const [tableData, setTableData] = useState();
  const [refreshTable, setRefreshTable] = useState(false);

  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);

  const toggleAddModal = () => { setIsModalAddOpen(!isModalAddOpen); } 
  const toggleEditModal = () => { setIsModalEditOpen(!isModalEditOpen); };

  const [editedID, setEditedID] = useState();
  const [industry, setIndustry] = useState();

  const headers = ["Filter name", "Industry", "Status", "Action"];

  const [allIndustries, setAllIndustries] = useState(null);
  const GetIndustries = async () => {
      let response = await PostDB({
          branch: "/settings/industries",
          json: {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              }
          }
      });
      if (response !== null) {
          setAllIndustries(response);
      }
  }

  useEffect(() => {
    GetIndustries();
  }, []);

  useEffect(() => {
    setTableData(
      <WBTable tableData="/getAllFilters" tableHeaders={headers} cateFilter servicesReturn={(e) => { setIndustry(e.industry); }}>
          <TableItems 
            itemTitle="Filter" itemsAt="filters" identifiers={["name", "industry"]} removeBranch="/removeFilter"
            editFunc={toggleEditModal} setEditedID={setEditedID} setRefreshTable={setRefreshTable}
            allIndustries={allIndustries}
          />
      </WBTable>
    );
  }, [refreshTable, allIndustries]);


  return (
    <div className="content-area-full">
      {isModalAddOpen && <AddFilter closeModalMethod={toggleAddModal} preloadIndustryId={industry} returnFunc={() => {
        setRefreshTable(!refreshTable)
      }}/>}
      {isModalEditOpen && <EditFilter closeModalMethod={toggleEditModal} editedID={editedID} returnFunc={() => {
        setRefreshTable(!refreshTable)
      }}/>}

      <div className="flex floating-box flex-column bg-white gap-20" style={{ padding: "42px 36px 35px 36px"}}>
        <div className="flex justify-between">
          <div className="text-base">
            <b>Filters</b>
          </div>
          <div className="flex gap-10">
            <WBButton title="Add Filter" func={toggleAddModal} color={"white"} />
          </div>
        </div>
        {tableData}
      </div>
    </div>
  );
};

export default FiltersPage;
