import styles from './css/MiniTechProfileModal.module.css';
import { WBButton } from '../../Components/UIComponents/WBComponents';
import { useEffect } from 'react';
import { useSchedule } from './context/ScheduleContext';

const MiniTechProfileModal = () => {
  const { techProfileModal, closeTechProfileModal, openSmsModal } = useSchedule();
  const { isOpen, techData, position } = techProfileModal;

  const {
    iconMessage,
    modalOverlay,
    modalContainer,
    profileHeader,
    profileImageContainer,
    profileImage,
    profileDetails,
    nameSection,
    name,
    viewProfile,
    roleSection,
    role,
    content,
    balanceSection,
    sectionTitle,
    sectionValue,
    mobileSection,
    emailSection,
    actionButtons,
    status,
  } = styles;

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const handleOverlayClick = e => {
    if (e.target === e.currentTarget) {
      closeTechProfileModal();
    }
  };

  const handleSMSClick = () => {
    openSmsModal(techData, position);
  };

  if (!isOpen || !position) return null;

  // Calculate modal position
  const modalHeight = 580;
  const windowHeight = window.innerHeight;

  // Center vertically and adjust based on click position
  let top = Math.max(
    20,
    Math.min(
      (windowHeight - modalHeight) / 2 + (position.top - windowHeight / 2) * 0.2,
      windowHeight - modalHeight - 20
    )
  );

  return (
    <div className={modalOverlay} onClick={handleOverlayClick} style={{ pointerEvents: 'auto' }}>
      <div
        className={modalContainer}
        style={{
          top: `${top}px`,
          left: `${position.left}px`,
        }}
        onClick={e => e.stopPropagation()}
      >
        <div className={profileHeader}>
          <div className={profileImageContainer}>
            <img src="/images/scheduler/iconModal.svg" alt="user_icon" className={profileImage} />
          </div>
          <div className={profileDetails}>
            <div className={nameSection}>
              <h2 className={name}>{techData?.name}</h2>
              <button className={viewProfile}>View Profile</button>
            </div>
            <div className={roleSection}>
              <p className={role}>{techData?.role}</p>
            </div>
          </div>
        </div>

        <div className={content}>
          <div className={balanceSection}>
            <h3 className={sectionTitle}>Account Balance</h3>
            <p className={sectionValue}>${1432.42}</p>
            <WBButton
              title="View Cash Account"
              color="grey"
              styles={{ marginTop: '16px', padding: '12px 0' }}
              width="100%"
            />
          </div>

          <div className={mobileSection}>
            <h3 className={sectionTitle}>Mobile Number</h3>
            <p className={sectionValue}>0404387899</p>
          </div>

          <div className={emailSection}>
            <h3 className={sectionTitle}>Work Email</h3>
            <p className={sectionValue}>k.armiento@servicetoday.com.au</p>
          </div>

          <div className={actionButtons}>
            <WBButton
              icon={<i className={iconMessage} />}
              title="SMS Technician"
              color="grey"
              styles={{ padding: '12px' }}
              width="100%"
              func={handleSMSClick}
            />
            <WBButton
              title="Assign Apprentice"
              color="grey"
              styles={{ padding: '12px 0' }}
              width="100%"
            />
          </div>

          <p className={status}>5 years since active</p>
        </div>
      </div>
    </div>
  );
};

export default MiniTechProfileModal;
