import { useEffect, useState } from "react";
import { PostDB, CallDB } from "../../../../Components/DBTools";
import { WBButton, WBIOSToggle } from "../../../../Components/UIComponents/WBComponents";
import { WBModal, WBDragDropCSV } from "../../../../Components/UIComponents/WBComponents";
import { toast } from "react-toastify";
import { validateInt, validateStr } from "../../../../lib/js/validateInput";

const AddMaterials = ({ closeModalMethod, returnFunc, supplierId=0 }) => {
    const [matTitle, setMatTitle] = useState();
    const [matCost, setMatCost] = useState();
    const [matDesc, setMatDesc] = useState("");
    const [matSupplier, setMatSupplier] = useState(supplierId);
    const [allSuppliers, setAllSuppliers] = useState([]);
    const [suppliersFormatted, setSuppliersFormatted] = useState();
    const [supTitle, setSupTitle] = useState();
    

    const [csvImportStatus, setCsvImportStatus] = useState(false);
    const [addNewSupplierStatus, setAddNewSupplierStatus] = useState(false);
    
    const [changesMade, setChangesMade] = useState(false);
    const [uploadFileModal, setUploadFileModal] = useState(false);

    const [filesToSend, setFilesToSend] = useState([]);
    const [tempUploadedFiles, setTempUploadedFiles] = useState([]);

    const toggleCsvImport = () => {
        setCsvImportStatus(!csvImportStatus);

    };
    const toggleAddNewSupplier = async () => {
        if (addNewSupplierStatus) {
            if (!supTitle) {
                toast.error("Input a supplier name");
                return;
            }
            let response = await PostDB({
                branch: "/addsupplier", json: {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        name: supTitle,
                    })
                }
            });
  
            if (response.status === "success") {
                toast.success("Material has been successfully updated");
                setMatSupplier(response.inserted_supplier_id);
                setSupTitle();
                GetSuppliers();
                setAddNewSupplierStatus(!addNewSupplierStatus);      
            }
            else {
                toast.error("There was an error saving this Material. Check your fields and try again");
            }
        } else {
            setAddNewSupplierStatus(!addNewSupplierStatus);
        }
    };

    const GetSuppliers = async () => {
        let response = await PostDB({
            branch: "/getAllSuppliers", json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        });
        if (response !== undefined && response !== null) {
            setAllSuppliers(response.suppliers);
        }
    };

    useEffect(() => {
        GetSuppliers();
    }, []);

    const handleFileInput = async (files) => {
		const maxSize = 5 * 1024 * 1024;

		if (files.length > 0) {
			for (let i = 0; i < files.length; i++) {
				const file = files[i];
				if (file && file.size < maxSize) {
                    setFilesToSend((oldArray) => [...oldArray, file]);
				}
				else {
					toast.error("An error occurred while trying to upload your file, or you exceeded the file size limit (5MB). Please try again.");
				}
			}
		}
		else {
			toast.error("No files selected or invalid file type. ");
		}
	}

    const Save = async () => {
        if (!validateInt("Supplier", matSupplier)) return;
        if (!validateStr("Name", matTitle)) return;
        if (!validateStr("Cost", matCost)) return;

        if (csvImportStatus) {
            saveCsvMaterial();
        } else {
            let bodyObj = {
                id: null,
                supplier_id: matSupplier,
                code: null,
                title: matTitle,
                description: matDesc,
                cost: matCost
            }
            // let response = "Success";
            let response = await PostDB({
                branch: "/editMaterial", json: {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(bodyObj)
                }
            });
  
            if (response.api_status === "success") {
                toast.success("Material has been successfully updated");
                const newItem = { id: response.material_id, name: matTitle, price: matCost, supplier: allSuppliers.find(sup => sup.id === parseInt(matSupplier))?.name };
                if (returnFunc) returnFunc(newItem);
                closeModalMethod();
            }
            else {
                toast.error("There was an error saving this Material. Check your fields and try again");
            }
        }
    }

    const saveCsvMaterial = async () => {
    
        if (filesToSend.length == 0) {
            toast.error("No files selected or invalid file type. ");
            return
        }

        for (let i = 0; i < filesToSend.length; i++) {
            const file = filesToSend[i];
            if (file) {
                const formData = new FormData();
                formData.append('supplier_id', matSupplier)
                formData.append('name', file.name)
                formData.append('file', file)

                let response = await fetch("https://dev.wurkbox.com.au/settings/upload_material", {
                    method: "POST",
                    body: formData
                })

                if (response.status == 200) {
                    toast.success("Material has been successfully updated");
                    setTempUploadedFiles([])
                    closeModalMethod();
                }
                else {
                    toast.error("There was an error saving this Material. Check your fields and try again");
                }
            } else {
                toast.error("An error occurred while trying to upload your file, or you exceeded the file size limit (5MB). Please try again.");
                return;
            }
        }

        

    }

    useEffect(() => {
        if (allSuppliers.length > 0) {
            setSuppliersFormatted(
                allSuppliers.map((supplier, i) =>
                    <option key={i} value={supplier.id}>{supplier.name}</option>
                )
            )
        }
    }, [allSuppliers]);

    const handleBGClick = (e) => {
        const background = document.querySelector(".bg-darkblue");
        if (background === e.target && !changesMade) {
            closeModalMethod()
        }
    }

    return (
        <div className="add-utility-page">
            
            <div className="bg-darkblue" onClick={(e) => handleBGClick(e)}>
                <div className="content">
                    {uploadFileModal &&
                        <WBModal title={"Upload CSV"} closeFunction={() => { setUploadFileModal(toggle => !toggle); setTempUploadedFiles([]);}}  
                            submitFunction={async () => { 
                                await handleFileInput(tempUploadedFiles);
                                if (tempUploadedFiles.length > 0) {
                                    setUploadFileModal(toggle => !toggle); 
                                    setTempUploadedFiles([]);

                                }
                        }}>
                            <div className="gap-30 p-40">
                                <WBDragDropCSV tempFiles={tempUploadedFiles} handleInput={(e) => {
                                    setTempUploadedFiles(existingFiles => [...existingFiles, ...e]);
                                    setChangesMade(true); 
                                }} />
                            </div>
                        </WBModal>
                    }

                    <div className="border-bottom bg-white p-20 gap-10" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="button-row left">
                            <h4 className="title"><b>Add Material</b></h4>
                        </div>
                    </div>
                    <div className="body">
                        <div className="row">
                            <div className="col">
                                <div className="flex flex-column gap-10">
                                    <label className="form-label">
                                        Supplier
                                    </label>
                                    <div className="flex flex-row flex-wrap gap-10">
                                        <select className="cat_select" name="sel-industry" id="sel-industry" value={matSupplier} style={{ width: "18em" }} onChange={(selected) => {
                                                setMatSupplier(selected.target.value); 
                                                setChangesMade(true);
                                            }}>
                                            <option value="0" disabled>None</option>
                                            {suppliersFormatted}
                                        </select>
                                    </div>
                                    <button style={{ color: "#0094FF", background: "none", border: "none", cursor: "pointer", marginRight: "auto", fontSize: "14px", paddingTop: addNewSupplierStatus ? "30px" : "10px" }} onClick={toggleAddNewSupplier}>ADD NEW SUPPLIER</button>
                                    {addNewSupplierStatus && <div className="flex flex-row gap-10">
                                        <input
                                            type="text"
                                            name="supName"
                                            className="half"
                                            id="supName"
                                            placeholder="Enter supplier name"
                                            defaultValue={supTitle}
                                            onChange={(e) => { setChangesMade(true); setSupTitle(e.target.value) }}
                                        />
                                        <WBButton title="Save" color="blue" func={() => toggleAddNewSupplier()} />
                                    </div> }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input flex">
                                <label htmlFor="filtActive" className="form-label" style={{marginTop: "10px"}}>
                                        Import Material Catalogue CSV
                                </label>
                                <div className="p-10">
                                    <WBIOSToggle active={csvImportStatus} func={() => toggleCsvImport()} />
                                </div>
                            </div>
                        </div>

                        {!csvImportStatus && (<div >
                                <div className="row">
                                    <div className="col">
                                        <div className="input">
                                            <label htmlFor="matName" className="form-label">
                                                Material Name
                                            </label>
                                            {/* <p>Max 20 Characters (Remaining 20)</p> */}
                                            <input
                                                type="text"
                                                name="matName"
                                                className="half"
                                                id="matName"
                                                placeholder="enter material name"
                                                defaultValue={matTitle}
                                                onChange={(e) => { setChangesMade(true); setMatTitle(e.target.value) }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="input">
                                            <label htmlFor="matDesc" className="form-label">
                                                Material Description
                                            </label>
                                            {/* <p>Max 20 Characters (Remaining 20)</p> */}
                                            <textarea
                                                name="matDesc"
                                                className="half"
                                                id="matDesc"
                                                placeholder="enter material description"
                                                defaultValue={matDesc}
                                                rows={"5"}
                                                onChange={(e) => { setChangesMade(true); setMatDesc(e.target.value) }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="input">
                                            <label htmlFor="matCost" className="form-label">
                                                Material Cost
                                            </label>
                                            {/* <p>Max 20 Characters (Remaining 20)</p> */}
                                            <input
                                                type="number"
                                                name="matCost"
                                                className="half"
                                                id="matCost"
                                                placeholder="enter material cost"
                                                defaultValue={matCost}
                                                onChange={(e) => { setChangesMade(true); setMatCost(e.target.value) }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {csvImportStatus && (<div style={{paddingLeft: "20px"}}>
                            <div className="flex flex-column gap-15">
                                <WBButton title="Upload CSV" width="200px" color="blue" func={() => setUploadFileModal(toggle => !toggle)} />

                                {filesToSend.length > 0 && <div>
                                    {Array.from(filesToSend).map((file, index) => (
                                        <p style={{padding: "5px"}}>{file.name}</p>
                                    ))}
                                </div>}
                            </div>
                        </div>)}
                    </div>
                    <div className="footer flex flex-row space-between" style={{ flexWrap: "wrap" }}>
                        <div className="flex flex-row gap-10" style={{ alignItems: "center"}}>
                        </div>
                        <div className="flex flex-row gap-10">
                            <WBButton title="Cancel" color="white" func={() => { if (!changesMade) closeModalMethod(); else { if (window.confirm("You are about to leave this page? Are you sure?")) closeModalMethod() } }} />
                            <WBButton title="Save" color={"green"} func={() => Save()} />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default AddMaterials;