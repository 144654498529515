/* eslint-disable react/style-prop-object */
import { useState, useEffect, useCallback, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CallDB, PostDB } from "../../../Components/DBTools";
import { WBButton, WBDrawer, WBDrawer2, WBDropdown, WBDropdown2, WBDropdownOption, WBLinker, WBModal, WBSelector, WBInput } from "../../../Components/UIComponents/WBComponents";
import { BlobToBase64 } from "../../../lib/js/convertimagetobase64";
import { toast } from "react-toastify";
import compDefault from "../../../Components/Settings/UserManagement/FieldUserModal/assets/compDefault.svg";
import { validateStr } from "../../../lib/js/validateInput";

const OrgTemplatePage = ({ refreshOrgs }) => {
	let { id } = useParams();
	let navigate = useNavigate();

	const [selectedOrgId, setSelectedOrgId] = useState(null);
	const [selectedLocationsReady, setSelectedLocationsReady] = useState(false);

	const [shortName, setShortName] = useState(""); /* IMPORTANT is this currently not being saved to the DB, but it can be called from the DB. */
	const [initShortName, setInitShortName] = useState(""); 
	const [companyName, setCompanyName] = useState("");
	const [orgImage, setOrgImage] = useState(null);
	const [businessName, setBusinessName] = useState("");
	const [businessPhone, setBusinessPhone] = useState(0);
	const [businessEmail, setBusinessEmail] = useState("");
	const [businessWebsite, setBusinessWebsite] = useState("");
	const [businessAddress, setBusinessAddress] = useState("");
	const [businessABN, setBusinessABN] = useState(0);
	const [businessACN, setBusinessACN] = useState(0);
	const [currencyType, setCurrencyType] = useState("AUD");
	const [serviceLocations, setServiceLocations] = useState([]); // This is where the preloaded serviceLocations are stored
	const [contractLicences, setContractLicences] = useState([]);
	const [delContractLicences, setDelContractLicences] = useState([]);
	const [businessBSB, setBusinessBSB] = useState("");
	const [businessAccountNumber, setBusinessAccountNumber] = useState("");
	const [businessAccountName, setBusinessAccountName] = useState("");
	const [organisationMargin, setOrganisationMargin] = useState();
	const [bpay, setBPAY] = useState(0);
	const [bpayActive, setBpayActive] = useState(false);
	const [squareID, setSquareID] = useState(null);
	const [xeroID, setXeroID] = useState(null);
	const [quickID, setQuickID] = useState(null);
	const [myobID, setMYOBID] = useState(null);
	const [selectedLocations, setSelectedLocations] = useState([]); // This is where the edited service locations are stored
	// const [slBeingEditedIndex, setSlBeingEditedIndex] = useState(-1);
	const [status, setStatus] = useState(1);

	const [billableTemplates, setBillableTemplates] = useState([]);
	const [contractLicencesFormatted, setContractLicencesFormatted] = useState(<></>);
	const [integrationsFormatted, setIntegrationsFormatted] = useState(<></>)
	// const [subtitleNames, setSubTitleNames] = useState([]);   //removed until later development
	const [nextOrg, setNextOrg] = useState();
	const [deleteOrgInfo, setDeleteOrgInfo] = useState();

	const [deleteModal, setDeleteModal] = useState(false);
	const [showDeleteError, setShowDeleteError] = useState(false);
	const [deleteEntered, setDeleteEntered] = useState("");
	const [slModal, setSlModal] = useState(false);

	const toggleDeleteModal = () => setDeleteModal(!deleteModal);
	const toggleSlModal = () => setSlModal(!slModal);
	const [tempSL, setTempSL] = useState({ 
		label: "", 
		service_location_id: null,
		bhTemplateID: 1, 
		active: 1, 
		service_area: [],
		force_open: true
	});

	const [changesMade, setChangesMade] = useState(false);
	useEffect(() => {
		// window.unsavedChanges = changesMade;
        // const handleBeforeUnload = (event) => {
        //     if (changesMade) {
        //         event.preventDefault();
        //         event.returnValue = "You have unsaved changes. Do you really want to leave?";
        //     }
        // };
        // window.addEventListener("beforeunload", handleBeforeUnload);

        // return () => {
		// 	window.unsavedChanges = false;
        //     window.removeEventListener("beforeunload", handleBeforeUnload);
        // };
    }, [changesMade]);

	const Start = useCallback(async () => {
		
		if (!id) {
			return navigate("/settings/organisation/edit");
		} 

		let response = await CallDB({ branch: "/getOrgs" });
		let selectedOrg = response.find((org) => org.id.toString() ===  id);
		
		if (!selectedOrg) {
			toast.error("Company does not exist or has been removed.");
			return navigate("/settings/organisation/edit");
		}
		setSelectedOrgId(id);
		setOrgImage({
			file: selectedOrg.logo_image ? selectedOrg.logo_image : null
		});
		setShortName(selectedOrg.short_name ? selectedOrg.short_name : "");
		setInitShortName(selectedOrg.short_name ? selectedOrg.short_name : "");
		setCompanyName(selectedOrg.name ? selectedOrg.name : "");
		setBusinessName(selectedOrg.business_name ? selectedOrg.business_name : "");
		setBusinessAddress(selectedOrg.business_address ? selectedOrg.business_address : "");
		setBusinessPhone(selectedOrg.business_phone ? selectedOrg.business_phone : "");
		setBusinessEmail(selectedOrg.email ? selectedOrg.email : "");
		setBusinessWebsite(selectedOrg.website ? selectedOrg.website : "");
		setBusinessABN(selectedOrg.abn ? selectedOrg.abn : "");
		setBusinessACN(selectedOrg.acn ? selectedOrg.acn : "");
		setCurrencyType(selectedOrg.currency ? selectedOrg.currency : "AUD");
		setContractLicences(selectedOrg.contractor_licences ? selectedOrg.contractor_licences : []);
		setBusinessBSB(selectedOrg.bank_bsb ? selectedOrg.bank_bsb : "");
		setBusinessAccountNumber(selectedOrg.bank_acc_no ? selectedOrg.bank_acc_no : "");
		setBusinessAccountName(selectedOrg.bank_acc_name ? selectedOrg.bank_acc_name : "");
		setOrganisationMargin(selectedOrg.org_margin ? selectedOrg.org_margin : 0);
		setBPAY(selectedOrg.bpay_id ? selectedOrg.bpay_id : "");
		setBpayActive(selectedOrg.pay_is_active ? selectedOrg.pay_is_active : false)
		setStatus(selectedOrg.status);
		setServiceLocations(
			selectedOrg.service_areas
				? selectedOrg.service_areas.map((area, i) => ({
					label: area.label,
					service_location_id: area.service_location_id,
					bhTemplateID: area.bhTemplateID,
					active: area.active,
					service_area: area.service_area !== null ? area.service_area.map((sArea, j) => ({
						id: sArea.id,
						locality: sArea.locality,
						postcode: sArea.postcode,
						postcode_id: sArea.pc_id,
						state: sArea.state
					}))
						: []
				}))
				: []
		);
		setSquareID(!isNaN(selectedOrg.business_square) ? selectedOrg.business_square : selectedOrg.business_square !== null && selectedOrg.business_square !== "null" ? Math.random() * 100 : null);
		setXeroID(!isNaN(selectedOrg.business_xero) ? selectedOrg.business_xero : selectedOrg.business_xero !== null && selectedOrg.business_xero !== "null" ? Math.random() * 100 : null);
		setQuickID(!isNaN(selectedOrg.business_quickbooks) ? selectedOrg.business_quickbooks : selectedOrg.business_quickbooks !== null && selectedOrg.business_quickbooks !== "null" ? Math.random() * 100 : null);
		setMYOBID(!isNaN(selectedOrg.business_myob) ? selectedOrg.business_myob : selectedOrg.business_myob !== null && selectedOrg.business_myob !== "null" ? Math.random() * 100 : null);
		setChangesMade(false)
		setNextOrg(response.find((org) => org.id.toString() !==  id)?.id)
		preDeleteInfo(id);

		let response2 = await PostDB({
			branch: "/getAllBHTemplates",
			json: {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				}
			}
		});
		if (response2.length > 0) {
			setBillableTemplates(response2);
		}
		// GetBillableHourTemplates(selectedOrg.defaultBHTemplate ? selectedOrg.defaultBHTemplate : null);
	}, [id]);

	const handleLogoUpload = (images) => {
		if (images.length > 0) {
			setOrgImage({
				file: URL.createObjectURL(images[0]),
				name: images[0].name
			});
		}
	}

	const AddContactorLicence = () => {
		setContractLicences(existingLicences => [...existingLicences, { label: "", lic_no: '', action: 'add'}]);
	}

	const saveSL = () => {
		AddServiceLocation();
		toggleSlModal();
	}

	const AddServiceLocation = async () => {
		const newSL = tempSL;
		setSelectedLocations(sell => [...sell, newSL]);
		setServiceLocations(serl => [...serl, newSL]);
		setTempSL({ 
			label: "", 
			service_location_id: null,
			bhTemplateID: 1, 
			active: 1, 
			service_area: [],
			force_open: true
		});
	};

	const UpdateServiceLocation = (index, value) => {
		if (value.length != null && selectedLocations && selectedLocations.length > 0) {
			const newLocations = [...selectedLocations];
			newLocations[index].service_area = value;
			// await BuildSubTitle(value, index);
			setSelectedLocations(newLocations);
		}
	}


	const UpdateServiceLocationName = (index, value) => {
		if (selectedLocations) {
			const newLocations = [...selectedLocations];
			newLocations[index].label = value;
			setSelectedLocations(newLocations);
			setServiceLocations(newLocations);
		}
	}

	const UpdateServiceLocationStatus = (index, value) => {
		const newLocations = [...selectedLocations];
		value === true ? newLocations[index].active = 1 : newLocations[index].active = 0;
		setSelectedLocations(newLocations);
	}

	const UpdateBPAYActive = (value) => {
		const newStatus = [...bpay];
		value === true ? newStatus[0].active = 1 : newStatus[0].active = 0;
		setBpayActive(newStatus);
	}

	// const UpdateBPAYCode = (value) => {
	// 	if (value.length > 0 && bpay) {
	// 		const newCode = [...bpay];
	// 		newCode[0].code = value;
	// 		setBPAY(newCode);
	// 	}
	// }

	const UpdateBHTemplate = (index, bhtID) => {
		if (bhtID !== null && bhtID !== 0 && serviceLocations[index]) {
			const newBH = [...serviceLocations];
			newBH[index].bhTemplateID = bhtID;
			setServiceLocations(newBH);
		}
	}

	// const UpdateServiceLocationMargin = (index, value) => {
	// 	if (value !== null && value !== 0 && serviceLocations[index]) {
	// 		const newBH = [...serviceLocations];
	// 		newBH[index].margin = value;
	// 		setServiceLocations(newBH);
	// 	}
	// }

	// ---- This will be updated later when spare time is found
	// const BuildSubTitle = (serviceAreas, index) => {
	// 	let names = "N/A";
	// 	if (serviceAreas.length > 0) {
	// 		let localityNames = serviceAreas.map(area => area.name.split(',')[0]); // Extract the first part of the name
	// 		if (localityNames.length > 3) {
	// 			names = localityNames.slice(0, 3).join(', ') + ",...";
	// 		}
	// 		else {
	// 			names = localityNames.join(', ');
	// 		}
	// 	}
	// 	setSubTitleNames(subTitleNames => {
	// 		let newSubTitleNames = [...subTitleNames]; // create a copy of the array
	// 		newSubTitleNames[index] = names; // update the value at the specific index
	// 		return newSubTitleNames; // return the new array
	// 	});
	// }

	useEffect(() => {
		if (contractLicences.length > 0)
			setContractLicencesFormatted(
				contractLicences.map((licence, index) => (
					// <div  className="org-licences" style={{ alignItems: "center", flexWrap: "wrap" }}>
					<div key={index} className="flex flex-row gap-10" style={{ flexWrap: "wrap", maxWidth: "75%" }}>
						<p className="p-20">{index + 1}</p>
						<WBInput 
							placeholder="Label Name"
							value={licence.label}
							setChangeMade={setChangesMade}
							returnFunc={(e) => {
								const tempLicences = [...contractLicences];
								tempLicences[index].label = e.target.value;
								if (tempLicences[index].id == null) {
									tempLicences[index].action = 'add';
								} else {
									tempLicences[index].action = 'update';
								}
								setContractLicences(tempLicences);
							}}
						/>
						<WBInput 
							placeholder="Licence number"
							value={licence.lic_no}
							setChangeMade={setChangesMade}
							returnFunc={(e) => {
								const tempLicences = [...contractLicences];
								tempLicences[index].lic_no = e.target.value;
								if (tempLicences[index].id == null) {
									tempLicences[index].action = 'add';
								} else {
									tempLicences[index].action = 'update';
								}
								setContractLicences(tempLicences);
							}}
						/>
						<p className="remove p-20" onClick={(e) => {
							const deletedLicence = [...contractLicences][index];
							deletedLicence.action = 'del';
							setContractLicences(licences => licences.filter((_, i) => i !== index))
							if (deletedLicence.id) {
								setDelContractLicences(delLicences => [...delLicences, deletedLicence]);
							}
						}}>Remove</p>
					</div>
				))
			)
		else {
			setContractLicencesFormatted([]);
		}
	}, [contractLicences])


	useEffect(() => {
		if (serviceLocations && serviceLocations.length > 0) {
			setSelectedLocations(serviceLocations);
			setSelectedLocationsReady(true);
		}
	}, [serviceLocations])

	useEffect(() => {
		Start();

		return () => {
			setContractLicences([]);
			setSelectedLocations([]);
			setServiceLocations([]);
		}
	}, [Start]);

	const SubmitOrg = async (givenStatus, isDisabling) => {
		if (!validateStr("Display Name", shortName)) return;

		let formattedSL = JSON.parse(JSON.stringify(selectedLocations));
		formattedSL.forEach(location => {
			location.service_area = location.service_area.map(area => {
				const [locality, state, postcode] = area.name.split(', ');
				return {
					id: area.id,
					locality: locality,
					state: state,
					postcode: postcode
				};
			});
		});



		let bodyObj = JSON.stringify({
			org_id: selectedOrgId,
			logo_image: orgImage && orgImage.name ? [{
				file: await BlobToBase64(orgImage.file),
				file_name: orgImage.name
			}] : null,
			short_name: shortName,
			org_name: companyName,
			business_name: businessName,
			business_address: businessAddress,
			business_phone: businessPhone,
			business_email: businessEmail,
			business_website: businessWebsite,
			abn: businessABN,
			acn: businessACN,
			currency: currencyType,
			licence_numbers: [...contractLicences, ...delContractLicences],
			business_bsb: businessBSB,
			business_bank_account_number: businessAccountNumber,
			business_bank_account_name: businessAccountName,
			org_margin: organisationMargin !== 0 ? organisationMargin : 0,
			business_bpay: [{
				code: bpay,
				active: bpayActive
			}],
			service_locations: formattedSL,
			business_square: squareID,
			business_xero: xeroID,
			business_quickbooks: quickID,
			business_myob: myobID,
			status: givenStatus
		});

		const response = await PostDB({
			branch: "/editOrg", json: {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: bodyObj
			}
		});

		if (response === "success") {
			setChangesMade(false);
			if (isDisabling) {
				if (givenStatus === 0) {
					toast.info("Organisation has been disabled.")
				} else {
					toast.info("Organisation has been enabled.")
				}
			} else {
				toast.success("Organisation was updated successfully.")
			}
			Start()
			setContractLicences([]);
			setSelectedLocations([]);
			setServiceLocations([]); 
			if (orgImage && orgImage.name || initShortName != shortName) {
				window.location.href = `/settings/organisation/edit/${selectedOrgId}`;
			}
		}
		else {
			toast.error("There was an issue editing organisations. Make sure the fields are filled correctly.")
		}
	}

	const preDeleteInfo = async (myOrg) => {
		const response = await PostDB({
			branch: "/predeleteorg", json: {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ org_id: myOrg })
			}
		});
		if (response?.length > 0) setDeleteOrgInfo(response[0])
	}

	const deleteOrg = async (myOrg) => {
		if (deleteEntered === "DELETE") {
			let tempName = shortName;
			const response = await PostDB({
				branch: "/delOrg", json: {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({ org_id: myOrg })
				}
			});
			if (response.result === "success") {
				// setShowModal(!showModal);
				toast.success(tempName + " has been deleted successfully");
				refreshOrgs(); // Trigger the refresh
				window.location.href = `/settings/organisation/edit/${nextOrg}`; // This is not the recommended way to navigate in React, but until i find a way to update Left Navigation to show the removal of a missing organisation, this will do.
				// navigate(`/settings/organisation/edit/${nextOrg}`);
			}
			else {
				toast.error("Company does not exist");
			}
		}
		else {
			setShowDeleteError(true);
		};
	}

	const deleteSL = async (selectedSlId, selectedIndex) => {
		const confirmDelete = window.confirm("Are you sure you want to delete this service location?");
		if (confirmDelete) {
			if (selectedSlId !== null) {
				const response = await PostDB({
					branch: "/delServiceAreaLocation", json: {
						method: 'POST',
						headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json'
						},
						body: JSON.stringify({ sl_id: selectedSlId })
					}
				});
				if (response.result === "success") {
					toast.success("Service location has been deleted successfully");
					setSelectedLocations(sell => sell.filter((_, index) => index !== selectedIndex));
					setServiceLocations(serl => serl.filter((_, index) => index !== selectedIndex).map(location => ({ ...location, force_close: location.force_close ? location.force_close + 1 : 1})));
				} else {
					toast.error("There was an error deleting this service location.");
				}
			} else {
				setSelectedLocations(sell => sell.filter((_, index) => index !== selectedIndex));
				setServiceLocations(serl => serl.filter((_, index) => index !== selectedIndex).map(location => ({ ...location, force_close: location.force_close ? location.force_close + 1 : 1})));
			}
		}
	}
 
	const GetTemplateName = (location) => {
		const template = billableTemplates.find(temp => temp.id === location.bhTemplateID);
		return template ? template.title : 'Template not found';
	}

	const LocationServices = useMemo(() => {
		const FormatPreload = (sArea) => {
			let result = [];
			if (sArea[0]) {
				if (Object.keys(sArea[0]).length === 2)
					result = sArea; // Preload format found, does not need to be reformatted to be displayed
				else if (Object.keys(sArea[0]).length > 2)
					result = sArea.map((area) => ({ id: area.postcode_id, name: area.locality + ", " + area.state + ", " + area.postcode }))
			}
			return result;
		}

		return (
			serviceLocations.length > 0 ? serviceLocations.map((location, index) => (
				<div key={index} className="row" style={{ padding: "0px" }}> 
					<div className="col" style={{ paddingLeft: "0px", paddingTop: "20px", paddingBottom: "0px" }}>
						<div className="input">
							<WBDrawer2 title={location.label} active={location.active === 1 ? true : false} 
								activeReturn={(value) => {setChangesMade(true); UpdateServiceLocationStatus(index, value)}}
								deleteReturn={() => deleteSL(location.service_location_id, index)}
								modalWidth="1100px"
								showStatus={true}
								modalTitle="Service Location"
							>
								<div className="row" style={{ padding: "16px 20px"}}>
									<div className="col" style={{ padding: "0"}}>
										<div className="input" style={{ padding: "0"}}>
											<label htmlFor="business_address" className="form-label">
												Label
											</label>
											<input
												type="text"
												name="service_location_label"
												className="form-control"
												id="service_location_label"
												value={location.label}
												placeholder="Enter service location name"
												onBlur={(e) => {setChangesMade(true); UpdateServiceLocationName(index, e.target.value)}}
												onChange={(e) => {setChangesMade(true); UpdateServiceLocationName(index, e.target.value)}}
											/>
										</div>
										<div className="input" />
									</div>
								</div>
								<div className="border mtb-20"></div>
								<h1 style={{ paddingLeft: "20px" }}>Location</h1>
								<div className="row" style={{ padding: "16px 20px"}}>
									<div className="col" style={{ padding: "0"}}>
										<div className="serviceLocations">
											<WBSelector
												title="Location Area"
												data="/getarea"
												identifiers={["id", "locality", "state", "postcode", "pc_id"]}
												preload={FormatPreload(location.service_area)}
												returnFunc={(value) => {UpdateServiceLocation(index, value)}}
												isUnique="true"
												side 
												givenMinWidth="1100px"
												setChangeMade={setChangesMade}/>	
										</div>
									</div>
								</div>
								<div className="border mtb-20"></div>
								<h2 >Billable Hourly Rate</h2>
								<div className="row" style={{ padding: "0px 16px"}}>
									<div className="col" style={{ padding: "0"}}>
										<div className="serviceLocations">
											<WBDropdown2 width="320px" fixedHeight="300px" key={location.id} leftitem={GetTemplateName(location)} rightitem={<i className="icon-arrow-down"></i>} color={"white"}>
												{billableTemplates.length > 0 &&
													billableTemplates.map((bt) => (
														<WBDropdownOption key={bt.id} title={bt.title} func={() => {setChangesMade(true); UpdateBHTemplate(index, bt.id)}} />
													))
												}
											</WBDropdown2>
										</div>
									</div>
								</div>
							</WBDrawer2>
						</div>
					</div>
				</div>
			)) : <p className="pl-20" style={{ paddingTop: "20px" }}>No Service Locations. Create one with the "Add Service Location" button above.</p>
		)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serviceLocations, selectedLocationsReady, billableTemplates]);

	useEffect(() => {
		setIntegrationsFormatted(
			<div>
				<div className="row" style={{ padding: "0px" }}>
					<div className="col" style={{ paddingLeft: "0px", paddingTop: "20px", paddingBottom: "0px"}}>
						<div className="input">
							<label className="form-label">
								Payment
							</label>
							<WBLinker title="Square" image={"/images/services/39003 1.png"} linked={squareID !== null ? true : false} returnFunc={setSquareID} />
						</div>
					</div>
				</div>
				<div className="row" style={{ padding: "0px" }}>
					<div className="col" style={{ paddingLeft: "0px", paddingTop: "20px", paddingBottom: "0px"}}>
						<div className="input">
							<label className="form-label">
								Accounting
							</label>
							<WBLinker title="Xero" image={"/images/services/1200px-Xero_software_logo 1.png"} linked={xeroID !== null ? true : false} returnFunc={setXeroID} />
						</div>
					</div>
				</div>
				<div className="row" style={{ padding: "0px" }}>
					<div className="col" style={{ paddingLeft: "0px", paddingTop: "20px", paddingBottom: "0px"}}>
						<div className="input">
							<WBLinker title="Quickbooks" image={"/images/services/39004.png"} linked={quickID !== null ? true : false} returnFunc={setQuickID} />
						</div>
					</div>
				</div>
				<div className="row" style={{ padding: "0px" }}>
					<div className="col" style={{ paddingLeft: "0px", paddingTop: "20px", paddingBottom: "0px"}}>
						<div className="input">
							<WBLinker title={"myob"} image={"/images/services/MYOB_Logo 1.png"} linked={myobID !== null ? true : false} returnFunc={setMYOBID} />
						</div>
					</div>
				</div>
			</div>
		)
	}, [xeroID, squareID, myobID, quickID])

	return (
		<div className="content-area-full">
			{deleteModal &&
				<WBModal title={"Delete Organisation"} closeFunction={toggleDeleteModal} clickToClose={false} customSubmit={<WBButton title={"Delete Organisation"} color={"red"} func={() => deleteOrg(selectedOrgId)} largemodal />} >
					<div className="deleteOrgModal" style={{ padding: "25px"}}>
						<div className="row">
							<div className="deleteOrgHeader">
								<p className="deleteOrgSubtitle">{shortName.toUpperCase()}</p>
								<div className="deleteOrgActive">
									<p className="warning-title">Organisation currently have:</p>
									<p className="warning-content">{deleteOrgInfo?.clients ?? "-"} Clients, {deleteOrgInfo?.jobs ?? "-"} Jobs, {deleteOrgInfo?.sales ?? "-"} Sales, {deleteOrgInfo?.payments ?? "-"} Payments</p>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="deleteOrgWarning">
								<p className="warning-title">Deleting Organisation all data will be <strong>permanently deleted</strong>.</p>
								<p className="warning-content">To Delete organisation, type DELETE</p>
							</div>
						</div>
						<div className="row">
							<div className="deleteOrgInput">
								<input type="text" className="form-control" placeholder={"Deleting organisation, type DELETE"} onChange={(e) => setDeleteEntered(e.target.value)} />
								{showDeleteError && <div className="deleteError">Please type 'DELETE' to confirm</div>}
							</div>
						</div>
					</div>
				</WBModal>}
			{slModal &&
				<WBModal width="1100px" title={"Service Location"} closeFunction={toggleSlModal} clickToClose={false} customSubmit={<WBButton title={"Save"} color={"green"} func={() => saveSL()} largemodal />} >
					<div className="row" style={{ padding: "0px" }}>
						<div className="col" style={{ paddingLeft: "0px", paddingTop: "20px", paddingBottom: "0px" }}>
							<div className="input">
								<div className="row" style={{ padding: "16px 20px"}}>
									<div className="col" style={{ padding: "0"}}>
										<div className="input" style={{ padding: "0"}}>
											<label htmlFor="business_address" className="form-label">
												Label
											</label>
											<input
												type="text"
												name="service_location_label"
												className="form-control"
												id="service_location_label"
												value={tempSL.label}
												placeholder="Enter service location name"
												onBlur={(e) => {
													setChangesMade(true); 
													setTempSL((prev) => ({
														...prev,
														label: e.target.value
													}));
												}}
												onChange={(e) => {
													setChangesMade(true); 
													setTempSL((prev) => ({
														...prev,
														label: e.target.value
													}));
												}}
											/>
										</div>
										<div className="input" />
									</div>
								</div>
								<div className="border mtb-20"></div>
								<h1 style={{ paddingLeft: "20px" }}>Location</h1>
								<div className="row" style={{ padding: "16px 20px"}}>
									<div className="col" style={{ padding: "0"}}>
										<div className="serviceLocations">
											<WBSelector
												title="Location Area"
												data="/getarea"
												identifiers={["id", "locality", "state", "postcode", "pc_id"]}
												returnFunc={(value) => {
													if (value != null) {
														setTempSL((prev) => ({
															...prev,
															service_area: value.map((curVal) => ({
																id: curVal.id,
																name: curVal.name
															}))
														}));
													}
												}}
												isUnique="true"
												givenMinWidth="900px"
												side />
										</div>
									</div>
								</div>
								<div className="border mtb-20"></div>
								<h2 style={{ paddingLeft: "20px"}}>Billable Hourly Rate</h2>
								<div className="row" style={{ padding: "20px"}}>
									<div className="col" style={{ padding: "0"}}>
										<div className="serviceLocations">										
											<WBDropdown2 width="320px" fixedHeight="300px" leftitem={GetTemplateName(tempSL)} rightitem={<i className="icon-arrow-down"></i>} color={"white"}>
												{billableTemplates.length > 0 &&
													billableTemplates.map((bt) => (
														<WBDropdownOption key={bt.id} title={bt.title} func={() => {
															setTempSL((prev) => ({
																...prev,
																bhTemplateID: bt.id
															}));
														}}/>
													))
												}
											</WBDropdown2>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</WBModal>}

			<div className="floating-box bg-white" style={{ maxWidth: "1260px" }}>
				<div className="flex flex-row w-f" style={{ justifyContent: "space-between", backgroundColor: "#F0F0F0", padding: "14px 36px" }}>
					<WBButton title="Save" color="green" func={() => SubmitOrg(status, false)} />
					<WBDropdown2 viewItem={<div className="option flex flex-row" style={{ backgroundColor: "#fff", width: "40px", height: "40px", justifyContent: "center", alignItems: "center", borderRadius: "10px"}}>
								<span className="dot-icon" style={{backgroundColor: "#000", marginRight: "4px"}}></span>
								<span className="dot-icon"style={{backgroundColor: "#000", marginRight: "4px"}}></span>
								<span className="dot-icon"style={{backgroundColor: "#000"}}></span>
							</div>} assignedMargin={5}>
						<WBDropdownOption title={status === 1 ? "Disable Organisation" : "Enable Organisation"}  func={() => {
							if (!nextOrg) {
								toast.error("You need to have atleast one active organisation.");
							} else {
								if (status === 1) {
									setStatus(0);
									SubmitOrg(0, true);
								} else {
									setStatus(1);
									SubmitOrg(1, true);
								}
							}
						}}/>
						<WBDropdownOption title="Delete Organisation" func={() => {
							if (!nextOrg) {
								toast.error("You need to have atleast one active organisation.");
							} else {
								toggleDeleteModal();
							}
						}} />
					</WBDropdown2>
				</div>
				{/* section */}
				<div style={{overflowY: "auto", scrollbarWidth: "none", width: "100%", display: "flex", flexDirection: "column", alignItems: ""}}>
					<div id="organisation-form">
						<div id="header" style={{ padding: "40px "}}>
							<div>
								<div>Organisation</div>
								<div>
									<h2>{shortName}</h2>
								</div>
							</div>
						</div>
					</div>

					<div className="organisation-section" style={{ padding: "40px"}}>
						<div className="row" style={{ paddingBottom: "40px"}}>
							<div style={{display: "flex", flexDirection: "row"}}>
								<div className="companyContentImage" style={{width: "103px", height: "103px"}}>
									{orgImage && orgImage.file ? <img
										src={orgImage.file}
										alt={shortName + " Logo"}
										width={"100%"}
										height={"100%"}
										title={shortName + " Logo"}
										style={{objectFit: 'cover'}}
									/>: 
									<img style={{width: "50%"}} src={compDefault} alt="Organisation profile placeholder" />}
								</div>
								<div className="orgLogoInput" style={{ paddingLeft: "22px" }}>
									<p >
										<h4>Company Profile Image</h4>
										<p style={{ fontSize: "12px", paddingTop: "8px" }}><b style={{ fontSize: "12px"}}>Supported formats:</b> JPG, JPEG, PNG</p>
									</p>
									<div className="orgLogoButtons" >
										<div className="input-upload">
											<label className="btn btn-default h-full" style={{ backgroundColor: "white", height: "48px", color: "black", margin: "0px"}} title="Upload Logo Here" htmlFor="file-upload" >
												<i className="icon-open-folder" />
												Upload Company Logo
											</label>
											<input type="file" onChange={(e) => {handleLogoUpload(e.target.files); setChangesMade(true)}} hidden accept="image/jpg,image/jpeg,image/png" id="file-upload" />
										</div>
									</div>
								</div>
							</div>
							
						</div>
						<div className="hr"></div>
						<div className="flex flex-column" style={{ gap: "20px" }}>
							<h2 style={{ margin: "0px", paddingTop: "20px" }}>Company Details</h2>
							<div className="flex flex-row w-full gap-10">
								<WBInput 
									labelName={
										<>
											Organisation Display Name 
											<span className="not-bold">(Max 20 Characters)</span>
										</>
									}
									placeholder="Enter organisation name"
									value={shortName}
									setValue={setShortName}
									setChangeMade={setChangesMade}
								/>
							</div>
							<div className="flex flex-row w-full gap-10" style={{ flexWrap: "wrap" }}>
								<WBInput 
									labelName="Company Name"
									placeholder="Enter company name"
									value={companyName}
									setValue={setCompanyName}
									setChangeMade={setChangesMade}
								/>
								<WBInput 
									labelName="Business/Trading Name"
									placeholder="Enter business name"
									value={businessName}
									setValue={setBusinessName}
									setChangeMade={setChangesMade}
								/>
							</div>
							<div className="flex flex-row w-full gap-10" style={{ flexWrap: "wrap" }}>
								<WBInput 
									labelName="Business Address"
									placeholder="Enter business address"
									value={businessAddress}
									setValue={setBusinessAddress}
									setChangeMade={setChangesMade}
								/>
								<WBInput 
									labelName="Business Phone Number"
									placeholder="Enter business phone"
									value={businessPhone}
									setValue={setBusinessPhone}
									setChangeMade={setChangesMade}
								/>
							</div>
							<div className="flex flex-row w-full gap-10" style={{ flexWrap: "wrap" }}>
								<WBInput 
									labelName="Business Email Address"
									placeholder="Enter business email"
									value={businessEmail}
									setValue={setBusinessEmail}
									setChangeMade={setChangesMade}
								/>
								<WBInput 
									labelName="Website URL"
									placeholder="Enter website address"
									value={businessWebsite}
									setValue={setBusinessWebsite}
									setChangeMade={setChangesMade}
								/>
							</div>
							<div className="flex flex-row w-full gap-10" style={{ flexWrap: "wrap" }}>
								<WBInput 
									labelName="Business ABN"
									placeholder="Enter business abn number"
									value={businessABN}
									setValue={setBusinessABN}
									setChangeMade={setChangesMade}
								/>
								<WBInput 
									labelName="Business ACN"
									placeholder="Enter  business acn number"
									value={businessACN}
									setValue={setBusinessACN}
									setChangeMade={setChangesMade}
								/>
								<div className="flex flex-column" style={{ flexGrow: 1 }}>
									<label htmlFor="currency" className="form-label">
										Currency
									</label>
									<WBDropdown leftitem={currencyType} rightitem={<i className="icon-arrow-down"></i>} defaultValue={"Select Currency"} func={(e) => {setChangesMade(true); setCurrencyType(e.target.value)}} color={"wbgrey"} width={"100%"} height={"40px"} inputColor="#EDF7FF">
										<WBDropdownOption title={"AUD"} func={() => setCurrencyType("AUD")} justifyGiven={"flex-start"}/>
										<WBDropdownOption title={"USD"} func={() => setCurrencyType("USD")} justifyGiven={"flex-start"}/>
									</WBDropdown>
								</div>
							</div>
						</div>
						<div className="row" style={{ padding: "0px"}}>
							<div className="col" style={{ paddingLeft: "0px", paddingTop: "20px", paddingBottom: "0px"}}>
								<div className="titleandbutton">
									<label>Contractor Licence Number</label>
									<WBButton title={"Add Licence Number"} icon={<i className="icon-card-tick"></i>} color="blueborder" func={AddContactorLicence} />
								</div>
							</div>
						</div>
						{contractLicences.length > 0 ?
							<div className="row" style={{ padding: "0px"}}>
								<div className="col" style={{ paddingLeft: "0px", paddingTop: "20px"}}>
									<div className="input">
										{contractLicencesFormatted}
									</div>
								</div>
							</div>
							:
							<div className="row" style={{ padding: "0px"}}>
								<div className="col" style={{ paddingLeft: "0px", paddingTop: "20px"}}>
									<div>
										<p className="pl-20">No contractor licence number have been added</p>
									</div>
								</div>
							</div>}
						<div className="border mtb-20"></div>
						<h2 style={{ margin: "0px", paddingBottom: "20px"}}>Banking Transfer</h2>
						<div className="flex flex-row w-full gap-10" style={{ flexWrap: "wrap" }}>
							<WBInput 
								labelName="BSB"
								placeholder="Enter BSB"
								maxLength={6}
								value={businessBSB}
								setValue={setBusinessBSB}
								setChangeMade={setChangesMade}
							/>
							<WBInput 
								labelName="Account Number"
								placeholder="Enter Account Number"
								value={businessAccountNumber}
								setValue={setBusinessAccountNumber}
								setChangeMade={setChangesMade}
							/>
							<WBInput 
								labelName="Account Name"
								placeholder="Enter Account Name"
								value={businessAccountName}
								setValue={setBusinessAccountName}
								setChangeMade={setChangesMade}
							/>
						</div>
						<div className="row" style={{ padding: "0px"}}>
							<div className="col" style={{ paddingLeft: "0px", paddingTop: "20px", paddingBottom: "0px" }}>
								<div className="input">
									<WBDrawer title={"BPAY"} activeReturn={(e) => setBpayActive(e)} height={"71px"} active >
										<div className="row">
											<div className="col">
												<div className="input">
													<label htmlFor="business_address" className="form-label">
														Biller Code
													</label>
													<div className="inputandremove">
														<input
															type="text"
															name="service_location_label"
															className="form-control"
															id="service_location_label"
															value={bpay}
															placeholder="enter biller code here"
															onBlur={(e) => setBPAY(e.target.value)}
														/>
														<p className="remove">
															remove
														</p>
													</div>
													<div className="bpayDesc">
														<div className="row">
															<div className="col">
																<p className="bpayTitle">Biller Code</p>
															</div>
															<div className="col">
																<p className="bpayDesc">318998</p>
															</div>
														</div>
														<div className="row">
															<div className="col">
																<p className="bpayTitle">Biller Short</p>
															</div>
															<div className="col">
																<p className="bpayDesc">SERVICE TODAY</p>
															</div>
														</div>
														<div className="row">
															<div className="col">
																<p className="bpayTitle">Biller Long Name</p>
															</div>
															<div className="col">
																<p className="bpayDesc">ALLTRADES CONTRACTING SERVICES PTY LTD</p>
															</div>
														</div>
														<div className="row">
															<div className="col">
																<p className="bpayTitle">This Biller accepts</p>
															</div>
															<div className="col">
																<p className="bpayDesc">Cheques, Savings or Debit Card</p>
															</div>
														</div>
													</div>
												</div>
												<div className="input" />
												<div className="input" />
											</div>
										</div>
									</WBDrawer>
								</div>
							</div>
						</div>

						<div className="border mtb-20"></div>

						<div className="titleandbutton">
							<h2 style={{ margin: "0px" }}>Service Locations</h2>
							<WBButton title={"Add Service Location"} color="blueborder" func={() => {
								toggleSlModal();
							}} />
						</div>

						{LocationServices.length > 0 && LocationServices.reverse()}

						<div className="border mtb-20"></div>

						<h2 style={{ margin: "0px" }}>Integrations</h2>
						{integrationsFormatted}
					</div>
				</div>
			</div>
		</div >
	);
};

export default OrgTemplatePage;
