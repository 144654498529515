import { useState, useEffect } from "react";
import { PostDB } from "../../../../Components/DBTools";
import { WBButton, WBIOSToggle, WBDropdown, WBDropdownOption } from "../../../../Components/UIComponents/WBComponents";
import { toast } from "react-toastify";

const EditDiscountLogic = ({ closeModalMethod, itemSelected, returnFunc }) => {
    const [id, setID] = useState();
    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [disType, setDisType] = useState();
    const [amount, setAmount] = useState();
    const [active, setActive] = useState(1);
    const [changesMade, setChangesMade] = useState(false);

    const toggleStatus = () => setActive(status => { setChangesMade(true); return !status });
    
    useEffect(() => {
        setID(itemSelected.id);
        setTitle(itemSelected.title);
        setDescription(itemSelected.description);
        setDisType(itemSelected.type);
        setAmount(itemSelected.discount);
        setActive(itemSelected.active === 1 ? true : false);
    }, []);

    const Save = async () => {
        let bodyObj = {
            id: id,
            org_id: 1,
            title: title,
            description: description,
            type: disType,
            discount: amount,
            status: active === true ? 1 : 0,
            org_id: 1,
            trade_id: 1
        }
        const response = await PostDB({
            branch: "/editDiscount",
            json: {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(bodyObj)
            }
        });
        if (response.api_status === "success") {
            toast.success("Discount has been successfully updated");
            if (returnFunc) returnFunc();
            closeModalMethod();
        }
        else {
            toast.error("There was an error saving this Discount. Check your fields and try again");
        }
    };

    const handleBGClick = (e) => {
        const background = document.querySelector(".bg-darkblue");
        if (background === e.target && !changesMade) {
            closeModalMethod();
        }
    }

    return (
        <div className="add-utility-page">
            <div className="bg-darkblue" onClick={(e) => handleBGClick(e)}>
                <div className="content">
                    <div className="border-bottom bg-white p-20 gap-10" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="button-row left">
                            <h4 className="title"><b>Edit Discount Logic</b></h4>
                        </div>
                    </div>
                    <div className="border-bottom bg-white h-full gap-10">
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="code">Discount ID:</label>
                                    <p className="pl-10" id="code">{id ? id : "N/A"}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="discountTitle" className="form-label">
                                        Discount Name
                                    </label>
                                    <input
                                        type="text"
                                        name="discountTitle"
                                        className="half"
                                        id="discountTitle"
                                        placeholder="Enter discount name"
                                        defaultValue={title}
                                        onChange={(e) => { setChangesMade(true); setTitle(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="discountDesc" className="form-label">
                                        Discount Description
                                    </label>
                                    <textarea
                                        name="discountDesc"
                                        className="half"
                                        id="discountDesc"
                                        placeholder="Enter discount description"
                                        defaultValue={description}
                                        rows={"5"}
                                        onChange={(e) => { setChangesMade(true); setDescription(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="discountType" className="form-label">
                                        Type
                                    </label>
                                    <WBDropdown leftitem={disType ? disType : "Select Type"} rightitem={<i className="icon-arrow-down"></i>} color={"white"} optionHidden={true} >
                                        <WBDropdownOption key={0} title="Amount" func={() => { setDisType("Amount"); setChangesMade(true); }} />
                                        <WBDropdownOption key={1} title="Percentage" func={() => { setDisType("Percentage"); setChangesMade(true); }} />
                                    </WBDropdown>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="discAmount" className="form-label">
                                        Discount Amount
                                    </label>
                                    <input
                                        type="text"
                                        name="discAmount"
                                        className="half"
                                        id="discAmount"
                                        placeholder="Enter discount cost"
                                        defaultValue={amount}
                                        onChange={(e) => { setChangesMade(true); setAmount(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer flex flex-row space-between" style={{ flexWrap: "wrap" }}>
                        <div className="flex flex-row gap-10" style={{ alignItems: "center"}}>
                            <p>{active ? "Disable" : "Enable"} Discount</p>
                            <WBIOSToggle active={active} func={() => toggleStatus()} />
                        </div>
                        <div className="flex flex-row gap-10">
                            <WBButton title="Cancel" color="white" func={() => { if (!changesMade) closeModalMethod(); else { if (window.confirm("You are about to leave this page? Are you sure?")) closeModalMethod() } }} />
                            <WBButton title="Save" color={"green"} func={() => Save()} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditDiscountLogic;