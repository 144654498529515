import { useState, useEffect } from "react";
import TableItems from "../../../Components/UIComponents/TableItems";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import AddServiceCallFee from "./Add/AddServiceCallFee";
import EditServiceCallFee from "./Edit/EditServiceCallFee";

const ServiceCallFeesPage = () => {
  const [tableData, setTableData] = useState();
  const [refreshTable, setRefreshTable] = useState(false);

  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);

  const toggleAddModal = () => { setIsModalAddOpen(!isModalAddOpen); }
  const toggleEditModal = () => { setIsModalEditOpen(!isModalEditOpen); }

  const [itemSelected, setItemSelected] = useState([]);

  const headers = ["Type", "Callout fee ($)", "Status", "Action"];

  useEffect(() => {
    setTableData(
      <WBTable tableDataOrg="/settings/callout_fees" tableHeaders={headers} isLocalSearch={true} filterBy="title">
          <TableItems 
            itemTitle="Customer Type" identifiers={["title", "price"]} removeBranch="/removeCalloutFee"
            editFunc={toggleEditModal} setRefreshTable={setRefreshTable}
            setItemSelected={setItemSelected}
          />
      </WBTable>
    );
  }, [refreshTable]);

  return (
    <div className="content-area-full">
      {isModalAddOpen && <AddServiceCallFee closeModalMethod={toggleAddModal} returnFunc={() => {
        setRefreshTable(!refreshTable)
      }}/>}
      {isModalEditOpen && <EditServiceCallFee closeModalMethod={toggleEditModal} itemSelected={itemSelected} returnFunc={() => {
        setRefreshTable(!refreshTable)
      }}/>}

      <div className="flex floating-box flex-column bg-white gap-20" style={{ padding: "42px 36px 35px 36px"}}>
        <div className="flex justify-between">
          <div className="text-base">
            <b>Service callout Fees</b>
          </div>
          <WBButton title="Add Service Call Fee" func={toggleAddModal} color={"white"} />
        </div>
        {tableData}
      </div>
    </div>
  );
};

export default ServiceCallFeesPage;
