import { useJobModal } from '../lib/contexts/JobModalContext';
import { JobBookingProvider } from '../Pages/ClientCard/Client/JobBookingContext';

import JobCustomerDetailsModal from '../Pages/ClientCard/Client/JobCustomerDetailsModal';
import JobPropertyDetailsModal from '../Pages/ClientCard/Client/JobPropertyDetailsModal';
import JobJobDetailsModal from '../Pages/ClientCard/Client/JobJobDetailsModal';
import JobSummaryModal from '../Pages/ClientCard/Client/JobSummaryModal';

const GlobalJobModals = () => {
  const { modals, toggleModal } = useJobModal();

  const modalComponents = [
    {
      name: 'addNewJobCustomer',
      component: JobCustomerDetailsModal,
    },
    {
      name: 'addNewJobProperty',
      component: JobPropertyDetailsModal,
    },
    {
      name: 'addNewJobJob',
      component: JobJobDetailsModal,
    },
    {
      name: 'addNewJobSummary',
      component: JobSummaryModal,
    },
  ];

  return (
    <JobBookingProvider>
      {modalComponents.map(({ name, component: ModalComponent }) => (
        <ModalComponent
          key={name}
          isOpen={modals[name]}
          closeModal={() => toggleModal('')}
          customerDetailsModal={() => toggleModal('addNewJobCustomer')}
          jobDetailsModal={() => toggleModal('addNewJobJob')}
          propertyDetailsModal={() => toggleModal('addNewJobProperty')}
          summaryModal={() => toggleModal('addNewJobSummary')}
        />
      ))}
    </JobBookingProvider>
  );
};

export default GlobalJobModals; 