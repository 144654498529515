import styles from "./css/schedulermodal.module.css";

import SchedulerButton from "./components/SchedulerButton";
import { useEffect, useState, createContext, useContext } from "react";

import JobCardPage from "../../Pages/Customer/JobCard/JobCardPage";
import { JobCardProvider } from "../../Components/Customer/context/JobCardContext";
import { JobDetailsProvider as JobDetailsProvider2 } from '@/Components/Customer/JobCard/context/JobDetailsContext';

const JobDetailsProvider = createContext();

export default function SchedulerModal({ onClose, scheduleData }) {

  const [jobDetails, setJobDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchJobDetails = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_NODEJSAPI}/jobs/jobdetails`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ job_id: scheduleData.job_id, schedule_id: scheduleData.schedule_id }),
        });
        const data = await response.json();
        console.log("job details", data);
        setJobDetails(data);

      } catch (error) {
        console.error("Error fetching job details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobDetails();
  }, [scheduleData.job_id, scheduleData.schedule_id]);


  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>

        <JobDetailsProvider.Provider value={jobDetails}>
          <JobCardProvider>
            <JobDetailsProvider2>
              <JobCardPage />
            </JobDetailsProvider2>
          </JobCardProvider>
        </JobDetailsProvider.Provider>

        <div className={styles.modalFooter}>
          <SchedulerButton text="Cancel" onClick={onClose} />
          <SchedulerButton text="Completed" />
        </div>
      </div>
    </div>
  );
};

export const useJobDetailsInfo = () => {
  const context = useContext(JobDetailsProvider);
  if (!context) {
    throw new Error('useJobDetails must be used within a JobDetailsProvider');
  }
  return context;
};
