import React, { useState, useReducer, useEffect, useContext } from "react";
import styles from "./addJobModals.module.css";
import ModalNavigation from "./components/ModalNavigation";
import NotesInput from "./components/NotesInput";
import { useJobCustomerModals } from "./hooks/useJobCustomerModals.js";
import { useFetchJobCustomerLists } from "./hooks/useFetchJobCustomerLists.js";
import CustomerCard from "./components/CustomerCard.js";
import CustomerDetailsForm from "./components/CustomerDetailsForm.js";
import CustomerDetails from "./components/CustomerDetails";
import { JobBookingContext } from "./JobBookingContext.js";
import { Autocomplete } from "@react-google-maps/api";
import { useGoogleMapsScript } from "./hooks/useGoogleMapsScript.js";

const JobCustomerDetailsModal = ({
  isOpen,
  closeModal,
  customerDetailsModal,
  jobDetailsModal,
  propertyDetailsModal,
  summaryModal,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedCustomerIndex, setSelectedCustomerIndex] = useState(0);
  const [isFilledPrimaryForm, setIsFilledPrimaryForm] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const [isAddressSelected, setIsAddressSelected] = useState(false);

  const handleCustomerClick = (index) => setSelectedCustomerIndex(index);

  const {
    query,
    setQuery,
    matchingCustomers,
    selectedCustomer,
    isCreateCustomer,
    handleInputChange,
    handleSelectAddress,
    handleCreateCustomer,
    createCustomer,
  } = useJobCustomerModals();

  const { response } = useFetchJobCustomerLists();


  const { setSelectedCustomer, updateBookingData, fetchedData, customerID, setSelectedJobAddress } =
    useContext(JobBookingContext);


  useEffect(() => {
    console.log("--- fetchedData ---", fetchedData);
  }, [query]);

  const { isLoaded, loadError } = useGoogleMapsScript();

  const initialFormState = {
    cust_id: customerID || null,
    org_id: 1,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    mobile: "",
    address: "",
    business_name: "",
    customer_type_id: null,
    payment_term_id: null,
    work_order_details: "",
    special_instruction: "",
    contacts: Array(2).fill({
      id: null,
      email: "",
      phone: "",
      mobile: "",
      lastName: "",
      firstName: "",
      relationship: "",
    }),
  };

  const formReducer = (state, action) => {
    switch (action.type) {
      case "UPDATE_FIELD":
        return { ...state, [action.field]: action.value };
      case "RESET":
        return initialFormState;
      case "POPULATE":
        return { ...state, ...action.data };
      case "UPDATE_CONTACT_FIELD":
        return {
          ...state,
          contacts: state.contacts.map((contact, index) =>
            index === action.contactIndex
              ? { ...contact, [action.field]: action.value }
              : contact
          ),
        };
      default:
        return state;
    }
  };

  const [formState, dispatch] = useReducer(formReducer, initialFormState);

  useEffect(() => {
    dispatch({ type: "UPDATE_FIELD", field: "cust_id", value: customerID });
  }, [customerID]);

  useEffect(() => {
    const { firstName, lastName, address, mobile, phone, customer_type_id } =
      formState;
    setIsFilledPrimaryForm(
      firstName && lastName && address && mobile && phone && customer_type_id
    );
  }, [formState]);

  useEffect(() => {
    if (!isOpen) {
      setQuery(null);
    }


  }, [isOpen]);

  if (!isOpen) return null;
  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <div>Loading maps</div>;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContentPropertyDetails}>
        <div className={styles.modalBody}>
          <ModalNavigation
            customerDetailsModal={customerDetailsModal}
            propertyDetailsModal={propertyDetailsModal}
            jobDetailsModal={jobDetailsModal}
            summaryModal={summaryModal}
            closeModal={closeModal}
            selectedTab={1}
            disabledNavigation={!selectedCustomer}
          />
          <div className={styles.modalInformationContainer}>
            <div className={styles.modalLeft}>
              <h3 className={`${styles.fontBig} bold`}>Job Location</h3>
              <div className={styles.customerJobLocation}>
                <div className={styles.modalPropertyFlex}>
                  <p className={`${styles.fontMedium} bold`}>
                    Job Site Address
                  </p>
                  <p className={`${styles.fontMedium}`}>(Required)</p>
                </div>
                <div>
                  {isLoaded ? (
                    <Autocomplete
                      onLoad={setAutocomplete}
                      onPlaceChanged={() => {
                        if (autocomplete) {
                          const place = autocomplete.getPlace();
                          handleSelectAddress(place.formatted_address);
                          setIsAddressSelected(true);
                          setSelectedJobAddress(place.formatted_address);
                        }
                      }}
                    >
                      <input
                        className={styles.customerJobLocationInput}
                        placeholder="Enter job site address"
                        value={query || ""}
                        onChange={handleInputChange}
                      />
                    </Autocomplete>
                  ) : (
                    <input
                      className={styles.customerJobLocationInput}
                      placeholder="Loading..."
                      disabled
                    />
                  )}
                </div>
                {matchingCustomers.length > 0 && !selectedCustomer && (
                  <div className={styles.customerSelection}>
                    <p className={styles.fontSmall}>
                      Found {matchingCustomers.length} customers.
                    </p>
                    {matchingCustomers.map((customer, index) => (
                      <CustomerCard
                        key={customer.id}
                        customer={customer}
                        index={index}
                        closeModal={closeModal}
                        nextModal={propertyDetailsModal}
                      />
                    ))}
                    <div className={styles.customerCardAddendum}>
                      <p>
                        Can't find a client? Try variations like "Rd" for Road,
                        "St" for Street, or "Dr" for Drive. You can also search
                        by job ID, address, name, details, or purchase orders.
                      </p>
                    </div>
                    <div
                      className={styles.customerCardButton}
                      style={{
                        width: 193,
                        whiteSpace: "nowrap",
                        marginTop: 17,
                      }}
                      onClick={handleCreateCustomer}
                    >
                      <p>Create New Customer</p>
                    </div>
                  </div>
                )}
                {matchingCustomers.length === 0 &&
                  !selectedCustomer &&
                  isAddressSelected && (
                    <div
                      className={styles.customerCardButton}
                      style={{
                        width: 193,
                        whiteSpace: "nowrap",
                        marginTop: 17,
                      }}
                      onClick={handleCreateCustomer}
                    >
                      <p>Create New Customer</p>
                    </div>
                  )}
              </div>
              {isCreateCustomer && (
                <>
                  <h3 className={`${styles.fontBig} bold`}>Customer Details</h3>
                  <div className={styles.customerDetails}>
                    {[0, 1, 2].map((index) =>
                      index === 0 ? (
                        <div
                          className={styles.primaryCustomerContainer}
                          key={index}
                        >
                          <div
                            className={`${styles.propertyContactCustomer} ${selectedCustomerIndex === index
                              ? styles.selectedCustomer
                              : ""
                              }`}
                            onClick={() => handleCustomerClick(index)}
                          >
                            <p>Customer</p>
                          </div>
                          <div className={styles.primaryCustomer}>
                            <p>Primary Account</p>
                          </div>
                        </div>
                      ) : (
                        <div
                          key={index}
                          className={`${styles.propertyContactCustomer} ${selectedCustomerIndex === index
                            ? styles.selectedCustomer
                            : ""
                            }`}
                          onClick={() => handleCustomerClick(index)}
                        >
                          <p key={index}>{`Contact ${index}`}</p>
                        </div>
                      )
                    )}
                  </div>
                  <CustomerDetailsForm
                    customerIndex={selectedCustomerIndex}
                    dispatch={dispatch}
                    formState={formState}
                    address={query}
                  />
                </>
              )}
            </div>
            <div className={styles.modalRight}>
              {selectedCustomer && <CustomerDetails />}
              <NotesInput />
              <div className={styles.buttonContainer}>
                <div className={styles.cancelButton} onClick={closeModal}>
                  <p>Cancel</p>
                </div>
                <div
                  className={
                    selectedCustomer || isFilledPrimaryForm
                      ? styles.nextButton
                      : styles.disabledNextButton
                  }
                  onClick={() => {
                    if (isFilledPrimaryForm) {
                      setSelectedCustomer(formState);
                      updateBookingData("customerDetails", formState);
                      console.log(formState);
                    }
                    if (selectedCustomer || isFilledPrimaryForm) {
                      createCustomer(formState);
                      propertyDetailsModal();
                    }
                  }}
                >
                  <p>Next</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobCustomerDetailsModal;
