import { useState, useEffect } from "react";
import TableItems from "../../../Components/UIComponents/TableItems";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import AddCategories from "./Add/AddCatergories";
import EditCategories from "./Edit/EditCategoires";

const CategoriesPage = () => {
  const [tableData, setTableData] = useState();
  const [refreshTable, setRefreshTable] = useState(false);

  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);

  const toggleAddModal = () => { setIsModalAddOpen(!isModalAddOpen); } 
  const toggleEditModal = () => { setIsModalEditOpen(!isModalEditOpen); }

  const [editedID, setEditedID] = useState();
  const [industry, setIndustry] = useState();

  const headers = ["Category name", "Sub categories", "Trade", "Status", "Action"];

  useEffect(() => {
    setTableData(
      <WBTable tableData="/categoriesJson" tableHeaders={headers} cateFilter servicesReturn={(e) => { setIndustry(e.industry); }}>
          <TableItems 
            itemTitle="Category" itemsAt="categories" identifiers={["title", "subcategories", "trade"]} removeBranch="/removeCategory"
            editFunc={toggleEditModal} setEditedID={setEditedID} setRefreshTable={setRefreshTable}
          />
      </WBTable>
    );
  }, [refreshTable]);

  return (
    <div className="content-area-full">
      {isModalAddOpen && <AddCategories closeModalMethod={toggleAddModal} preloadIndustryId={industry} returnFunc={() => {
        setRefreshTable(!refreshTable)
      }}/>}
      {isModalEditOpen && <EditCategories closeModalMethod={toggleEditModal} editedID={editedID} returnFunc={() => {
        setRefreshTable(!refreshTable)
      }}/>}

      <div className="flex floating-box flex-column bg-white gap-20" style={{ padding: "42px 36px 35px 36px"}}>
        <div className="flex justify-between">
          <div className="text-base">
            <b>Categories</b>
          </div>
          <WBButton title="Add Category" color="white" func={toggleAddModal} />
        </div>
        {tableData}
      </div>
    </div>
  );
};

export default CategoriesPage;
