import { useState } from "react";
import { WBButton, WBIOSToggle } from "../../../../Components/UIComponents/WBComponents";
import { PostDB } from "../../../../Components/DBTools";
import { toast } from "react-toastify";
import { validateStr } from "../../../../lib/js/validateInput";

const AddUtility = ({ closeModalMethod, returnFunc }) => {
    const [title, setTitle] = useState('');
    const [code, setCode] = useState('');
    const [cost, setCost] = useState(0);
    const [status, setStatus] = useState(0);
    const [description, setDescription] = useState('');
    const [active, setActive] = useState(true);
    const [changesMade, setChangesMade] = useState(false);

    const toggleStatus = () => setActive(status => { setChangesMade(true); return !status });

    const Save = async () => {
        if (!validateStr("Name", title)) return;
        if (!validateStr("Cost", cost)) return;

        const body = {
            id: null,
            code,
            title: title,
            description,
            cost,
            status: status === true ? 1 : 0,
        };

        const response = await PostDB({
            branch: '/editUtility',
            json: {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            },
        });

        if (response.api_status === 'success') {
            toast.success('Utility has been successfully added');
            if (returnFunc) returnFunc();
            closeModalMethod();
        } else {
            toast.error('There was an error saving this Utility. Check your fields and try again');
        }
    };

    const handleBGClick = (e) => {
        const background = document.querySelector(".bg-darkblue");
        if (background === e.target && !changesMade) {
            closeModalMethod();
        }
    }

    return (
        <div className="add-utility-page">
            <div className="bg-darkblue" onClick={(e) => handleBGClick(e)}>
                <div className="content">
                    <div className="border-bottom bg-white p-20 gap-10" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className="button-row left">
                            <h4 className="title"><b>Add Utility</b></h4>
                        </div>
                    </div>
                    <div className="border-bottom bg-white h-full gap-10">
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="code" className="form-label">
                                        Utility Code
                                    </label>
                                    <input
                                        type="text"
                                        name="code"
                                        className="half"
                                        id="code"
                                        placeholder="enter utility code E.g. C12345"
                                        onChange={e => { setChangesMade(true); setCode(e.target.value); }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="name" className="form-label">
                                        Utility Name
                                    </label>
                                    <input
                                        type="text"
                                        name="name"
                                        className="half"
                                        id="name"
                                        placeholder="enter utility name"
                                        onChange={e => { setChangesMade(true); setTitle(e.target.value); }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="description" className="form-label">
                                        Utility Description
                                    </label>
                                    <textarea
                                        name="description"
                                        className="half"
                                        id="description"
                                        placeholder="enter utility description"
                                        rows="5"
                                        onChange={e => { setChangesMade(true); setDescription(e.target.value); }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="cost" className="form-label">
                                        Utility Cost
                                    </label>
                                    <input
                                        type="number"
                                        name="cost"
                                        className="half"
                                        id="cost"
                                        placeholder="enter utility cost"
                                        onChange={e => { setChangesMade(true); setCost(e.target.value); }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer flex flex-row space-between" style={{ flexWrap: "wrap" }}>
                        <div className="flex flex-row gap-10" style={{ alignItems: "center"}}>
                            <p>Status</p>
                            <WBIOSToggle active={active} func={() => toggleStatus()} />
                        </div>
                        <div className="flex flex-row gap-10">
                            <WBButton title="Cancel" color="white" func={() => { if (!changesMade) closeModalMethod(); else { if (window.confirm("You are about to leave this page? Are you sure?")) closeModalMethod() } }} />
                            <WBButton title="Save" color={"green"} func={() => Save()} />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}


export default AddUtility;