import { useState, useEffect } from "react";
import { toast } from "react-toastify";

export const useFieldUserForm = (initialUserData, closeModal) => {
	const [formData, setFormData] = useState({
		id: null,
		org_id: null,
		first_name: "",
		surname: "",
		username: "",
		email_work: "",
		email_personal: "",
		phone_mobile: "",
		address_home: "",
		password: null,
		confirm_password: null,
		trade_type_id: null,
		tech_role_id: null,
		truck_rego: "",
		active: 1,
		logo_image: null
	});

	const [dropdownOptions, setDropdownOptions] = useState({
		orgs: [],
		tradeTypes: [],
		techRoles: [],
	});
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);

	useEffect(() => {
		fetchDropdownOptions();
		if (initialUserData) {
			setFormData({
				id: initialUserData.id,
				org_id: initialUserData.org_id || null,
				first_name: initialUserData.first_name || "",
				surname: initialUserData.last_name || "",
				username: initialUserData.username || "",
				email_work: initialUserData.email_work || "",
				email_personal: initialUserData.email_personal || "",
				phone_mobile: initialUserData.phone_mobile || "",
				address_home: initialUserData.address_home || "",
				password: initialUserData.password ||null,
				confirm_password: initialUserData.confirm_password || null,
				trade_type_id: initialUserData.trade_type_id || null,
				tech_role_id: initialUserData.tech_role_id || null,
				truck_rego: initialUserData.truck_rego || "",
				active: initialUserData.active || 1,
				logo_image: initialUserData.logo_image || null,
			});
		}
	}, [initialUserData]);

	const fetchDropdownOptions = async () => {
		setLoading(true);
		try {
			const [orgsResponse, tradeTypesResponse, techRolesResponse] = await Promise.all([
				fetch(`${process.env.REACT_APP_NODEJSAPI}/getOrgs`),
				fetch(`${process.env.REACT_APP_NODEJSAPI}/settings/industries`, {
					method: "POST",
					headers: { "Content-Type": "application/json" },
				}),
				fetch(`${process.env.REACT_APP_NODEJSAPI}/settings/field_users_roles`, {
					method: "POST",
					headers: { "Content-Type": "application/json" },
				}),
			]);

			const [orgsData, tradeTypesData, techRolesData] = await Promise.all([
				orgsResponse.json(),
				tradeTypesResponse.json(),
				techRolesResponse.json(),
			]);

			setDropdownOptions({
				orgs: orgsData.map((org) => ({ id: org.id, name: org.short_name })),
				tradeTypes: tradeTypesData.map((type) => ({ id: type.id, name: type.name })),
				techRoles: techRolesData.map((role) => ({ id: role.id, name: role.name })),
			});
		} catch (e) {
			setError("Failed to load dropdown options");
			console.error("Failed to retrieve data from DB. Error:", e);
		} finally {
			setLoading(false);
		}
	};

	const handleInputChange = (e) => {
		const { id, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[id]: value,
		}));
	};

	const handleDropdownChange = (field, value) => {
		setFormData((prevData) => ({
			...prevData,
			[field]: value,
		}));
	};

	const handleSaveUser = async () => {
		try {
			const payload = { ...formData };
			const isEditing = payload.id !== null;

			if (!payload.org_id) {
				toast.error("Please select organisation.");
				return false;
			}

			if (payload.first_name === "") {
				toast.error("Please enter first name.");
				return false;
			}

			if (payload.surname === "") {
				toast.error("Please enter last name.");
				return false;
			}

			if (payload.username === "") {
				toast.error("Please enter username.");
				return false;
			}

			if (!payload.trade_type_id) {
				toast.error("Please select trade type.");
				return false;
			}

			if (!payload.tech_role_id) {
				toast.error("Please select technician role.");
				return false;
			}

			if (!payload.truck_rego) {
				toast.error("Please select truck registration.");
				return false;
			}

			if (isEditing) {
				payload.user_id = payload.id;
				delete payload.id;
				if (!payload.logo_image?.file) {
					delete payload.logo_image
				}
			} else {
				if (!payload.password) {
					toast.error("Please enter password.");
					return false;
				}
				if (!payload.confirm_password) {
					toast.error("Please confirm password.");
					return false;
				}
				if (payload.password !== payload.confirm_password) {
					toast.error("Passwords don't match.");
					return false;
				}
			}

			const url = `${process.env.REACT_APP_NODEJSAPI}/addFieldUser`;
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(payload),
			});

			const responseText = await response.text();

			if (response.ok) {
				console.log(
					isEditing ? "User updated successfully:" : "User created successfully:",
					responseText,
				);
				toast.success(isEditing ? "User updated successfully:" : "User created successfully:")
				setTimeout(() => {
					closeModal();
				}, 500);
				
			} else {
				throw new Error(responseText || "Failed to save user");
			}
		} catch (error) {
			console.error("Error saving user:", error.message);
			setError("Failed to save user. Please try again.");
			// toast.error("Error: " + error.message);
		}
	};

	const toggleActiveStatus = async (userId, currentActiveStatus) => {
		try {
			const newActiveStatus = currentActiveStatus === 1 ? 0 : 1;
			const payload = {
				user_id: userId,
				active: newActiveStatus,
			};

			const url = `${process.env.REACT_APP_NODEJSAPI}/statusFieldUser `;
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(payload),
			});

			if (response.ok) {
				setFormData((prevData) => ({
					...prevData,
					active: newActiveStatus,
				}));
				return newActiveStatus;
			} else {
				throw new Error("Failed to update user status");
			}
		} catch (error) {
			console.error("Error toggling user status:", error.message);
			throw error;
		}
	};

	const deleteFieldUser = async (userId) => {
		try {
			const url = `${process.env.REACT_APP_NODEJSAPI}/deleteFieldUser`;
			const response = await fetch(url, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ user_id: userId, delete_user: 1 }),
			});

			if (!response.ok) {
				throw new Error("Failed to delete user");
			}

			return true; // Return true if deletion was successful
		} catch (error) {
			console.error("Error deleting user:", error.message);
			throw error;
		}
	};

	return {
		formData,
		dropdownOptions,
		loading,
		error,
		handleInputChange,
		handleDropdownChange,
		handleSaveUser,
		toggleActiveStatus,
		deleteFieldUser,
	};
};
