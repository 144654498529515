import { useState, useEffect } from "react";
import TableItems from "../../../Components/UIComponents/TableItems";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import AddDiscountLogic from "./Add/AddDiscountLogic";
import EditDiscountLogic from "./Edit/EditDiscountLogic";

const DiscountLogicPage = () => {
  const [tableData, setTableData] = useState();
  const [refreshTable, setRefreshTable] = useState(false);

  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);

  const toggleAddModal = () => { setIsModalAddOpen(!isModalAddOpen); }
  const toggleEditModal = () => { setIsModalEditOpen(!isModalEditOpen); }

  const [itemSelected, setItemSelected] = useState([]);

  const headers = ["Title", "Description", "Amount", "Type", "Status", "Action"];

  useEffect(() => {
    setTableData(
      <WBTable tableData="/discountsJson" tableHeaders={headers} isLocalSearch={true} filterBy="title" itemsAt="discounts">
          <TableItems 
            itemTitle="Customer Type" itemsAt="discounts" identifiers={["title", "description", "discount", "type"]} removeBranch="/removeDiscount"
            editFunc={toggleEditModal} setRefreshTable={setRefreshTable}
            setItemSelected={setItemSelected}
          />
      </WBTable>
    );
  }, [refreshTable]);

  return (
    <div className="content-area-full">
      {isModalAddOpen && <AddDiscountLogic closeModalMethod={toggleAddModal} returnFunc={() => {
        setRefreshTable(!refreshTable)
      }}/>}
      {isModalEditOpen && <EditDiscountLogic closeModalMethod={toggleEditModal} itemSelected={itemSelected} returnFunc={() => {
        setRefreshTable(!refreshTable)
      }}/>}

      <div className="flex floating-box flex-column bg-white gap-20" style={{ padding: "42px 36px 35px 36px"}}>
        <div className="flex justify-between">
          <div className="text-base">
            <b>Discounts</b>
          </div>
          <WBButton title="Add Discount" func={toggleAddModal} color={"white"} />
        </div>
        {tableData}
      </div>
    </div>
  );
};

export default DiscountLogicPage;
