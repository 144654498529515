import { useCallback, useContext } from 'react';
import { JobStatus } from '../../../lib/enums';
import { toast } from 'react-toastify';
import { ScheduledJobsContext } from '../SchedulePage';

const useDragAndDrop = (setSchedules, onScheduleMove, styles) => {

  const { setDraggedItem } = useContext(ScheduledJobsContext);

  const handleDragStart = useCallback((event, schedule) => {

    console.log("MOVING JOB", schedule)
    event.dataTransfer.setData("job-details", JSON.stringify({
      ...schedule,
      fromCalendar: true
    }));
  }, []);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
    event.currentTarget.classList.add(styles.dragOver);
  }, [styles]);

  const handleDragLeave = useCallback((event) => {
    event.currentTarget.classList.remove(styles.dragOver);
  }, [styles]);

  const handleDrop = useCallback((event, targetIndex, rowIndex) => {
    event.preventDefault();
    event.currentTarget.classList.remove(styles.dragOver);



    try {
      const data = JSON.parse(event.dataTransfer.getData("job-details"));

      if (data.fromWorkspace) {
        // Item is coming from workspace
        setSchedules(prevSchedules => [...prevSchedules, {
          ...data,
          startIndex: targetIndex,
          rowIndex: rowIndex,
        }]);
        onScheduleMove(data);
      } else if (data.type === "newJob") {

        console.log("--- DATA ---", data);


        const slots = data.slots;
        const techId = event.currentTarget.getAttribute("data-tech-id");
        let startTime = event.currentTarget.getAttribute("data-time");
        startTime = startTime.replace(/(am|pm)/i, (match) => match.toUpperCase());


        let d = new Date(`1970-01-01 ${startTime}`);
        d.setHours(d.getHours() + (slots - 1));

        let hours = d.getHours() % 12 || 12;
        let minutes = d.getMinutes().toString().padStart(2, "0");
        let ampm = d.getHours() >= 12 ? "PM" : "AM";
        let endTime = `${hours}:${minutes} ${ampm}`;

        const newSchedule = {
          startIndex: targetIndex,
          span: 2,
          status: JobStatus.SCHEDULED,
          rowIndex: rowIndex,
          suburb: data.location,
          job_types: data.job_types,
          job_id: data.job_id,
          tech_id: parseInt(techId),
          start_time: startTime,
          end_time: endTime,
          slots: slots
        };

        console.log("---- API TO DATABASE TO CREATE A NEW SCHEDULE ----", newSchedule);

        const insertSchedule = async () => {
          const response = await fetch(`${process.env.REACT_APP_NODEJSAPI}/schedules/create`, {
            method: "POST",
            body: JSON.stringify(newSchedule),
            headers: {
              "Content-Type": "application/json"
            }
          });

          if (response.ok) {
            const data = await response.json();
            console.log("---- DATA FROM API ----", data);


            // update scheduler state and push to scheduler scheduled jobs array
            setSchedules(prevSchedules => {
              console.log("---- PREV SCHEDULES ----", prevSchedules);
              return [...prevSchedules, data]
            });
            setDraggedItem(new Date().getTime());
            toast.success("Job assigned successfully");
          } else {
            toast.error("Failed to assign job");
          }
        };
        insertSchedule();



      } else {
        // Moving within calendar

        console.log("----- data: ", data);
        const slots = 2;

        console.log("----- slots: ", slots);

        const techId = event.currentTarget.getAttribute("data-tech-id");
        const startTime = event.currentTarget.getAttribute("data-time"); // e.g., "01:00 PM"

        // Extract hours, minutes, and AM/PM
        const timeParts = startTime.match(/(\d+):(\d+) (\w{2})/);

        if (!timeParts) {
          console.error("Invalid time format");
          return;
        }

        let [, hours, , ampm] = timeParts;
        hours = parseInt(hours, 10);

        // Convert to 24-hour format
        if (ampm === "PM" && hours !== 12) {
          hours += 12;
        } else if (ampm === "AM" && hours === 12) {
          hours = 0;
        }

        // Create a Date object for manipulation
        let d = new Date(`1970-01-01 ${startTime}`);

        // Add slots (assuming each slot is 1 hour)
        d.setHours(d.getHours() + (slots - 1));

        // // Convert back to 12-hour format
        let endHours = d.getHours() > 12 ? d.getHours() - 12 : d.getHours();

        let endMinutes = d.getMinutes().toString().padStart(2, "0");
        let endAmPm = d.getHours() >= 12 ? "PM" : "AM";
        let endTime = `${endHours}:${endMinutes} ${endAmPm}`;

        // console.log("---- START TIME --- ", startTime);
        // console.log("---- END TIME --- ", endTime);



        // console.log("----- NEW DATA TO UPDATE -----", data, startTime, endTime);

        const updateScheduleData = {
          startIndex: targetIndex,
          span: 2,
          status: JobStatus.SCHEDULED,
          rowIndex: rowIndex,
          location: data.location,
          description: data.description,
          job_id: data.job_id,
          tech_id: parseInt(techId),
          start_time: startTime,
          end_time: endTime,
          slots: slots,
          schedule_id: data.schedule_id
        };

        console.log("---- API TO DATABASE TO UPDATE A SCHEDULE ----", updateScheduleData);

        const updateSchedule = async () => {
          const response = await fetch(`${process.env.REACT_APP_NODEJSAPI}/schedules/update`, {
            method: "POST",
            body: JSON.stringify(updateScheduleData),
            headers: {
              "Content-Type": "application/json"
            }
          });
          console.log("---- RESPONSE FROM API ----", response);

          if (response.ok) {
            const data = await response.json();
            console.log("---- DATA FROM API ----", data);

            // update scheduler state and push to scheduler scheduled jobs array
            // setSchedules(prevSchedules => [...prevSchedules, newSchedule]);
            setSchedules((prevSchedules) =>
              prevSchedules.map((schedule) =>
                schedule.schedule_id === data.schedule_id
                  ? {
                    ...schedule,
                    startIndex: targetIndex,
                    rowIndex: rowIndex,
                    start_time: startTime,
                    end_time: endTime,
                    tech_id: parseInt(techId),
                  }
                  : schedule
              )
            );


            toast.success("Job assigned successfully");
          } else {
            toast.error("Failed to assign job");
          }
        };
        updateSchedule();



      }
    } catch (error) {
      console.error("Error parsing drag data:", error);
    }
  }, [setSchedules, onScheduleMove, styles, setDraggedItem]);

  return {
    handleDragStart,
    handleDragOver,
    handleDragLeave,
    handleDrop
  };
};

export default useDragAndDrop;