import { useState, useEffect } from "react";
import TableItems from "../../../Components/UIComponents/TableItems";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import EditMaterial from "./Edit/EditMaterial";
import AddMaterials from "./Add/AddMaterials";

const MaterialsPage = () => {
  const [tableData, setTableData] = useState();
  const [refreshTable, setRefreshTable] = useState(false);

  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);

  const toggleAddModal = () => { setIsModalAddOpen(!isModalAddOpen); } 
  const toggleEditModal = () => { setIsModalEditOpen(!isModalEditOpen); }

  const [editedID, setEditedID] = useState();

  const headers = ["Code", "Title", "Description", "Cost", "Multiplier", "Final cost", "Linked", "Action"];

  useEffect(() => {
    setTableData(
      <WBTable tableDataOrg="/materialsJson" tableHeaders={headers} mateFilter>
          <TableItems 
            itemTitle="Material" itemsAt="materials" identifiers={["code", "title", "description", "cost", "multiplier", "final_cost"]} removeBranch="/removeMaterial"
            editFunc={toggleEditModal} setEditedID={setEditedID} setRefreshTable={setRefreshTable}
          />
      </WBTable>
    );
  }, [refreshTable]);

  return (
    <div className="content-area-full">
      {isModalAddOpen && <AddMaterials closeModalMethod={toggleAddModal} returnFunc={(val) => {
        setRefreshTable(!refreshTable)
      }}/>}
      {isModalEditOpen && <EditMaterial closeModalMethod={toggleEditModal} editedID={editedID} returnFunc={() => {
        setRefreshTable(!refreshTable)
      }}/>}
      <div className="flex floating-box flex-column bg-white gap-20" style={{ padding: "42px 36px 35px 36px"}}>
        <div className="flex justify-between">
          <div className="text-base">
            <b>Materials</b>
          </div>
          <WBButton title="Add New Material" func={toggleAddModal} color="white" />
        </div>
        {tableData}
      </div>
    </div>
  );
};

export default MaterialsPage;