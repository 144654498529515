import { useState, useEffect } from "react";
import { PostDB } from "../../../../Components/DBTools";
import { WBButton, WBIOSToggle } from "../../../../Components/UIComponents/WBComponents";
import { toast } from "react-toastify";

const EditServiceResponse = ({ closeModalMethod, itemSelected, returnFunc }) => {
    const [id, setID] = useState();
    const [title, setTitle] = useState();
    const [active, setActive] = useState(1);
    const [changesMade, setChangesMade] = useState(false);

    const toggleStatus = () => setActive(status => { setChangesMade(true); return !status });

    useEffect(() => {
        setID(itemSelected.id);
        setTitle(itemSelected.title);
        setActive(itemSelected.active === 1 ? true : false);
    }, []);

    const Save = async () => {
        let bodyObj = {
            id: id,
            title: title,
            status: active === true ? 1 : 0,
            org_id: 1,
            trade_id: 1
        }
        const response = await PostDB({
            branch: "/editServiceResponse",
            json: {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(bodyObj)
            }
        });
        if (response.api_status === "success") {
            toast.success("Service Response has been successfully updated");
            if (returnFunc) returnFunc();
            closeModalMethod();
        }
        else {
            toast.error("There was an error saving this Service Response. Check your fields and try again");
        }
    };

    const handleBGClick = (e) => {
        const background = document.querySelector(".bg-darkblue");
        if (background === e.target && !changesMade) {
            closeModalMethod();
        }
    }

    return (
        <div className="add-utility-page">
            <div className="bg-darkblue" onClick={(e) => handleBGClick(e)}>
                <div className="content">
                    <div className="border-bottom bg-white p-20 gap-10" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="button-row left">
                            <h4 className="title"><b>Edit Service Response</b></h4>
                        </div>
                    </div>
                    <div className="border-bottom bg-white h-full gap-10">
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="code">Service Response ID:</label>
                                    <p className="pl-10" id="code">{id ? id : "N/A"}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="typeTitle" className="form-label">
                                        Service Response Name
                                    </label>
                                    <input
                                        type="text"
                                        name="typeTitle"
                                        className="half"
                                        id="typeTitle"
                                        placeholder="Enter customer type"
                                        defaultValue={title}
                                        onChange={(e) => { setChangesMade(true); setTitle(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer flex flex-row space-between" style={{ flexWrap: "wrap" }}>
                        <div className="flex flex-row gap-10" style={{ alignItems: "center"}}>
                            <p>{active ? "Disable" : "Enable"} Response</p>
                            <WBIOSToggle active={active} func={() => toggleStatus()} />
                        </div>
                        <div className="flex flex-row gap-10">
                            <WBButton title="Cancel" color="white" func={() => { if (!changesMade) closeModalMethod(); else { if (window.confirm("You are about to leave this page? Are you sure?")) closeModalMethod() } }} />
                            <WBButton title="Save" color={"green"} func={() => Save()} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditServiceResponse;