import { useEffect, useState } from "react";
import TableItems from "../../../Components/UIComponents/TableItems";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import AddIndustry from "./Add/AddIndustry";
import EditIndustry from "./Edit/EditIndustry";

const IndustriesPage = () => {
  const [tableData, setTableData] = useState();
  const [refreshTable, setRefreshTable] = useState(false);

  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);

  const toggleAddModal = () => { setIsModalAddOpen(!isModalAddOpen); }
  const toggleEditModal = () => { setIsModalEditOpen(!isModalEditOpen); }

  const [itemSelected, setItemSelected] = useState([]);

  const headers = ["Industry", "Status", "Action"];

  useEffect(() => {
		setTableData(
			<WBTable tableData="/settings/industries" tableHeaders={headers} isLocalSearch={true} filterBy="name">
          <TableItems 
            itemTitle="Industry" identifiers={["name"]} removeBranch="/removeIndustry"
            editFunc={toggleEditModal} setRefreshTable={setRefreshTable}
            setItemSelected={setItemSelected}
          />
        </WBTable>
		);
	}, [refreshTable]);


  return (
    <div className="content-area-full">
      {isModalAddOpen && <AddIndustry closeModalMethod={toggleAddModal} returnFunc={() => {
        setRefreshTable(!refreshTable)
      }}/>}
      {isModalEditOpen && <EditIndustry closeModalMethod={toggleEditModal} itemSelected={itemSelected} returnFunc={() => {
        setRefreshTable(!refreshTable)
      }}/>}

      <div className="flex floating-box flex-column bg-white gap-20" style={{ padding: "42px 36px 35px 36px"}}>
        <div className="flex justify-between">
          <div className="text-base">
            <b>Industries</b>
          </div>
          <WBButton title="Add Industry" func={toggleAddModal} color={"white"} />
        </div>
        {tableData}
      </div>
    </div>
  );
};

export default IndustriesPage;
