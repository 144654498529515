import { useEffect, useRef, useState } from "react";
import { PostDB } from "../../DBTools";
import { WBDropdown2, WBDropdownOption, WBModal, useWBTableData } from "../../UIComponents/WBComponents";
import { toast } from "react-toastify";
import compDefault from "../UserManagement/FieldUserModal/assets/compDefault.svg"

const ServiceItems = ({ editFunc, setItemRemoved }) => {
    const selectedService = useRef();
    const [openDropdownId, setOpenDropdownId] = useState(null);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const toggleDeleteServiceModal = (service) => {
        service !== 0 ? selectedService.current = service : selectedService.current = 0;
        setIsDeleteModalOpen(!isDeleteModalOpen);
    }

    const [spinnerActive, setSpinnerActive] = useState(false);

    const deleteService = async () => {
        let removedService = selectedService.current.id;
        const response = await PostDB({
            branch: "/removeService", json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id: removedService })
            }
        });
        if (response === "success") {
            toast.success("Service has been removed successfully.");
            setItemRemoved(itemRemoved => !itemRemoved);
        }
        else {
            toast.error("This service could not be removed. Please try again later.");
        }
        toggleDeleteServiceModal();
    }

    const thumbNailExist = (media) => {
        let myThumbnail = media.find(x => x.is_thumbnail === 1);
        return myThumbnail !== undefined ? myThumbnail.media_link : null;
    }

    const [servicesFormatted, formatServices] = useState(<></>);

    const items = useWBTableData();
    useEffect(() => {
        setSpinnerActive(true);
        if (items !== null && items !== undefined) {
            if (items.services.length > 0) {
                // let myThumbnail = items.services.media.find(x => x.is_thumbnail === 1);
                const sortedItems = [...items.services].sort((a,b) => b.id - a.id);
                formatServices(
                    sortedItems.map((service, index) => (
                        <tr key={"service" + index} id={"service" + service.id} onDoubleClick={() => { editFunc(service.id); }}>
                            <td style={{ padding: "8px 36px"}}>
                                <div className="serviceCompanyLogo">
                                    <img
                                        src={thumbNailExist(service.media) ? thumbNailExist(service.media) : compDefault}
                                        alt="Thumbnail"
                                        style={{ width: thumbNailExist(service.media) ? "100%" : "50%", height: thumbNailExist(service.media) ? "100%" : "50%", objectFit: thumbNailExist(service.media) ? "cover" : "contain" }}
                                    />
                                </div>
                            </td>
                            <td style={{ padding: "8px 36px" }}>
                                {service.code ? <b>{service.code}</b> : <i>No code available</i>}
                            </td>
                            <td style={{ padding: "8px 36px" }}>
                                <div className="longDesc" title={service.short_desc}>
                                    {service.short_desc}
                                </div>
                                <div className="categories flex flex-row gap-10" style={{ alignItems: "center", paddingTop: "4px"}}>
                                    <p>{service.trade}</p>
                                    {service.category && <div className="flex flex-row" style={{ alignItems: "center"}}><i className="icon-arrow-right-light mr-10"></i> <p>{service.category}</p></div>}
                                    {service.sub_category && <div className="flex flex-row" style={{ alignItems: "center"}} ><i className="icon-arrow-right-light mr-10"></i> <p>{service.sub_category}</p></div>}
                                </div>
                            </td>
                            <td style={{ padding: "8px 36px" }}>{service.price}</td>
                            <td style={{ padding: "8px 36px" }}>
                                {service.status === 1 ? <span className="text-green bold">Active</span> : <span className="text-red bold">Inactive</span>}
                            </td>
                            <td style={{ padding: "8px 36px", width: "100%"}}>
                                <div style={{ display: "flex", justifyContent: "flex-end",  width: "100%"}}>
                                    <WBDropdown2 leftitem={
                                        <div className="title">
                                            <div className="option">
                                                <span className="dot-icon"></span>
                                                <span className="dot-icon"></span>
                                                <span className="dot-icon"></span>
                                            </div>
                                        </div>
                                    } align={"end"}>
                                        <WBDropdownOption title="Edit" func={() => { editFunc(service.id); }} />
                                        <WBDropdownOption title="Delete" func={() => toggleDeleteServiceModal(service)} />
                                    </WBDropdown2>
                                </div>
                            </td>
                        </tr>
                    ))
                );
            }
            else {
                formatServices(
                    <tr id="service0">
                        <td style={{ padding: "8px" }}></td>
                        <td style={{ padding: "8px" }}></td>
                        <td style={{ padding: "8px" }}>No services found</td>
                        <td style={{ padding: "8px" }}></td>
                        <td style={{ padding: "8px" }}></td>
                        <td style={{ padding: "8px" }}></td>
                        <td style={{ padding: "8px" }}></td>
                    </tr>
                );   
            }
        }
        else {
            formatServices(
                <tr id="service0">
                    <td style={{ padding: "8px" }}></td>
                    <td style={{ padding: "8px" }}></td>
                    <td style={{ padding: "8px" }}>Failed to load services</td>
                    <td style={{ padding: "8px" }}></td>
                    <td style={{ padding: "8px" }}></td>
                    <td style={{ padding: "8px" }}></td>
                    <td style={{ padding: "8px" }}></td>
                </tr>
            );   
        }
        setSpinnerActive(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    return (
        <tbody>
            {isDeleteModalOpen && <WBModal closeFunction={toggleDeleteServiceModal} submitFunction={deleteService} title={'Service named "' + selectedService.current.short_desc + '"'} description={"Are you sure you want to delete this Service Item?"}><p style={{ padding: "20px"}}>This cannot be reversed once completed.</p></WBModal>}
            {!spinnerActive && servicesFormatted}
        </tbody>  
    )
}

export default ServiceItems;