import React, { useEffect, useState } from "react";
import styles from './css/scheduler.module.css';

const JobList = ({ jobs }) => {
  const [jobsReordered, setJobsReordered] = useState([]);


  useEffect(() => {
    if (!jobs || jobs.length === 0) return;
    setJobsReordered(reOrderJobs(jobs));
  }, [jobs]);

  const reOrderJobs = (jobs) => {
    const jobsByTrade = jobs.reduce((acc, job) => {
      if (!acc[job.trade]) {
        acc[job.trade] = [];
      }
      acc[job.trade].push({
        job_id: job.job_id,
        location: job.location,
        job_types: job.job_types,
        time: job.time,
        trade: job.trade,
      });
      return acc;
    }, {});


    return jobsByTrade; // Convert object to array
  };


  return (

    <>

      {
        Object.entries(jobsReordered).map(([trade, job]) => (
          <React.Fragment key={`${Date.now()}-${Math.floor(Math.random() * 1000000)}-`}>
            <div className={styles.jobListContainer} >
              <p>{trade}</p>
              <div className={styles.jobList}>

                {
                  job.map((job) => (
                    <div
                      className={styles.jobItem}
                      draggable="true"
                      onDragStart={e => {
                        e.dataTransfer.setData(
                          'job-details',
                          JSON.stringify({
                            ...job,
                            type: 'newJob',
                            slots: 2
                          })
                        );
                      }}
                      key={`${Date.now()}-${Math.floor(Math.random() * 1000000)}`}
                    >
                      <div className={styles.jobLine}></div>

                      <div className={styles.jobDetailsWrapper}>
                        <div className={styles.jobDetailsContainer}>

                          <div className={styles.jobSideDetails}>
                            <div className={styles.jobLocation}>
                              {job.location}
                              <span className={styles.jobTime}>{job.time}</span>
                            </div>
                            <div className={styles.jobTags}>{job && job.job_types?.split(",").map(type => <span key={`${Date.now()}-${Math.floor(Math.random() * 1000000)}`} className={styles.tag}>{type}</span>)}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                }

              </div>
            </div>
          </ React.Fragment>
        ))

      }
    </>


  )
}

export default JobList;