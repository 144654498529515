
import styles from '../css/schedulercalendar.module.css';

const ScheduledBox = ({
  span,
  status,
  onClick,
  onDragStart,
  stackIndex = 0,
  draggable,
  location,
  job_types,
  lastCell = false
}) => {

  const width = `calc(${(lastCell ? 1 : span) * 100}% - 5px)`;
  const boxStyle = {
    width: width,
    left: `${2.5}px`,
    top: `${stackIndex * 24}px`,

    zIndex: stackIndex + 1,
    cursor: draggable ? 'move' : 'pointer',
  };

  const getBoxColorStyle = status => {
    switch (status) {
      case 'Scheduled':
        return { backgroundColor: '#FFF9F2' };
      case 'Completed':
        return { backgroundColor: 'rgba(229, 253, 241, 1)' };
      case 'Started':
        return { backgroundColor: 'rgba(242, 249, 255, 1)' };
      case 'Ongoing':
        return { backgroundColor: 'rgba(255, 249, 235, 1)' };
      default:
        return {};
    }
  };

  const getLabelColorStyle = status => {
    switch (status) {
      case 'Scheduled':
        return { backgroundColor: '#FF8935' };
      case 'Completed':
        return { backgroundColor: 'rgba(21, 195, 111, 1)' };
      case 'Started':
        return { backgroundColor: 'rgba(0, 148, 255, 1)' };
      case 'Ongoing':
        return { backgroundColor: 'rgba(255, 164, 28, 1)' };
      default:
        return { backgroundColor: 'rgba(233, 98, 98, 1)' };
    }
  };

  const boxColorStyle = getBoxColorStyle(status);
  const labelColorStyle = getLabelColorStyle(status);

  return (
    <div
      className={`${styles.scheduledBox} ${styles[status]}`}
      style={{
        ...boxStyle,
        ...boxColorStyle,
      }}
      draggable="true"
      onDragStart={onDragStart}
      onDoubleClick={onClick}
    >
      <p className={styles.scheduledBoxTitle}>{location}</p>
      <p className={styles.scheduledBoxSubtitle}>{job_types}</p>
      <div className={styles.scheduledBoxLabel} style={labelColorStyle}>
        <p>{status.charAt(0).toUpperCase() + status.slice(1)}</p>
      </div>
    </div>
  );
};

export default ScheduledBox;
