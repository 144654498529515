import { WBButton } from '../../UIComponents/WBComponents';
import styles from './JobDetails.module.css';
import EditJobDetailsModal from './EditJobDetails/EditJobDetailsModal';
import { useJobDetails } from './context/JobDetailsContext';
// import { useJobDetailsInfo } from '@/Pages/Schedule/SchedulerModal';
import { useJobDetailsInfo as useJobData } from "@/Pages/Schedule/SchedulerModal";

import { useState, useEffect } from 'react';
import SiteMap from "@/features/scheduler/components/SiteMap";

const JobDetails = () => {
  const [showPin, setShowPin] = useState(false);
  const { openEditModal } = useJobDetails();
  const [job, setJob] = useState({});

  const jobDetails = useJobData();


  useEffect(() => {
    setJob(jobDetails);
  }, [jobDetails]);


  const getLabelColorStyle = status => {
    switch (status) {
      case 'Scheduled':
        return { backgroundColor: '#FF8935' };
      case 'Completed':
        return { backgroundColor: 'rgba(21, 195, 111, 1)' };
      case 'Started':
        return { backgroundColor: 'rgba(0, 148, 255, 1)' };
      case 'Ongoing':
        return { backgroundColor: 'rgba(255, 164, 28, 1)' };
      default:
        return { backgroundColor: 'rgba(233, 98, 98, 1)' };
    }
  };


  return (
    <div className={styles.jobDetails}>
      {/* header */}
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          <span className={styles.jobDetailsText}>Job Details</span>
          <div className={styles.jobIdContainer}>
            <span className={styles.jobId}>{job.job_id}</span>
            <span className={styles.jobIdStatus} style={getLabelColorStyle(job.job_status)}>{job.job_status}</span>
          </div>
        </div>

        <div className={styles.headerRight}>
          {showPin ? (
            <div className={styles.securityPinContainer}>
              <i className={styles.iconKey}></i>
              <span className={styles.securityPinText}>Security Pin</span>
              <span className={styles.securityPinValue}>4256</span>
            </div>
          ) : (
            <WBButton
              title="Security Pin"
              icon={<i className={styles.iconKey}></i>}
              color="grey"
              height="40px"
              styles={{ padding: '0 24px' }}
              func={() => setShowPin(true)}
            />
          )}
          <WBButton
            icon={<i className={styles.iconPen}></i>}
            color="grey"
            height="40px"
            styles={{ padding: '12px' }}
            func={openEditModal}
          />
        </div>
      </div>

      {/* job site address */}
      <div className={styles.jobsiteAddress}>
        <h2 className={styles.addressLabel}>Job Site Address</h2>
        <p className={styles.address}>
          <i className={styles.iconLocation}></i>{job.address_line}, {job.suburb} {job.postcode} {job.state}
        </p>
        <div className={styles.mapContainer}>
          <SiteMap address={`${job.address_line}, ${job.suburb} ${job.postcode} ${job.state}`} />
          <button className={`btn btn-default ${styles.mapIcon}`}>
            <i className={styles.iconMap}></i>
          </button>
        </div>
      </div>

      {/* site contact */}
      <div className={styles.siteContact}>
        <h2 className={styles.siteContactTitle}>Site Contact</h2>
        <div className={styles.siteContactContainer}>
          <div className={styles.siteContactTop}>
            <div className={styles.contact}>
              <span className={styles.contactLabel}>Name</span>
              <span className={styles.contactValue}>Leslie Alexander</span>
            </div>
            <div className={styles.contact}>
              <span className={styles.contactLabel}>Customer Relationship</span>
              <span className={styles.contactValue}>Tenant</span>
            </div>
          </div>
          <div className={styles.siteContactBottom}>
            <div className={styles.contact}>
              <span className={styles.contactLabel}>Mobile</span>
              <span className={styles.contactValue}>
                <i className={styles.iconPhone} />
                0444 645 555
              </span>
            </div>
            <div className={styles.contact}>
              <span className={styles.contactLabel}>Phone</span>
              <span className={styles.contactValue}>
                <i className={styles.iconPhone} />
                987 222 111
              </span>
            </div>
            <div className={styles.contact}>
              <span className={styles.contactLabel}>Email</span>
              <span className={styles.contactValue}>
                <i className={styles.iconSMS} />
                customer@hotmail.com
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* job type (light blue bg) */}
      <div className={styles.jobDetails}>
        <div className={styles.jobDetailsContainer}>
          {/* column 1 */}
          <div className={styles.jobDetail}>
            <span className={styles.jobDetailLabel}>Job Type</span>
            <span className={styles.jobDetailValue}>Other - Plumbing</span>
          </div>
          <div className={styles.jobDetail}>
            <span className={styles.jobDetailLabel}>Job Priority</span>
            <span className={styles.jobDetailValue}>Non Urgent</span>
          </div>
          <div className={styles.jobDetail}>
            <span className={styles.jobDetailLabel}>Industry</span>
            <span className={styles.jobDetailValue}>Plumbing</span>
          </div>

          {/* column 2 */}
          <div className={styles.jobDetail}>
            <span className={styles.jobDetailLabel}>Service Type</span>
            <span className={styles.jobDetailValue}>New Installation</span>
          </div>
          <div className={styles.jobDetail}>
            <span className={styles.jobDetailLabel}>Callout Fee</span>
            <span className={styles.jobDetailValue}>$49</span>
          </div>
          <div className={styles.jobDetail}>
            <span className={styles.jobDetailLabel}>Preferred Date & Time</span>
            <span className={styles.jobDetailValueContainer}>
              <span className={styles.jobDetailValue}>
                <i className={styles.iconCalendar} />
                18-03-2024
              </span>
              <span>-</span>
              <span className={styles.jobDetailValue}>
                <i className={styles.iconClock} />
                8am-12pm
              </span>
            </span>
          </div>
        </div>
      </div>

      {/* job description */}
      <div>
        <h2 className={styles.jobDescriptionTitle}>Job Description</h2>
        <div className={styles.description}>
          <p>blocked drain downstairs</p>
          <p>**water overflowing</p>
          <p>**PLS CALL Stacy first to discuss quote and she is also the one</p>
          <p>responsible for payments for the callout</p>
          <p>**her daughter Christine will be on site pls cba 30mins</p>
          <p>Jerric</p>
          <div className={styles.verticalLine}></div>
        </div>
      </div>

      <EditJobDetailsModal />
    </div >
  );
};

export default JobDetails;
