import { useEffect, useState } from "react";
import NavItem from "./NavItem";
import { CallDB } from "../DBTools";
import { WBButton, WBDropdown, WBDropdownOption } from "../UIComponents/WBComponents";
import { Link } from "react-router-dom";
import SubNav from "./SubNav";
import { useJobModal } from '../../lib/contexts/JobModalContext';


const NavBar = ({ changeOrg, isSettings, hideSubNav }) => {
  const [dbData, setDBData] = useState();
  const [orgs, setOrgs] = useState();
  // const selectedOrg = useRef(localStorage.getItem("selectedOrg") ? localStorage.getItem("selectedOrg") : 1);
  const [selectedOrgName, setSelectedOrgName] = useState(localStorage.getItem("selectedOrgName") || 'Sydney - NSW');
  const [activeNav, setActiveNav] = useState(window.location.pathname);
  const [activeSubNav, setActiveSubNav] = useState(window.location.pathname);
  const { toggleModal } = useJobModal();


  useEffect(() => {
    const GetPageData = async () => {
      setDBData(await CallDB({ branch: "/getOrgs" }));
    }
    // console.log("OrgID: " + selectedOrg.current + ", OrgName: ", selectedOrgName.current);
    GetPageData();
  }, []);

  useEffect(() => {
    if (dbData !== undefined && dbData !== null) {
      // console.log("dbData: ", dbData);
      setOrgs(
        dbData.map((org) => (
          <WBDropdownOption key={org.id} title={org.short_name} id={org.id} func={() => { localStorage.setItem("selectedOrgName", org.short_name); setSelectedOrgName(org.short_name); changeOrg(org.id); }} />
        ))
      )
    }
  }, [changeOrg, dbData])

  var pathname = window.location.pathname.split('/')[1];
  const isAccountingPage = pathname === "accounting";

  const handleSubNavClick = (path) => {
    setActiveSubNav(path);
    setActiveNav(null); // Clear main nav selection when subnav is clicked
  };

  const handleNewJob = () => {
    toggleModal('addNewJobCustomer');
  };

  if (isSettings) {
    return (
      <nav className="settingsnav">
        <div className="left">
          <div id="logo">
            <a className="navbar-brand" href="/">
              <img max-height={"50px"} width={"50px"} src="/images/wurkboxlogo2.svg" alt="Workbox Thumbnail" />
            </a>
          </div>
          <WBButton title="Exit Settings" color={"white"} link="/" />
          {/* <NavItem title="Exit Settings" link="/" /> */}
        </div>
        <div className="right">
          <NavItem phone />
          <NavItem search />
          <WBDropdown leftitem={<i className="icon-bell filter-white"></i>} color={"transparentwhite"} >
            <WBDropdownOption title={"Report ready"} />
          </WBDropdown>
          <WBDropdown image={"/images/profile-image.png"} height={"50px"} width={"50px"} color={"transparentwhite"} nopadding>
            {/* <WBDropdownOption title="My Profile" link="/home" />
                  <WBDropdownOption title="Profile Settings" link="/profile" />
                  <div className="hr" />
                  <p className="font-size-12 left-align bold">Wurkbox Platform</p>
                  <WBDropdownOption title="Wurkbox Settings" />
                  <div className="hr" /> */}
            <WBDropdownOption title="Help" link="/help" color={"transparentwhite"} />
            <WBDropdownOption title="Logout" func={() => { localStorage.removeItem("sessiontoken"); window.location.href = "/login" }} />
          </WBDropdown>
        </div>
      </nav>
    )
  }
  else {
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div id="left">
            <div id="logo">
              <Link className="navbar-brand" to="/">
                <img height={"50px"} width={"50px"} src="/images/wurkboxlogo2.svg" alt="Workbox Thumbnail" />
              </Link>
            </div>

            <ul id="navbar-nav">
              {/* <NavItem title="Dashboard" link="/dashboard" active={pathname === "dashboard" ? "active" : ""} /> */}
              <NavItem
                title="Scheduler"
                link="/scheduler"
                icon="icon-scheduler"
                active={activeNav === "/scheduler"}
                onClick={() => {
                  setActiveNav("/scheduler");
                  setActiveSubNav(null);
                }}
              />
              {/* <NavItem title="Technicians" link="/technicians" /> */}
              <NavItem
                title="Reports"
                link="/reports"
                icon="icon-reports"
                active={activeNav === "/reports"}
                onClick={() => {
                  setActiveNav("/reports");
                  setActiveSubNav(null);
                }}
              />
              <NavItem
                title="Price Book"
                link="/pricebook"
                icon="icon-price-book"
                active={activeNav === "/pricebook"}
                onClick={() => {
                  setActiveNav("/pricebook");
                  setActiveSubNav(null);
                }}
              />
              <NavItem
                title="Accounting"
                link="/accounting"
                icon="icon-accounting"
                active={activeNav === "/accounting"}
                onClick={() => {
                  setActiveNav("/accounting");
                  setActiveSubNav(null);
                }}
              />
            </ul>
          </div>
          <div id="right">

            <WBButton title="New Job" color="blue" height="38px" noshadow noborder func={handleNewJob} />
            <WBDropdown leftitem={selectedOrgName} rightitem={<i className="icon-arrow-down filter-white"></i>} color="transparentwhite">
              {orgs}
            </WBDropdown>
            <NavItem phone />
            <NavItem search />
            {/* <NavItem bell /> */}
            <WBDropdown leftitem={<i className="icon-bell filter-white"></i>} color={"transparentwhite"} />
            <WBDropdown image={"/images/profile-image.png"} height={"50px"} width={"50px"} color={"transparentwhite"} nopadding>
              <WBDropdownOption title="My Profile" link="/home" />
              <WBDropdownOption title="Profile Settings" link="/profile" />
              <div className="hr" />
              {/* <p className="font-size-12 left-align bold">Wurkbox Platform</p> */}
              <WBDropdownOption title="Wurkbox Settings" link={"/settings"} />
              <div className="hr" />
              <WBDropdownOption title="Help" link="/help" />
              <WBDropdownOption title="Logout" func={(e) => { localStorage.removeItem("sessiontoken"); window.location = "/login"; }} />
            </WBDropdown>
          </div>
        </nav>

        {!hideSubNav && (
          <SubNav
            activeSubNav={activeSubNav}
            onSubNavClick={handleSubNavClick}
          />
        )}
      </div>
    );
  }
};

export default NavBar;
