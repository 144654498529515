import MediaDocumentTabs from '../../../Components/Customer/JobCard/MediaDocumentTabs';
import NotesTabGroup from '../../../Components/Customer/JobCard/NotesTabGroup';
import JobPerformance from '../../../Components/Customer/JobCard/JobPerformance';
import FinanceSummaryCards from '../../../Components/Customer/JobCard/FinanceSummaryCards';
import JobCardHeader from '../../../Components/Customer/JobCard/JobCardHeader';
import JobDetails from '../../../Components/Customer/JobCard/JobDetails';
// import SubJobs from '../../../Components/Customer/JobCard/SubJobs';
import InvoiceSection from '../../../Components/Customer/JobCard/InvoiceSection';
import styles from './JobCardPage.module.css';
import sub_jobs_styles from "@/Components/Customer/JobCard/SubJobs.module.css";
import LogDetails from '../../../Components/Customer/JobCard/LogDetails';
import PriceBookModal from '../../../Components/Customer/AddService/PriceBookModal/PriceBookModal';
// import XeroModal from '../../../Components/Customer/JobCard/XeroModal/XeroModal';
import CustomServiceModal from '../../../Components/Customer/AddService/CustomServiceModal/CustomServiceModal';
import PaymentModal from '../../../Components/Customer/AddService/PaymentModal/PaymentModal';
import CreateCallBackModal from '../../../Components/Customer/JobCard/CreateCallBack/CreateCallBackModal';
import { useJobCard } from '../../../Components/Customer/context/JobCardContext';

import { WBButton } from "@/Components/UIComponents/WBComponents";



/**
 * http://localhost:3000/customer/123/456/789
 */

const JobCardPage = () => {

  const {
    activeModalId,
    selectedTab,
    complaintLogs,
    invoiceLogs,
    quoteLogs,
    isCallbackModalOpen,
    closeCallbackModal,
    materialsLogs,
  } = useJobCard();

  const getLogDetails = () => {
    switch (selectedTab) {
      case 'Complaints':
        return { type: 'Complaints', logs: complaintLogs };
      case 'Quotes':
        return { type: 'Quotes', logs: quoteLogs };
      case 'Milestone':
        return { type: 'Milestone', logs: null };
      case 'Materials':
        return { type: 'Materials', logs: materialsLogs };
      default:
        return { type: 'Sales', logs: invoiceLogs };
    }
  };

  const { type, logs } = getLogDetails();

  return (
    <div className={styles.jobCardPage}>
      <header>
        <JobCardHeader />
      </header>
      <main className={styles.main}>
        <div className={styles.width}>
          <div>
            <div className={sub_jobs_styles.subJobs}>
              <div className={sub_jobs_styles.left}>
                <h3>SubJobs</h3>
                <WBButton
                  title="436535"
                  icon={<i className={sub_jobs_styles.iconBriefCase}></i>}
                  color="grey"
                  height="40px"
                  styles={{ padding: "0 24px" }}
                />
                <WBButton
                  title="365643"
                  icon={<i className={sub_jobs_styles.iconBriefCase}></i>}
                  color="grey"
                  height="40px"
                  styles={{ padding: "0 24px" }}
                />
              </div>
              <div className={sub_jobs_styles.right}>
                <h3>Callback Jobs</h3>
                <WBButton
                  title="452221"
                  icon={<i className={sub_jobs_styles.iconBriefCase}></i>}
                  color="grey"
                  height="40px"
                  styles={{ padding: "0 24px" }}
                />
              </div>
            </div>
          </div>
          <div className={styles.jobDetails}>
            <div className={styles.left}>
              <JobDetails />
            </div>
            <div className={styles.right}>
              <FinanceSummaryCards />
              <JobPerformance />
              <NotesTabGroup />
              <MediaDocumentTabs />
            </div>
          </div>
        </div>

        <div className="invoiceSection">
          <InvoiceSection />
        </div>
        {logs !== null && (
          <div className={styles.invoiceLog}>
            <LogDetails type={type} rawLogs={logs} />
          </div>
        )}
      </main>

      {/* Modals */}
      {activeModalId === 'priceBook' && <PriceBookModal />}
      {activeModalId === 'customService' && <CustomServiceModal />}
      {activeModalId === 'payment' && <PaymentModal />}
      <CreateCallBackModal isOpen={isCallbackModalOpen} onClose={closeCallbackModal} />
    </div >
  );
};

export default JobCardPage;
