import { useEffect, useState } from "react";
import { PostDB } from "../../../../Components/DBTools";
import { WBButton } from "../../../../Components/UIComponents/WBComponents";
import { toast } from "react-toastify";
import { validateStr, validateInt } from "../../../../lib/js/validateInput";

const EditMaterial = ({ editedID, closeModalMethod, returnFunc }) => {
    const [matID, setMatID] = useState();
    const [matCode, setMatCode] = useState();
    const [matTitle, setMatTitle] = useState();
    const [matCost, setMatCost] = useState();
    const [matDesc, setMatDesc] = useState("");
    const [matSupplier, setMatSupplier] = useState();
    const [matSupplierName, setMatSupplierName] = useState();
    const [allSuppliers, setAllSuppliers] = useState([]);
    const [suppliersFormatted, setSuppliersFormatted] = useState();
    const [supTitle, setSupTitle] = useState();

    const [changesMade, setChangesMade] = useState(false);
    const [addNewSupplierStatus, setAddNewSupplierStatus] = useState(false);

    const toggleAddNewSupplier = async () => {
        if (addNewSupplierStatus) {
            if (!supTitle) {
                toast.error("Input a supplier name");
                return;
            }
            let response = await PostDB({
                branch: "/addsupplier", json: {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        name: supTitle,
                    })
                }
            });
    
            if (response.status === "success") {
                toast.success("Material has been successfully updated");
                setMatSupplier(response.inserted_supplier_id);
                setSupTitle();
                GetSuppliers(response.inserted_supplier_id);
                setAddNewSupplierStatus(!addNewSupplierStatus);      
            }
            else {
                toast.error("There was an error saving this Material. Check your fields and try again");
            }
        } else {
            setAddNewSupplierStatus(!addNewSupplierStatus);
        }
    };
    
    const GetSuppliers = async (materialSupplierId=false) => {
        let response = await PostDB({
            branch: "/getAllSuppliers", json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        });
        if (response !== undefined && response !== null) {
            setAllSuppliers(response.suppliers);
            if (materialSupplierId) {setMatSupplierName(response.suppliers.find(supplier => supplier.id === materialSupplierId)?.name);}

        }
    };

    useEffect(() => {
        const GetMaterial = async () => {
            const response = await PostDB({
                branch: "/getMaterial",
                json: {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ material_id: editedID })
                }
            });

            if (typeof response !== 'string') {
                setMatID(response.id);
                setMatSupplier(response.supplier_id);
                setMatCode(response.code);
                setMatTitle(response.title);
                setMatDesc(response.description);
                setMatCost(response.cost);
                GetSuppliers(response.supplier_id);
            }
            else {
                toast.error("Could not retrieve material with ID: " + editedID);
            }
        }
        GetMaterial();
    }, []);

    const Save = async () => {
        if (!validateInt("Supplier", matSupplier)) return;
        if (!validateStr("Name", matTitle)) return;
        if (!validateStr("Cost", matCost)) return;

        let bodyObj = {
            id: matID,
            supplier_id: matSupplier,
            code: matCode,
            title: matTitle,
            description: matDesc,
            cost: matCost,
            org_id: 1,
            trade_id: 1
        }
        // let response = "Success";
        let response = await PostDB({
            branch: "/editMaterial", json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(bodyObj)
            }
        });
        if (response.api_status === "success") {
            toast.success("Material has been successfully updated");
            if (returnFunc) returnFunc();
            closeModalMethod();
        }
        else {
            toast.error("There was an error saving this Material. Check your fields and try again");
        }
    }

    useEffect(() => {

    }, [matSupplier])

    useEffect(() => {
        if (allSuppliers.length > 0) {
            setSuppliersFormatted(
                allSuppliers.map((supplier, i) =>
                    <option key={i} value={supplier.id}>{supplier.name}</option>
                )
            )
        }
    }, [allSuppliers]);

    const handleBGClick = (e) => {
        const background = document.querySelector(".bg-darkblue");
        if (background === e.target && !changesMade) {
            closeModalMethod();
        }
    }

    return (
        <div className="add-utility-page">
            <div className="bg-darkblue" onClick={(e) => handleBGClick(e)}>
                <div className="content">
                    <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="button-row left">
                            <h4 className="title"><b>Edit Material</b></h4>
                        </div>
                    </div>
                    <div className="body">
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="code">Material ID:</label>
                                    <p className="pl-10" id="code">{matID ? matID : "N/A"}</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="code">Code:</label>
                                    <p className="pl-10" id="code">{matCode ? matCode : "N/A"}</p>
                                </div>
                            </div>
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="code">Supplier ID:</label>
                                    <p className="pl-10" id="code">{matSupplier ? matSupplier : "N/A"}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="flex flex-column gap-10">
                                    <label className="form-label">
                                        Supplier
                                    </label>
                                    <div className="flex flex-row flex-wrap gap-10">
                                        <select className="cat_select" name="sel-industry" id="sel-industry" value={matSupplier} style={{ width: "18em" }} onChange={(selected) => {
                                                setMatSupplier(selected.target.value); 
                                                setChangesMade(true);
                                            }}>
                                            <option value="0" disabled>None</option>
                                            {suppliersFormatted}
                                        </select>
                                    </div>
                                    <button style={{ color: "#0094FF", background: "none", border: "none", cursor: "pointer", marginRight: "auto", fontSize: "14px", paddingTop: addNewSupplierStatus ? "30px" : "10px" }} onClick={toggleAddNewSupplier}>ADD NEW SUPPLIER</button>
                                    {addNewSupplierStatus && <div className="flex flex-row gap-10">
                                        <input
                                            type="text"
                                            name="supName"
                                            className="half"
                                            id="supName"
                                            placeholder="Enter supplier name"
                                            defaultValue={supTitle}
                                            onChange={(e) => { setChangesMade(true); setSupTitle(e.target.value) }}
                                        />
                                        <WBButton title="Save" color="blue" func={() => toggleAddNewSupplier()} />
                                    </div> }
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="matName" className="form-label">
                                        Material Name
                                    </label>
                                    {/* <p>Max 20 Characters (Remaining 20)</p> */}
                                    <input
                                        type="text"
                                        name="matName"
                                        className="half"
                                        id="matName"
                                        placeholder="enter material name"
                                        defaultValue={matTitle}
                                        onChange={(e) => { setChangesMade(true); setMatTitle(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="matDesc" className="form-label">
                                        Material Description
                                    </label>
                                    {/* <p>Max 20 Characters (Remaining 20)</p> */}
                                    <textarea
                                        name="matDesc"
                                        className="half"
                                        id="matDesc"
                                        placeholder="enter material description"
                                        defaultValue={matDesc}
                                        rows={"5"}
                                        onChange={(e) => { setChangesMade(true); setMatDesc(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="matCost" className="form-label">
                                        Material Cost
                                    </label>
                                    {/* <p>Max 20 Characters (Remaining 20)</p> */}
                                    <input
                                        type="number"
                                        name="matCost"
                                        className="half"
                                        id="matCost"
                                        placeholder="enter material cost"
                                        defaultValue={matCost}
                                        onChange={(e) => { setChangesMade(true); setMatCost(e.target.value) }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer flex flex-row space-between" style={{ flexWrap: "wrap" }}>
                        <div className="flex flex-row gap-10" style={{ alignItems: "center"}}>
                        </div>
                        <div className="flex flex-row gap-10">
                            <WBButton title="Cancel" color="white" func={() => { if (!changesMade) closeModalMethod(); else { if (window.confirm("You are about to leave this page? Are you sure?")) closeModalMethod() } }} />
                            <WBButton title="Save" color={"green"} func={() => Save()} />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default EditMaterial;