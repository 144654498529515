import { toast } from "react-toastify";

export const validateStr = (title, value) => {
    if (!value || value === "") {
        toast.error(`${title} cannot be empty.`);
        return false;
    }
    return true;
}

export const validateInt = (title, value) => {
    if (!value || value === 0) {
        toast.error(`${title} cannot be empty.`);
        return false;
    }
    return true;
}