import { useEffect, useState, useRef } from "react";
import { PostDB } from "../DBTools";
import { toast } from "react-toastify";
import { WBDropdown2, WBDropdownOption, useWBTableData, WBModal } from "./WBComponents";

const TableItems = ({ itemTitle, itemsAt, identifiers, removeBranch, editFunc, setEditedID, setRefreshTable, allIndustries, setItemSelected }) => {
    const [itemsFormatted, formatItems] = useState(<></>);
    const items = useWBTableData();

    const selectedItem = useRef();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const toggleDeleteModal = (item) => {
        item !== 0 ? selectedItem.current = item : selectedItem.current = 0;
        setIsDeleteModalOpen(!isDeleteModalOpen);
    }

    const deleteItem = async () => {
        let removedItem = selectedItem.current.id;
        const response = await PostDB({
            branch: removeBranch, json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id: removedItem })
            }
        });
        if (response === "success" || response === "done") {
            toast.success(`${itemTitle} has been removed successfully.`);
            setRefreshTable(itemRefresh => !itemRefresh);
            toggleDeleteModal();
        }
        else {
            toast.error(`This ${itemTitle} could not be removed. Please try again later.`);
        }
    }
       
    useEffect(() => {
        if (items !== null && items !== undefined) {
            const importedItems = itemsAt ? items[itemsAt] : items
            const allItems = importedItems.map(item => ({
                ...item,
                industry: allIndustries ?  allIndustries.find(industry => industry.id === item.industry).name : item.industry,
                final_cost: "$" + item.final_cost?.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }))
            if (allItems.length > 0) {
                formatItems(
                    allItems.map((item) => (
                        <tr key={item.id} onDoubleClick={() => { 
                                if (setEditedID) setEditedID(item.id); 
                                if (setItemSelected) setItemSelected(item);
                                editFunc(); 
                            }}>
                           {identifiers.map((identifier, _) => (
                                 <td style={{ padding: "8px 36px" }}>{item[identifier]}</td>
                            ))}
                            <td style={{ padding: "8px 36px" }}>
                                {(item.active === 1 || item.status === 1) ? <span className="text-green bold">Active</span> : (item.active === 0 || item.status === 0) ? <span className="text-red bold">Inactive</span> : ""}
                                {item.hasServices === true ? <i className="icon-linked" /> : item.hasServices === false ? "-" : ""}
                            </td>
                            <td style={{ padding: "8px 36px", width: "100%"}}>
                                <div style={{ display: "flex", justifyContent: "flex-end",  width: "100%"}}>
                                    <WBDropdown2 leftitem={<div className="title">
                                            <div className="option">
                                                <span className="dot-icon"></span>
                                                <span className="dot-icon"></span>
                                                <span className="dot-icon"></span>
                                            </div>
                                        </div>} align="end">
                                        <WBDropdownOption title="Edit" func={() => { 
                                            if (setEditedID) setEditedID(item.id); 
                                            if (setItemSelected) setItemSelected(item);
                                            editFunc(); 
                                        }} />
                                        <WBDropdownOption title="Delete" func={() => toggleDeleteModal(item)} />
                                    </WBDropdown2>
                                </div>
                            </td>
                        </tr>
                    ))
                )
            }
            else {
                formatItems(<tr><td colSpan={10} style={{ textAlign: "center", paddingTop: "20px" }}>No items found</td></tr>);
            }
        }
        else {
            formatItems(<tr><td colSpan={10} style={{ textAlign: "center", paddingTop: "20px" }}>No items found</td></tr>);
        }
    }, [items])

    return (
        <tbody>
            {isDeleteModalOpen && <WBModal closeFunction={toggleDeleteModal} submitFunction={deleteItem} title={'Delete ' + selectedItem.current.title ?? selectedItem.current.name}><p style={{ padding: "20px"}}>Are you sure you want to delete "{selectedItem.current.title ?? selectedItem.current.name}"? <br/>This cannot be reversed once completed.</p></WBModal>}
            {itemsFormatted}
        </tbody>
    );
}

export default TableItems;