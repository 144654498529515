import { useState, useEffect } from "react";
import { PostDB } from "../../../../Components/DBTools";
import { WBButton, WBIOSToggle, WBDropdown, WBDropdownOption } from "../../../../Components/UIComponents/WBComponents";
import { toast } from "react-toastify";

const AddJobType = ({ closeModalMethod, returnFunc }) => {
    const [industry, setIndustry] = useState(0);
    const [industriesFormatted, formatIndustries] = useState([]);

    const [title, setTitle] = useState();
    const [active, setActive] = useState(1);
    const [changesMade, setChangesMade] = useState(false);

    const toggleStatus = () => setActive(status => { setChangesMade(true); return !status });

    const GetIndustries = async () => {
        const response = await PostDB({
            branch: "/settings/industries",
            json: {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }
            }
        });
        if (response !== undefined && response !== null) {
            formatIndustries(
                response.map((ind) => (
                    <option key={ind.id} value={ind.id}>{ind.department}</option>
                ))
            );
        }
    }

    useEffect(() => {
        GetIndustries();
    }, []);

    const Save = async () => {
        let bodyObj = {
            id: null,
            title: title,
            trade_id: industry,
            status: active === true ? 1 : 0,
            org_id: 1
        }
        const response = await PostDB({
            branch: "/editJobType",
            json: {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(bodyObj)
            }
        });
        if (response.api_status === "success") {
            toast.success("Job Type has been successfully updated");
            if (returnFunc) returnFunc();
            closeModalMethod();
        }
        else {
            toast.error("There was an error saving this Job Type. Check your fields and try again");
        }
    };

    const handleBGClick = (e) => {
        const background = document.querySelector(".bg-darkblue");
        if (background === e.target && !changesMade) {
            closeModalMethod();
        }
    }

    return (
        <div className="add-utility-page">
            <div className="bg-darkblue" onClick={(e) => handleBGClick(e)}>
                <div className="content">
                    <div className="border-bottom bg-white p-20 gap-10" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="button-row left">
                            <h4 className="title"><b>Add Job Type</b></h4>
                        </div>
                    </div>
                    <div className="border-bottom h-full flex flex-column gap-30" style={{ padding: "35px" }}>
                        <div className="input">
                            <label htmlFor="selTrade" className="form-label">
                                Trade
                            </label>
                            <select className="cat_select" name="selTrade" value={industry} style={{ width: "300px" }} onChange={(selected) => {
                                    setChangesMade(true); 
                                    setIndustry(selected.target.value);
                                }}>
                                <option value="0" disabled>None</option>
                                {industriesFormatted.reverse()}
                            </select>
                        </div>
                        <div className="input">
                            <label htmlFor="typeTitle" className="form-label">
                                Job Type
                            </label>
                            <input
                                type="text"
                                name="typeTitle"
                                style={{ width: "300px" }}
                                id="typeTitle"
                                placeholder="Enter job type"
                                defaultValue={title}
                                onChange={(e) => { setChangesMade(true); setTitle(e.target.value) }}
                            />
                        </div>
                    </div>
                    <div className="footer flex flex-row space-between" style={{ flexWrap: "wrap" }}>
                        <div className="flex flex-row gap-10" style={{ alignItems: "center"}}>
                            <p>Status</p>
                            <WBIOSToggle active={active} func={() => toggleStatus()} />
                        </div>
                        <div className="flex flex-row gap-10">
                            <WBButton title="Cancel" color="white" func={() => { if (!changesMade) closeModalMethod(); else { if (window.confirm("You are about to leave this page? Are you sure?")) closeModalMethod() } }} />
                            <WBButton title="Save" color={"green"} func={() => Save()} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddJobType;