import { useContext, useState, useCallback } from "react";
import styles from "./FieldUserModal.module.css";
import { WBButton } from "../../../UIComponents/WBComponents";
import { WBDropdown, WBDropdownOption } from "../../../Customer/JobCard/common/WBDropdown";
import { ModalContext } from "../context/ModalContext";
import { useFieldUserForm } from "./useFieldUserForm";
import { OfficeUsersButton } from "../common/OfficeUsersButton";
import { initialPermissions } from "../FieldUserModal/permissionsData";
import { BlobToBase64 } from "../../../../lib/js/convertimagetobase64";
import compDefault from "./assets/compDefault.svg";

const FieldUserModal = () => {
	const {
		iconCardImage,
		iconDropDown,
		iconPlus,
		addUserModalOverlay,
		addUserModal,
		addUserModalContainer,
		addUserModalHeader,
		headerTitle,
		closeButton,
		headerContent,
		headerContentImage,
		headerContentUpload,
		headerContentLabel,
		headerContentInfo,
		addUserModalBody,
		row,
		rowSingleInput,
		rowDoubleInput,
		label,
		input,
		addUserModalPermissions,
		permissionsHeader,
		permissionsBody,
		permissionItem,
		permissionText,
		permissionTextTitle,
		permissionItemWithBorder,
		createJobs,
		accountingHeader,

		addUserModalFooter,
	} = styles;

	const { closeModal, modalTitle, userData } = useContext(ModalContext);

	const [permissions, setPermissions] = useState(initialPermissions);

	const togglePermission = (index) => {
		setPermissions(
			permissions.map((permission, i) =>
				i === index && !permission.type
					? { ...permission, isEnabled: !permission.isEnabled }
					: permission,
			),
		);
	};

	const {
		formData,
		dropdownOptions,
		loading,
		error,
		handleInputChange,
		handleDropdownChange,
		handleSaveUser,
	} = useFieldUserForm(userData, closeModal);

	const getSelectedItem = useCallback((items, selectedId) => {
		return items.find((item) => item.id === selectedId) || null;
	}, []);

	const handleImageUpload = async (e) => {
		const images = e.target.files;
		if (images.length > 0) {
			handleInputChange({
				target: {
					id: e.target.id,
					value: {
						file: await BlobToBase64(URL.createObjectURL(images[0])),
						file_name: images[0].name
					}
				}
			});
		}
	}

	// Dummy options for truck registration
	const truckRegoOptions = [
		{ id: 1, name: "GHT-7U1" },
		{ id: 2, name: "XYZ-123" },
		{ id: 3, name: "ABC-789" },
	];

	return (
		<div className={addUserModalOverlay}>
			<div className={addUserModal}>
				<div className={headerTitle}>
					<span>{modalTitle}</span>
					<button className={closeButton} onClick={closeModal}>
						<i className={iconPlus}></i>
					</button>
				</div>
				<div className={addUserModalContainer}>
					<div className={addUserModalHeader}>
						<div className={headerContent}>
							<div className={headerContentImage}>
								{formData.logo_image ? <img
									src={formData.logo_image.file ? formData.logo_image.file : formData.logo_image}
									alt={formData.name + " Logo"}
									width={"100%"}
									height={"100%"}
									title={formData.name + " Logo"}
									style={{objectFit: 'cover'}}
								/>: 
									<img style={{width: "50%"}} src={compDefault} alt="User profile placeholder" />
								}
							</div>
							<div className={headerContentUpload}>
								<span className={headerContentLabel}>Image Avatar</span>
								<div className="input-upload">
									<label className="btn btn-default h-full" style={{ color: "grey", height: "48px", width: "100%", color: "black", padding: "14.5px 20px", fontSize: "16px", justifyContent: "flex-start" }} title="Upload Logo Here" htmlFor="logo_image" >
										<i className={iconCardImage}/>
										Upload Image
									</label>
									<input type="file" onChange={handleImageUpload} hidden accept="image/jpg,image/jpeg,image/png" id="logo_image" />
								</div>
								{modalTitle === "Edit Field User" && (
									<span className={headerContentInfo}>
										Uploading new image will overwrite previous profile image
									</span>
								)}
							</div>
						</div>
					</div>
					<div className={addUserModalBody}>
						{/* Organization dropdown */}
						<div className={`${row} ${rowSingleInput}`}>
							<label className={label}>Organization</label>
							<WBDropdown
								leftitem={
									getSelectedItem(dropdownOptions.orgs, formData.org_id)?.name ||
									"Select Organization"
								}
								rightitem={<i className={iconDropDown} />}
								color="green"
								height="48px"
								width="100%"
								borderRadius="10px"
								leftItemPadding="0 24px"
								rightItemPadding="0 20px 0 0"
								style={{
									boxShadow: "0px 4px 4px 0px #0000000A",
									border: "none",
								}}
								background={"linear-gradient(360deg, #F6F6F6 0%, #F3F3F3 100%)"}
							>
								{loading ? (
									<WBDropdownOption key="loading" title="Loading..." />
								) : error ? (
									<WBDropdownOption key="error" title={error} />
								) : (
									dropdownOptions.orgs.map((org) => (
										<WBDropdownOption
											key={org.id}
											id={org.id}
											title={org.name}
											func={() => {
												handleDropdownChange("org_id", org.id);
											}}
											pos="center"
										/>
									))
								)}
							</WBDropdown>
						</div>
						{/* First name and surname */}
						<div className={`${row} ${rowDoubleInput}`}>
							<div>
								<label htmlFor="first_name" className={label}>
									First Name
								</label>
								<input
									type="text"
									id="first_name"
									className={input}
									placeholder="Enter first name"
									value={formData.first_name}
									onChange={handleInputChange}
								/>
							</div>
							<div>
								<label htmlFor="surname" className={label}>
									Surname
								</label>
								<input
									type="text"
									id="surname"
									className={input}
									placeholder="Enter surname"
									value={formData.surname}
									onChange={handleInputChange}
								/>
							</div>
						</div>
						{/* Username and Mobile number */}
						<div className={`${row} ${rowDoubleInput}`}>
							<div>
								<label htmlFor="username" className={label}>
									Username
								</label>
								<input
									type="text"
									id="username"
									className={input}
									placeholder="Enter username"
									value={formData.username}
									onChange={handleInputChange}
								/>
							</div>
							<div>
								<label htmlFor="phone_mobile" className={label}>
									Mobile Number
								</label>
								<input
									type="tel"
									id="phone_mobile"
									className={input}
									placeholder="Enter mobile number"
									value={formData.phone_mobile}
									onChange={handleInputChange}
								/>
							</div>
						</div>
						{/* Work email and personal email */}
						<div className={`${row} ${rowDoubleInput}`}>
							<div>
								<label htmlFor="email_work" className={label}>
									Work Email
								</label>
								<input
									type="email"
									id="email_work"
									className={input}
									placeholder="Enter work email"
									value={formData.email_work}
									onChange={handleInputChange}
								/>
							</div>
							<div>
								<label htmlFor="email_personal" className={label}>
									Personal Email
								</label>
								<input
									type="email"
									id="email_personal"
									className={input}
									placeholder="Enter personal email"
									value={formData.email_personal}
									onChange={handleInputChange}
								/>
							</div>
						</div>
						{/* Home address */}
						<div className={`${row} ${rowSingleInput}`}>
							<label htmlFor="address_home" className={label}>
								Home Address
							</label>
							<input
								type="text"
								id="address_home"
								className={input}
								placeholder="Enter home address"
								value={formData.address_home}
								onChange={handleInputChange}
							/>
						</div>
						<div className={`${row} ${rowDoubleInput}`}>
							<div>
								<label htmlFor="password" className={label}>
									Password
								</label>
								<input
									type="password"
									id="password"
									className={input}
									placeholder="Enter password"
									value={formData.password}
									onChange={handleInputChange}
									autoComplete="new-password"
								/>
							</div>
							<div>
								<label htmlFor="confirm_password" className={label}>
									Confirm Password
								</label>
								<input
									type="password"
									id="confirm_password"
									className={input}
									placeholder="Enter password again"
									value={formData.confirm_password}
									onChange={handleInputChange}
									autoComplete="new-password"
								/>
							</div>
						</div>
						{/* Trade type and technician role dropdowns */}
						<div className={`${row} ${rowDoubleInput}`}>
							<div>
								<label className={label}>Trade Type</label>
								<WBDropdown
									leftitem={
										getSelectedItem(
											dropdownOptions.tradeTypes,
											formData.trade_type_id,
										)?.name || "Select Trade Type"
									}
									rightitem={<i className={iconDropDown} />}
									color="green"
									height="48px"
									width="100%"
									borderRadius="10px"
									leftItemPadding="0 24px"
									rightItemPadding="0 20px 0 0"
									style={{
										boxShadow: "0px 4px 4px 0px #0000000A",
										border: "none",
									}}
									background={"linear-gradient(360deg, #F6F6F6 0%, #F3F3F3 100%)"}
								>
									{loading ? (
										<WBDropdownOption key="loading" title="Loading..." />
									) : error ? (
										<WBDropdownOption key="error" title={error} />
									) : (
										dropdownOptions.tradeTypes.map((type) => (
											<WBDropdownOption
												key={type.id}
												id={type.id}
												title={type.name}
												func={() => {
													handleDropdownChange("trade_type_id", type.id);
												}}
												pos="center"
											/>
										))
									)}
								</WBDropdown>
							</div>
							<div>
								<label className={label}>Technician Role</label>
								<WBDropdown
									leftitem={
										getSelectedItem(
											dropdownOptions.techRoles,
											formData.tech_role_id,
										)?.name || "Select Technician Role"
									}
									rightitem={<i className={iconDropDown} />}
									color="green"
									height="48px"
									width="100%"
									borderRadius="10px"
									leftItemPadding="0 24px"
									rightItemPadding="0 20px 0 0"
									style={{
										boxShadow: "0px 4px 4px 0px #0000000A",
										border: "none",
									}}
									background={"linear-gradient(360deg, #F6F6F6 0%, #F3F3F3 100%)"}
								>
									{loading ? (
										<WBDropdownOption key="loading" title="Loading..." />
									) : error ? (
										<WBDropdownOption key="error" title={error} />
									) : (
										dropdownOptions.techRoles.map((role) => (
											<WBDropdownOption
												key={role.id}
												id={role.id}
												title={role.name}
												func={() => {
													handleDropdownChange("tech_role_id", role.id);
												}}
												pos="center"
											/>
										))
									)}
								</WBDropdown>
							</div>
						</div>
						{/* Truck registration dropdown */}
						<div className={`${row} ${rowSingleInput}`}>
							<label className={label}>Truck Registration</label>
							<WBDropdown
								leftitem={formData.truck_rego || "Select Truck Registration"}
								rightitem={<i className={iconDropDown} />}
								color="green"
								height="48px"
								width="100%"
								borderRadius="10px"
								leftItemPadding="0 24px"
								rightItemPadding="0 20px 0 0"
								style={{
									boxShadow: "0px 4px 4px 0px #0000000A",
									border: "none",
								}}
								background={"linear-gradient(360deg, #F6F6F6 0%, #F3F3F3 100%)"}
							>
								{truckRegoOptions.map((option) => (
									<WBDropdownOption
										key={option.id}
										id={option.id}
										title={option.name}
										func={() => {
											handleDropdownChange("truck_rego", option.name);
										}}
										pos="center"
									/>
								))}
							</WBDropdown>
						</div>
					</div>
					<div className={addUserModalPermissions}>
						<div className={permissionsHeader}>
							<span>Permissions</span>
						</div>
						<div className={permissionsBody}>
							{permissions.map((permission, index) => (
								<div key={index}>
									{permission.type === "header" ? (
										<div className={accountingHeader}>
											<strong>{permission.text}</strong>
										</div>
									) : (
										<div
											className={`${permissionItem} ${
												permission.hasTitle ? permissionItemWithBorder : ""
											} ${index === 0 ? createJobs : ""}`}
										>
											{permission.hasTitle ? (
												<div className={permissionText}>
													<span className={permissionTextTitle}>
														{permission.title}
													</span>
													<span>{permission.description}</span>
												</div>
											) : (
												<span>{permission.text}</span>
											)}
											<OfficeUsersButton
												isEnabled={permission.isEnabled}
												onClick={() => togglePermission(index)}
											/>
										</div>
									)}
								</div>
							))}
						</div>
					</div>

					<div className={addUserModalFooter}>
						<WBButton
							title="Cancel"
							color="grey"
							height="40px"
							styles={{
								padding: "12px 24px",
								fontSize: "16px",
								justifyContent: "flex-start",
							}}
							func={closeModal}
						/>
						<WBButton
							title={`${
								modalTitle === "Add Field User" ? "Create User" : "Save Changes"
							}`}
							color="green"
							height="40px"
							bold={true}
							styles={{
								padding: "12px 24px",
								fontSize: "16px",
								justifyContent: "flex-start",
							}}
							func={handleSaveUser}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FieldUserModal;
