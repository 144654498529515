import { NavLink, useLocation, useNavigate } from "react-router-dom";

const LeftNavOption = ({ name, link, id="", disabled = false }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleNavigation = (event) => {
      if (window.unsavedChanges) {
          const confirmLeave = window.confirm("You have unsaved changes. Are you sure you want to leave?");
          if (!confirmLeave) {
              event.preventDefault();
              return;
          }
      }
      navigate(link + id);
  };

  return (
    <li className={disabled ? "disabled" : ""}>
      <NavLink 
        to={link + id} 
        className={pathname === link ? "active" : ""}
        onClick={handleNavigation}
      >
        {name}
      </NavLink>
    </li>
  );
};

export default LeftNavOption;
