import { useEffect, useState } from "react";
import { WBButton, WBCollector, WBIOSToggle } from "../../../../Components/UIComponents/WBComponents";
import { PostDB, CallDB } from "../../../../Components/DBTools";
import { toast } from "react-toastify";
import { validateStr, validateInt } from "../../../../lib/js/validateInput";

const EditCategories = ({ closeModalMethod, editedID, returnFunc }) => {
    const [cateID, setCateID] = useState();
    const [cateName, setCateName] = useState();
    const [industry, setIndustry] = useState();
    const [industriesFormatted, formatIndustries] = useState([]);
    const [allSubCategories, setAllSubCategories] = useState();
    const [selectedSubCategories, setSelectedSubCategories] = useState();
    const [editedSubCategories, setEditedSubCategories] = useState();
    const [delSubCategories, setDelSubCategories] = useState([]);
    const [active, setActive] = useState(true);
    const [changesMade, setChangesMade] = useState(false);

    const toggleStatus = () => setActive(status => { setChangesMade(true); return !status });

    const GetIndustries = async () => {
        const response = await PostDB({
            branch: "/settings/industries",
            json: {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                }
            }
        });
        if (response !== undefined && response !== null) {
            formatIndustries(
                response.map((ind) => (
                    <option key={ind.id} value={ind.id}>{ind.department}</option>
                ))
            );
        }
    }

    useEffect(() => {
        GetIndustries();
    }, []);

    useEffect(() => {
        const GetCategory = async () => {
            const response = await CallDB({branch: `/pricebook/categorybyid/${editedID}`});
            if (response !== undefined && response !== null) {
                setCateID(response[0].id);
                setCateName(response[0].name);
                setIndustry(response[0].trade_id);
                setActive(response[0].status === 1 ? true : false);

                const response2 = await CallDB({branch: `/pricebook/subcat/${response[0].id}`});
                if (response2 !== undefined && response2 !== null) {
                    setAllSubCategories(response2);
                }

            }
        }

        GetCategory();
    }, []);
    
    useEffect(() => {
        if (allSubCategories !== undefined && allSubCategories !== null) {
            setSelectedSubCategories(allSubCategories.map((subCategory) => ({
                id: subCategory.id,
                name: subCategory.title
            })));
        }
    }, [allSubCategories]);

    const Save = async () => {
        if (!validateInt("Trade", industry)) return;
        if (!validateStr("Name", cateName)) return;

        console.log("cateName", cateName, cateName === "");

        const subCategoriesToSend = [...editedSubCategories, ...delSubCategories]
        let bodyObj = {
            cat_id: cateID,
            cat_name: cateName,
            trade_id: industry,
            sub_categories: subCategoriesToSend.map((subCategory) => ({
                sub_cat_id: subCategory.id,
                sub_cat_name: subCategory.name,
                status: 1,
                action: subCategory.action
            })),
            active: active === true ? 1 : 0
        }
        let response = await PostDB({
            branch: "/editCategory", json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(bodyObj)
            }
        });
        if (response.api_status === "success") {
            toast.success("Category has been successfully added");
            if (returnFunc) returnFunc();
            closeModalMethod();
        }
        else {
            toast.error("There was an error saving this Utility. Check your fields and try again");
        }
    }

    const handleBGClick = (e) => {
        const background = document.querySelector(".bg-darkblue");
        if (background === e.target && !changesMade) {
            closeModalMethod();
        }
    }

    return (
        <div className="add-utility-page">
            <div className="bg-darkblue" onClick={(e) => handleBGClick(e)}>
                <div className="content">
                    <div className="header" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="button-row left">
                            <h4 className="title"><b>Edit Category</b></h4>
                        </div>
                    </div>
                    <div className="body">
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="filtName" className="form-label">
                                        Trade
                                    </label>
                                    <select className="cat_select" name="selTrade" value={industry} style={{ width: "300px" }} onChange={(selected) => {
                                            setChangesMade(true); 
                                            setIndustry(selected.target.value);
                                        }}>
                                        <option value="0" disabled>None</option>
                                        {industriesFormatted.reverse()}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="hr" />
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="cateName" className="form-label">
                                        Category Name
                                    </label>
                                    <input
                                        type="text"
                                        name="cateName"
                                        id="cateName"
                                        placeholder="enter category name"
                                        className="half"
                                        value={cateName}
                                        onChange={(e) => { setChangesMade(true); setCateName(e.target.value) }}
                                        autoFocus
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="hr" />
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="subCategories" className="form-label">
                                        Sub Categories
                                    </label>
                                    <WBCollector allCollectorItems={selectedSubCategories} setAllCollectorItems={setSelectedSubCategories} setDeletedItems={setDelSubCategories} selectedTitle="Sub Category" returnFunc={(e) => { setEditedSubCategories(e) }} changeMade={(e) => { setChangesMade(true)}}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer flex flex-row space-between" style={{ flexWrap: "wrap" }}>
                        <div className="flex flex-row gap-10" style={{ alignItems: "center"}}>
                            <p>Status</p>
                            <WBIOSToggle active={active} func={() => toggleStatus()} />
                        </div>
                        <div className="flex flex-row gap-10">
                            <WBButton title="Cancel" color="white" func={() => { if (!changesMade) closeModalMethod(); else { if (window.confirm("You are about to leave this page? Are you sure?")) closeModalMethod() } }} />
                            <WBButton title="Save" color={"green"} func={() => Save()} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditCategories;