import { useState, useEffect } from "react";
import TableItems from "../../../Components/UIComponents/TableItems";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import EditSpiff from "./Edit/EditSpiff";
import AddSpiff from "./Add/AddSpiff";

const SpiffsPage = () => {
  const [tableData, setTableData] = useState();
  const [refreshTable, setRefreshTable] = useState(false);

  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);

  const toggleAddModal = () => { setIsModalAddOpen(!isModalAddOpen); }
  const toggleEditModal = (id) => { setIsModalEditOpen(!isModalEditOpen); };

  const [editedID, setEditedID] = useState();

  const headers = ["Title", "Code", "Payout", "Status", "Action"];

  useEffect(() => {
    setTableData(
      <WBTable tableDataOrg="/spiffsJson" tableHeaders={headers}>
        <TableItems 
          itemTitle="Spiff" itemsAt="spiffs" identifiers={["title", "code", "cost"]} removeBranch="/removeSpiff"
          editFunc={toggleEditModal} setEditedID={setEditedID} setRefreshTable={setRefreshTable}
        />
      </WBTable>
    );
  }, [refreshTable]);

  return (
    <div className="content-area-full">
      {isModalAddOpen && <AddSpiff closeModalMethod={toggleAddModal} returnFunc={() => {
        setRefreshTable(!refreshTable)
      }}/>}
      {isModalEditOpen && <EditSpiff closeModalMethod={toggleEditModal} editedID={editedID} returnFunc={() => {
        setRefreshTable(!refreshTable)
      }}/>}
      <div className="flex floating-box flex-column bg-white gap-20" style={{ padding: "42px 36px 35px 36px"}}>
        <div className="flex justify-between">
          <div className="text-base">
            <b>Spiffs</b>
          </div>
          <WBButton title="Add New Spiff" color="white" func={toggleAddModal}/>
        </div>
        {tableData}
      </div>
    </div>
  );
};

export default SpiffsPage;
