import { createContext, useContext, useState } from 'react';

const JobDetailsContext = createContext();

export const JobDetailsProvider = ({ children }) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [relationship, setRelationship] = useState('Choose relationship');
  const [jobPriority, setJobPriority] = useState('Choose job priority');
  const [serviceType, setServiceType] = useState('Choose service type');
  const [calloutFee, setCalloutFee] = useState('Choose callout fee');
  const [specialBooking, setSpecialBooking] = useState('Choose special booking');
  const [selectedIndustry, setSelectedIndustry] = useState('Plumbing');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedJobTypes, setSelectedJobTypes] = useState([]);
  const [selectedContact, setSelectedContact] = useState('contact1');
  const [sameAsCustomer, setSameAsCustomer] = useState(false);

  const openEditModal = () => setIsEditModalOpen(true);
  const closeEditModal = () => setIsEditModalOpen(false);

  return (
    <JobDetailsContext.Provider
      value={{
        isEditModalOpen,
        openEditModal,
        closeEditModal,
        relationship,
        setRelationship,
        jobPriority,
        setJobPriority,
        serviceType,
        setServiceType,
        calloutFee,
        setCalloutFee,
        specialBooking,
        setSpecialBooking,
        selectedIndustry,
        setSelectedIndustry,
        selectedCategory,
        setSelectedCategory,
        selectedJobTypes,
        setSelectedJobTypes,
        selectedContact,
        setSelectedContact,
        sameAsCustomer,
        setSameAsCustomer,
      }}
    >
      {children}
    </JobDetailsContext.Provider>
  );
};

export const useJobDetails = () => {
  const context = useContext(JobDetailsContext);
  if (!context) {
    throw new Error('useJobDetails must be used within a JobDetailsProvider');
  }
  return context;
};
