/* eslint-disable react-hooks/exhaustive-deps */
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CallDB } from "../DBTools";
// import orgData from "../../Data Source/Organisations.json";
import { useState, useCallback, useEffect, useRef } from "react";
import compDefault from "./UserManagement/FieldUserModal/assets/compDefault.svg";
import { toast } from "react-toastify";

const LeftNavOrganisations = (parentRef=null) => {
    // console.log("Full rerender");
    // let myJSON = localStorage.orgLocal;
    // let jsonConv = JSON.parse(myJSON) ? JSON.parse(myJSON) : "";

    const [organisations, setOrganisations] = useState("");
    const [orgFormatted, formatOrgs] = useState(""); // locally store organisations to stop scrollbar moving between pages
    const [isLoading, setIsLoading] = useState(true);
    const { pathname } = useLocation();
    const storedOrgs = useRef();
    const navigate = useNavigate();

    const handleNavigation = (event, orgID) => {
        if (window.unsavedChanges) {
            const confirmLeave = window.confirm("You have unsaved changes. Are you sure you want to leave?");
            if (!confirmLeave) {
                event.preventDefault();
                return;
            }
        } 
        window.unsavedChanges = false;
        navigate("/settings/organisation/edit/" + orgID);
    };

    const StoreLocalOrg = (value) => {
        let parsedJSON = JSON.stringify(value);
        localStorage.setItem('orgLocal', parsedJSON);
    }

    const fetchDB = useCallback(async () => {
        setIsLoading(true);
        if (orgFormatted !== undefined && orgFormatted !== "") {
            // console.log("Load from memory");
            // console.log("Org Values: ", orgFormatted);
            setOrganisations(storedOrgs);
        }
        else {
            // console.log("Pull form DB");
            // console.log("Org Values: ", orgFormatted);
            let response = await CallDB({ branch: "/getOrgs" });
            let fallback = "";
            if (response !== null) {
                if (response.length > 0) {
                    setOrganisations(response);
                }
                else {
                    fallback =
                        <li>
                            <a href="true" style={{ color: "white", backgroundColor: "red" }}>No organisations present.</a>
                        </li>;
                    formatOrgs(fallback);
                }
            } else {
                fallback =
                    <li>
                        <a href="true" style={{ color: "white", backgroundColor: "red" }}>Failed to retrieve organisations.</a>
                    </li>;
                formatOrgs(fallback);
            }
        }
        setIsLoading(false);
    }, [formatOrgs]);

    useEffect(() => {
        // formatOrgs(jsonConv);
        fetchDB();
    }, [fetchDB]);

    const linkRefs = useRef({})
    useEffect(() => {
        if (organisations.length > 0) {
            const currentOrg = organisations.find(org => pathname === `/settings/organisation/edit/${org.id}`);
            if (parentRef && currentOrg) {
                setTimeout(() => {
                    const currentElement = linkRefs.current[currentOrg.id];
                    const parent = parentRef.parentRef.current;
                    if (parent && currentElement) {
                        parent.scrollTo({
                            top: currentElement.offsetTop - parent.offsetTop,
                            behavior: "smooth"
                        });
                    }
                }, 100)
            }
        }
    }, [organisations, pathname]);

    useEffect(() => {
        let myOrgs = "";
        if (!isLoading) {
            if (organisations.length > 0) {
                myOrgs = organisations.map((org) => {
                    // console.log("OrgID: /settings/organisation/edit/" + org.id + " Pathname: " + pathname);
                    return <li key={org.id}>
                        <Link 
                            to={"/settings/organisation/edit/" + org.id} 
                            className={pathname === ("/settings/organisation/edit/" + org.id) ? "active" : ""}
                            onClick={(event) => handleNavigation(event, org.id)}
                            ref={(el) => linkRefs.current[org.id] = el}
                        >
                            <div  style={{ display: "flex", flexDirection: "row", gap: 10, alignItems: "center"  }}>
                                <div className="profile_image_background profile_image_border">
                                    {org.logo_image ? 
                                        <img className="profile_image" src={org.logo_image.file ? org.logo_image.file : org.logo_image} alt="Organisation Profile" /> :
                                        <img style={{width: "60%"}} src={compDefault} alt="Organisation profile placeholder" />
                                    }
                                </div>
                                <div id="orgNameNav">{org.short_name}</div>
                            </div>
                        </Link>
                    </li>
                });
                StoreLocalOrg(myOrgs);
            }
            else {
                <a href="true">No organisations found. Create on by selecting +Add</a>
            }
        }
        else {
            myOrgs =
                <li>
                    <Link style={{ color: "GrayText" }}>Loading organisations...</Link>
                </li>;
        }
        formatOrgs(myOrgs);
    }, [organisations, pathname]);

    return <ul
        className="dropdown-menu-list"
        aria-labelledby="dropdownMenuButton1"
    >{orgFormatted}</ul>;
};

export default LeftNavOrganisations;
