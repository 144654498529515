import { useState, useEffect } from "react";
import TableItems from "../../../Components/UIComponents/TableItems";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import AddCustomerType from "./Add/AddCustomerType";
import EditCustomerType from "./Edit/EditCustomerType";

const CustomerTypePage = () => {
  const [tableData, setTableData] = useState();
  const [refreshTable, setRefreshTable] = useState(false);

  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);

  const toggleAddModal = () => { setIsModalAddOpen(!isModalAddOpen); } 
  const toggleEditModal = () => { setIsModalEditOpen(!isModalEditOpen); }

  const [itemSelected, setItemSelected] = useState([]);

  const headers = ["Type", "Discount Percentage", "Status", "Action"];

  useEffect(() => {
    setTableData(
      <WBTable tableData="/settings/customer_types" tableHeaders={headers} isLocalSearch={true} filterBy="title">
          <TableItems 
            itemTitle="Customer Type" identifiers={["title", "discount_percentage"]} removeBranch="/removeCustomerType"
            editFunc={toggleEditModal} setRefreshTable={setRefreshTable}
            setItemSelected={setItemSelected}
          />
      </WBTable>
    );
  }, [refreshTable]);

  return (
    <div className="content-area-full">
      {isModalAddOpen && <AddCustomerType closeModalMethod={toggleAddModal} returnFunc={() => {
        setRefreshTable(!refreshTable)
      }}/>}
      {isModalEditOpen && <EditCustomerType closeModalMethod={toggleEditModal} itemSelected={itemSelected} returnFunc={() => {
        setRefreshTable(!refreshTable)
      }}/>}
      <div className="flex floating-box flex-column bg-white gap-20" style={{ padding: "42px 36px 35px 36px"}}>
        <div className="flex justify-between">
          <div className="text-base">
            <b>Customer Types</b>
          </div>
          <WBButton title="Add New Customer Type" func={toggleAddModal} color={"white"} />
        </div>
        {tableData}
      </div>
    </div>
  );
};

export default CustomerTypePage;
