import React, { useState, useCallback } from 'react';
import styles from './css/schedulercalendar.module.css';
import ScheduledBox from './components/ScheduledBox';
import SchedulerModal from './SchedulerModal';
import useDragAndDrop from './hooks/useDragAndDrop';
import useTimeIndicator from './hooks/useTimeIndicator';
import { useSchedule } from './context/ScheduleContext';

const TIME_SLOTS = [
  '07:00 AM',
  '08:00 AM',
  '09:00 AM',
  '10:00 AM',
  '11:00 AM',
  '12:00 PM',
  '01:00 PM',
  '02:00 PM',
  '03:00 PM',
  '04:00 PM',
];

const SchedulerCalendar = ({
  isGridLocked,
  onScheduleMove,
  workers = []
}) => {
  const { openTechProfileModal, techProfileModal, smsModal, schedules, setSchedules } = useSchedule();


  const [isModalOpen, setModalOpen] = useState(false);
  const [activeSchedule, setActiveSchedule] = useState(null);
  const currentTimePosition = useTimeIndicator(styles);

  console.log("currentTimePosition", currentTimePosition);

  const openScheduleModal = schedule => {
    setActiveSchedule(schedule);
    setModalOpen(true);
  };



  // useEffect(() => {
  //   console.log('isGridLocked', isGridLocked);
  // }, [isGridLocked]);

  const { handleDragStart, handleDragOver, handleDragLeave, handleDrop } = useDragAndDrop(setSchedules, onScheduleMove, styles);

  // Memoize getScheduleAtPosition calculations
  const getScheduleAtPosition = useCallback(
    (rowIndex, index) => {
      const rowSchedules = schedules.filter(schedule => schedule.rowIndex === rowIndex);

      const schedulesAtCurrentPosition = rowSchedules.filter(
        schedule => schedule.startIndex <= index && index < schedule.startIndex + schedule.span
      );

      const allOverlappingSchedules = new Set();

      schedulesAtCurrentPosition.forEach(currentSchedule => {
        const currentEnd = currentSchedule.startIndex + currentSchedule.span;

        rowSchedules.forEach(schedule => {
          const scheduleStart = schedule.startIndex;
          const scheduleEnd = schedule.startIndex + schedule.span;

          if (scheduleStart < currentEnd && scheduleEnd > currentSchedule.startIndex) {
            allOverlappingSchedules.add(schedule);
          }
        });
      });

      return Array.from(allOverlappingSchedules).sort((a, b) => a.startIndex - b.startIndex);
    },
    [schedules]
  );

  const getScheduledJobs = useCallback(
    (tech_id, time) => {
      return schedules.filter(job => job.tech_id === tech_id && job.start_time === time);
    },
    [schedules]
  );

  // Calculate row height helper
  const calculateRowHeight = useCallback(
    (tech_id, time) => {
      const schedulesInRow = schedules.filter((schedule) => {

        // console.log("---- schedule", schedule);
        return schedule.tech_id === tech_id
      });

      console.log("---- schedulesInRow", schedulesInRow);

      const parseTime = (timeStr) => {
        let [time, modifier] = timeStr.split(" ");
        let [hours, minutes] = time.split(":").map(Number);

        if (modifier.toLowerCase() === "pm" && hours !== 12) {
          hours += 12;
        } else if (modifier.toLowerCase() === "am" && hours === 12) {
          hours = 0;
        }

        return hours * 60 + minutes; // Convert to minutes for easy comparison
      }

      const maxStackIndex = Math.max(
        ...schedulesInRow.map(() => {
          // Step 1: Group schedules by start time
          const groupedByStart = schedulesInRow.reduce((acc, { start_time, end_time }) => {
            const parsedStart = parseTime(start_time);
            const parsedEnd = parseTime(end_time);

            // Create or push the schedule into the corresponding start_time group
            if (!acc[parsedStart]) {
              acc[parsedStart] = [];
            }
            acc[parsedStart].push({ start: parsedStart, end: parsedEnd });

            return acc;
          }, {});

          // console.log("--- groupedByStart", groupedByStart);

          // Step 2: Calculate the max overlap for each start time group
          let maxOverlap = 0;
          for (let start in groupedByStart) {
            const schedules = groupedByStart[start];

            // Sort schedules by their start time (in case it's not sorted)
            schedules.sort((a, b) => a.start - b.start);



            let overlapCount = 1; // Initially, we have at least one schedule
            for (let i = 0; i < schedules.length - 1; i++) {
              // If current schedule ends after the next one starts, it's an overlap
              if (schedules[i].end > schedules[i + 1].start) {
                overlapCount++;
              }
            }

            // Update the max overlap
            maxOverlap = Math.max(maxOverlap, overlapCount);
          }

          return maxOverlap;
        }),
        0 // Get the overall maximum overlap
      );

      return Math.max(65, (maxStackIndex - 1) * 24 + 55 + 10);
    },
    [getScheduleAtPosition, schedules]
  );


  return (
    <div className={styles.calendarContainer} >
      <div className={styles.timeRow}>
        <div className={styles.timeSlotsFirstBox}></div>
        {TIME_SLOTS.map((timeSlot, index) => (
          <div key={index} className={`${styles.calendarBoxHeader} ${styles.calendarBoxCenter}`}>
            <p>{timeSlot}</p>
          </div>
        ))}
      </div>
      {currentTimePosition > 136 && (
        <div
          className={styles.currentTimeIndicator}
          style={{
            left: `${currentTimePosition}px`,
            position: 'absolute',
            top: 0,
            height: '100%',
          }}
        >
          <div className={styles.currentTimeIndicatorDot} />
        </div>
      )}


      {/* workers are grouped by trades (Plumber, Electrician, HVAC) */}
      {Object.entries(workers).map(([trade, technicians]) => {

        return (
          <React.Fragment key={`${Date.now()}-${Math.floor(Math.random() * 1000000)}`}>
            <div className={styles.tradeHeader}>{trade[0].toUpperCase() + trade.slice(1)}</div>
            {
              technicians.map((technician) => {
                const rowHeight = calculateRowHeight(technician.id);

                return (
                  <div
                    className={styles.calendarRowWrapper}
                    style={{ minHeight: `${rowHeight}px` }}
                  >
                    <div
                      className={styles.calendarRow}
                      style={{ minHeight: `${rowHeight}px` }}
                    >
                      <div
                        className={styles.calendarFirstBox}
                        style={{ minHeight: `${rowHeight}px` }}
                        onDoubleClick={(event) => openTechProfileModal({
                          name: `${technician.firstName} ${technician.lastName}`,
                          role: technician.trade,
                        }, technician.id, event)}
                      >
                        <div className={`${styles.calendarFirstBoxContent} ${(techProfileModal.selectedTechId === technician.id &&
                          (techProfileModal.isOpen || smsModal.isOpen))
                          ? styles.calendarFirstBoxContentActive
                          : ''
                          }`}>
                          <img className={styles.calendarFirstBoxIcon} src="/images/scheduler/iconModal.svg" alt="user_icon" />

                          <div className={styles.calendarNames}>
                            <p className="bold">{technician.firstName}</p>
                            <p>{technician.lastName || ''}</p>
                            <p className={styles.calendarStatus}>{technician.status}</p>
                          </div>
                        </div>
                        {technician.apprentices?.length > 0 && (
                          <div className={styles.apprenticesContainer}>
                            {technician.apprentices.map((apprentice, index) => (
                              <div key={apprentice.id} className={styles.apprenticeCircle}>
                                <img src={apprentice.imageUrl} alt={`apprentice_${index + 1}`} />
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      {TIME_SLOTS.map((time, index) => (
                        (
                          <div
                            key={index}
                            className={styles.calendarBox}
                            style={{ minHeight: `${rowHeight}px` }}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={event => handleDrop(event, index, index)}
                            data-tech-id={technician.id}
                            data-time={time}
                          >
                            {getScheduledJobs(technician.id, time).map((schedule) => {
                              return (
                                schedule.start_time === time ? (
                                  <ScheduledBox
                                    key={schedule.id}
                                    startIndex={schedule.startIndex}
                                    {...schedule}
                                    draggable={!isGridLocked}
                                    onDragStart={!isGridLocked ? event => handleDragStart(event, schedule) : null}
                                    onClick={() => openScheduleModal(schedule)}
                                    stackIndex={getScheduledJobs(technician.id, time).indexOf(schedule)}
                                    location={schedule.suburb}
                                    job_types={schedule.job_types}
                                    lastCell={schedule.start_time === TIME_SLOTS.at(-1)}
                                    span={2}
                                  />
                                ) : null)
                            }

                            )}
                          </div>
                        )
                      ))}
                    </div>
                  </div>
                );
              })
            }
          </React.Fragment>
        )
      })}
      {isModalOpen && (
        <SchedulerModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          scheduleData={activeSchedule}
        />
      )}
    </div>
  );
};

export default SchedulerCalendar;
