const JobStatus = Object.freeze({
    NOT_SCHEDULED: "Not Scheduled",
    SCHEDULED: "Scheduled",
    CANCELLED: "Cancelled",
    STARTED: "Started",
    PENDING: "Pending",
    OPEN: "Open",
    COMPLETED: "Completed",
});

export { JobStatus };