import { useState, useEffect } from "react";
import TableItems from "../../../Components/UIComponents/TableItems";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import AddTag from "./Add/AddTags";
import EditTag from "./Edit/EditTag";

const TagsPage = () => {
  const [tableData, setTableData] = useState();
  const [refreshTable, setRefreshTable] = useState(false);

  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);

  const toggleAddModal = () => { setIsModalAddOpen(!isModalAddOpen); }
  const toggleEditModal = (id) => { setIsModalEditOpen(!isModalEditOpen); };

  const [editedID, setEditedID] = useState();

  const headers = ["Tag", "Description", "Status", "Action"];

  useEffect(() => {
    setTableData(
      <WBTable tableData="/tagsJson" tableHeaders={headers}>
        <TableItems 
            itemTitle="Tag" identifiers={["title", "description"]} removeBranch="/removeTag"
            editFunc={toggleEditModal} setEditedID={setEditedID} setRefreshTable={setRefreshTable}
          />
      </WBTable>
    );
  }, [refreshTable]);

  return (
    <div className="content-area-full">
      {isModalAddOpen && <AddTag closeModalMethod={toggleAddModal} returnFunc={() => {
        setRefreshTable(!refreshTable)
      }}/>}
      {isModalEditOpen && <EditTag closeModalMethod={toggleEditModal} editedID={editedID} returnFunc={() => {
        setRefreshTable(!refreshTable)
      }}/>}
      <div className="flex floating-box flex-column bg-white gap-20" style={{ padding: "42px 36px 35px 36px"}}>
        <div className="flex justify-between">
          <div className="text-base">
            <b>Tags</b>
          </div>
          <WBButton title="Add New Tag" func={toggleAddModal} color={"white"} />
        </div>
        {tableData}
      </div>
    </div>
  );
};

export default TagsPage;
