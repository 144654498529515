import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { CallDB, PostDB } from "../../../../Components/DBTools";
import { AddServiceValidation } from "../../../../lib/js/addservicevalidation";
import { BlobToBase64 } from "../../../../lib/js/convertimagetobase64";
import AddIndustry from "../Add/AddIndustry";
import AddCategories from "../Add/AddCatergories";
import EditCategories from "../Edit/EditCategoires";
import { toast } from "react-toastify";
import { 
    WBButton, 
    WBDragDrop, 
    WBDropdown,
    WBDropdown2, 
    WBDropdownOption, 
    WBModal, 
    WBSelector, 
    WBTabs,
	WBIOSToggle 
} from "../../../../Components/UIComponents/WBComponents";
import { useLocation } from "react-router-dom";

const AddService = ({ isCollapsed }) => {
    const location = useLocation();
    const preload = location.state?.preload || [];

    const [industry, setIndustry] = useState(0);
    const [category, setCategory] = useState();
    const [subCategory, setSubCategory] = useState();
    const [code, setCode] = useState();
    const [spiff, setSpiff] = useState(0);
    const [tag, setTag] = useState(0);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [specialNotes, setSpecialNotes] = useState("");
    const [billableRates, setBillableRates] = useState([]);
    const [active, setActive] = useState(true);

    const [industriesFormatted, formatIndustries] = useState([]);
    const [categoriesFormatted, formatCategories] = useState("");
    const [subCategoriesFormatted, formatSubCategories] = useState("");
    const [spiffsFormatted, formatSpiffs] = useState("");
    const [tagsFormatted, formatTags] = useState("");
    const [hourlyRatesFormatted, formatHourlyRates] = useState("");

    const [daysTrade, setDaysTrade] = useState(0);
    const [hoursTrade, setHoursTrade] = useState(0);
    const [minutesTrade, setMinutesTrade] = useState(0);
    const [daysHelper, setDaysHelper] = useState(0);
    const [hoursHelper, setHoursHelper] = useState(0);
    const [minutesHelper, setMinutesHelper] = useState(0);

    let tempMaterialCosts = 0.00;
    const [materialCosts, setMaterialCosts] = useState(tempMaterialCosts);

    let tempUtilitiesCosts = 0.00;
    const [utilitiesCosts, setUtilitiesCosts] = useState(tempUtilitiesCosts);

    const industryChange = useRef(false);
    const [manualMargins, setManualMargins] = useState([]);

    //Display Components
    const [myFiltersSelected, setMyFiltersSelected] = useState([]); // built in WBSelector, returned to DB on submit
    const [myMaterialsSelected, setMyMaterialsSelected] = useState([]); // built in WBSelector, returned to DB on submit
    const [myUtilitiesSelected, setMyUtilitiesSelected] = useState([]); // built in WBSelector, returned to DB on submit

    const [isDisabled, setIsDisabled] = useState(false);

    const [submitResult, setSubmitResult] = useState();

    const [tempUploadedFiles, setTempUploadedFiles] = useState([]);
    const [tempUploadedTypes, setTempUploadedTypes] = useState([]);
    const [filesToSend, setFilesToSend] = useState([]);
    const [pdfsFormatted, setPDFsFormatted] = useState([]);
    const [imagesFormatted, setImagesFormatted] = useState([]);
    const [modalImage, setModalImage] = useState(null);
    const [allFileTypes, setAllFileTypes] = useState([]);

    const [industries, setIndustries] = useState([]);
    const [industryName, setIndustryName] = useState();
    const [organisations, setOrganisations] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState(null);

    const [uploadFileModal, setUploadFileModal] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);
    const [showHelperRate, setShowHelperRate] = useState(false);
    const [fileTypeModal, setFileTypeModal] = useState(false);
    const [fileToChangeIndex, setFileToChangeIndex] = useState();
    const [fileToChange, setFileToChange] = useState();

    const [modalAddIndustryOpen, setModalAddIndustryOpen] = useState(false);
	const toggleAddIndustryModal = () => { setModalAddIndustryOpen(!modalAddIndustryOpen); }
	const [modalAddCategoryOpen, setModalAddCategoryOpen] = useState(false);
	const toggleAddCategoryModal = () => { 
        if (industry) {
			setModalAddCategoryOpen(!modalAddCategoryOpen);
		} else {
			toast.error("Select an industry")
        }
    }
    const [modalAddSubCategoryOpen, setModalAddSubCategoryOpen] = useState(false);
	const toggleAddSubCategoryModal = () => {
        if (category) {
			setModalAddSubCategoryOpen(!modalAddSubCategoryOpen);
		} else {
			toast.error("Select a category")
		}
    }

    const [changesMade, setChangesMade] = useState(false);
    const [showDuplicateMessage, setShowDuplicateMessage] = useState(false);
	const [lastSavedServices, setLastSavedServices] = useState();
    const [savingDuplicate, setSavingDuplicate] = useState(false);
    const navigate = useNavigate();
    
    const GetServiceLocationData = async () => {
        const response = await CallDB({ branch: "/getorgs" });
        setOrganisations(response);
        setSelectedOrg(response[0]);

        const response2 = await PostDB({
            branch: "/getAllBHTemplates",
            json: {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ org_id: 1 }), // This is just how its coded (org_id isnt actually required).....
            }
        });

        const allTrades = await PostDB({
            branch: "/settings/industries", json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        });
        if (allTrades !== null) {
            if (allTrades.length > 0) {
                formatIndustries(
                    allTrades.map((ind) => (
                        <option key={ind.id} value={ind.id}>{ind.department}</option>
                    ))
                );
                setIndustries(allTrades);
            }
        }

        if (response2 !== null && response2 !== undefined) {
            response2.forEach((bh, i) => {
                const extraHourlyRates = JSON.parse(bh.extra_data);
                let allBH = [];
                if (extraHourlyRates) {
                    for (let i = 0; i < allTrades.length; i++) {
                        if (!allBH[i]) {
                            allBH[i] = {};
                            for (let j = 0; j < extraHourlyRates.length; j++) {
                                if (allTrades[i].id === extraHourlyRates[j].dept_id) {
                                    allBH[i].industry = extraHourlyRates[j].dept_id;
                                    allBH[i].industry_name = extraHourlyRates[j].department;
                                    if (extraHourlyRates[j].billable_type === "Standard Hours") {
                                        allBH[i].standardID = extraHourlyRates[j].id;
                                        allBH[i].standard = extraHourlyRates[j].hourly_rate;
                                    }
                                    if (extraHourlyRates[j].billable_type === "Helper Hours") {
                                        allBH[i].helperID = extraHourlyRates[j].id;
                                        allBH[i].helper = extraHourlyRates[j].hourly_rate;
                                    }
                                }
                            }
                            // if (!Object.keys(allBH[i]).length) {
                            //     allBH.splice(i, 1);
                            // }
                            if (!Object.keys(allBH[i]).length) {
                                allBH[i].industry = allTrades[i].id;
                                allBH[i].industry_name = allTrades[i].name;
                                allBH[i].standardID = null;
                                allBH[i].standard = 0.00;
                                allBH[i].helperID = null;
                                allBH[i].helper = 0.00;
                            }
                        }
                    }
                }
                response2[i].extra_data = allBH;
            });
            setBillableRates(response2);
        }
    };

    const handleFileInput = async (files) => {
        const maxSize = 5 * 1024 * 1024; // Maximum file size in bytes (e.g., 5MB)

        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                if (!tempUploadedTypes[i]) {
                    toast.error("Please select file type");
                    return
                }
            }

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if (file && file.size < maxSize) {
                    const myFile = URL.createObjectURL(file);
                    let fileName = CheckFileNameDuplicates(file.name, 0);
                    if (tempUploadedTypes[i] === 3) { // "Product Image" has ID 3 in the database
                        setFilesToSend((oldArray) => [...oldArray, {
                            id: null,
                            file: myFile,
                            file_name: fileName,
                            file_type: 3,
                            file_category: "Image",
                            is_thumbnail: 0,
							action: "new"
                        }]);
                    }
                    else {
                        setFilesToSend((oldArray) => [...oldArray, {
                            id: null,
                            file: myFile,
                            file_name: fileName,
                            file_type: tempUploadedTypes[i],
                            file_category: "File",
                            is_thumbnail: 0,
							action: "new"
                        }]);
                    }
                    setUploadFileModal(toggle => !toggle);
					setTempUploadedFiles([]); 
					setTempUploadedTypes([]);
                }
                else {
                    toast.error("An error occurred while trying to upload your file, or you exceeded the file size limit (5MB). Please try again.");
                }
            }
        }
        else {
            toast.error("No files selected or invalid file type. ");
        }
    }

    const CheckFileNameDuplicates = (fileName, iterator) => {
        for (let i = 0; i < filesToSend.length; i++) {
            if (iterator === 0) {
                if (filesToSend[i].file_name === fileName) {
                    return CheckFileNameDuplicates(fileName, iterator + 1);
                }
            } else {
                if (filesToSend[i].file_name === fileName + (" (" + iterator + ")")) {
                    return CheckFileNameDuplicates(fileName, iterator + 1);
                }
            }
        }
        let newFileName = fileName + (iterator !== 0 ? (" (" + iterator + ")") : "");
        return newFileName;
    }

    const GetFileTypes = async () => {
        const response = await PostDB({
            branch: "/settings/service_media_types",
            json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        });
        if (response !== null) {
            setAllFileTypes(response);
        }
    }

    const handleImageRemove = (indextoRemove) => {
        setFilesToSend(oldArray => oldArray.filter((_, index) => index !== indextoRemove));
        RenderFiles();
    }

    const changeFileType = (indexToChange) => {
		setFileToChangeIndex(indexToChange)
		setFileToChange(filesToSend.find((_, index) => index === indexToChange))
		setFileTypeModal(toggle => !toggle)
	}

    const makeThumbnail = (indexOfThumbnail) => {
		setFilesToSend(oldArray => oldArray.map((item, index) =>
			index === indexOfThumbnail ? { ...item, is_thumbnail: 1 } : { ...item, is_thumbnail: 0 }
		));
	}

    const RenderFiles = () => {
        let allImagesFormatted = [];
        let allPDFsFormatted = [];
        let thumbnailFound = filesToSend.find(file => file.is_thumbnail === 1);
		if (!thumbnailFound) {
			thumbnailFound = filesToSend.find(file => file.file_category === "Image");
			if (thumbnailFound) {
				thumbnailFound.is_thumbnail = 1;
			}
		}
        for (let i = 0; i < filesToSend.length; i++) {
            let myFile = filesToSend[i];
            if (myFile.action !== "remove") {
                if (myFile.file_category === "Image") {
                    allImagesFormatted.push(
                        <div key={i} id={myFile.id} className="dropdownImageFrame">
                            <WBDropdown image={myFile.file} height={"96px"} width={"96px"} radius={"12px"}>
                                <WBDropdownOption title={"View Image"} func={() => { setModalImage(myFile); setShowImageModal(true) }} />
                                <WBDropdownOption title={"Make Primary"} func={() => makeThumbnail(i)} />
                                <WBDropdownOption title="Change Type" func={() => changeFileType(i)} />
                                <WBDropdownOption title={"Remove Image"} func={() => handleImageRemove(i)} />
                            </WBDropdown>
                            {myFile.is_thumbnail === 1 ? <div className="selectedThumbnail"><p>Primary</p></div> : null}
                        </div>
                    );
                }
                else if (myFile.file_category === "File") {
                    allPDFsFormatted.push(
                        <tr key={i} id={myFile.id}>
                            <td style={{ padding: "8px", maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{myFile.file_name}</td>
                            <td style={{ padding: "8px" }}>{allFileTypes.find(type => type.id === myFile.file_type) !== undefined ? allFileTypes.find(type => type.id === myFile.file_type).media_type : "Unknown"}</td>
                            <td style={{ padding: "8px" }}>
                                <WBDropdown leftitem={
                                        <div className="title">
                                            <div className="option">
                                                <span className="dot-icon"></span>
                                                <span className="dot-icon"></span>
                                                <span className="dot-icon"></span>
                                            </div>
                                        </div>
                                    } align={"end"}>
                                        <WBDropdownOption title="View" func={() => { window.open(myFile.file, "_new") }} />
                                        <WBDropdownOption title="Delete" func={() => handleImageRemove(i)} />
                                        <WBDropdownOption title="Change Type" func={() => changeFileType(i)} />
                                    </WBDropdown>
                            </td>
                        </tr>
                    );
                }
                else {
                    toast.error("An error occurred while trying to render one of your files. If a file is missing, contact support.");
                }
            }
        }
        setImagesFormatted(allImagesFormatted);
        setPDFsFormatted(allPDFsFormatted);
    }

    useEffect(() => {
        RenderFiles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filesToSend]);

    const GetCategories = async (ind) => {
        setCategory(0);
        setSubCategory(0);
        formatCategories("");
		formatSubCategories("");
        if (parseInt(ind) !== 0) {
            industryChange.current = true;
            setIndustry(parseInt(ind));
            setChangesMade(true);

            let response = await CallDB({ branch: "/pricebook/cat/" + ind });
            if (response !== null) {
                formatCategories(
                    response.map((cat) => (
                        <option key={cat.id} value={cat.id}>{cat.name}</option>
                    ))
                );
            }
        }
    };

    // Gets the subcategories for the subcategory dropdown menu.
    const GetSubCategories = async (cat) => {
        setCategory(parseInt(cat));
		setSubCategory(0);
		formatSubCategories("");
        if (parseInt(cat)) {
            let response = await CallDB({ branch: "/pricebook/subcat/" + cat });
            if (response !== null) {
                formatSubCategories(
                    response.map((sub) => (
                        <option key={sub.id} value={sub.id} className={sub.active ? "" : "disabled"}>{sub.title}</option>
                    ))
                );
            }
        }
    };

    // Gets the Spiffs.
    const GetSpiffs = async () => {
        const response = await PostDB({
            branch: "/spiffsJson", json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                // body: JSON.stringify({ org_id: selectedOrg.current }) IMPORTANT switch back to this row when ready to use selected Organisation
                body: JSON.stringify({ org_id: 1 })
            }
        });
        if (response !== null) {
            if (response.spiffs.length > 0) {
                formatSpiffs(
                    response.spiffs.map((spiff) => (
                        <option key={spiff.id} value={spiff.id} className={spiff.active ? "" : "disabled"}>{spiff.title} - {parseInt(spiff.cost).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</option>
                    ))
                );
            }
            else {
                formatSpiffs(
                    <option value="">No Spiffs Found</option>
                );
            }
        }
        else {
            formatSpiffs(
                <option value="">Failed to retrieve Spiffs</option>
            );
        }
    };

    const GetTags = async () => {
        const response = await PostDB({
            branch: "/tagsJson", json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            }
        });
        if (response !== null) {
            if (response.length > 0) {
                formatTags(
                    response.map((tag) => (
                        <option key={tag.id} value={tag.id} className={tag.status === 1 ? "" : "disabled"}>{tag.title}</option>
                    ))
                );
            }
            else {
                formatTags(
                    <option value="">No Tags Found</option>
                );
            }
        }
        else {
            formatTags(
                <option value="">Failed to retrieve Tags</option>
            );
        }
    };

    const increaseMargin = (slID) => {
        setManualMargins(prevMargins => {
            const updated = [...prevMargins];
            let foundIndex = updated.findIndex(x => x.service_area_id === slID);
            if (foundIndex !== -1) {
                updated[foundIndex].margin++;
            }
            else {
                updated.push({ service_area_id: slID, margin: 1 })
            }
            return updated;
        });
    };

    const decreaseMargin = (slID) => {
        setManualMargins(prevMargins => {
            const updated = [...prevMargins];
            let foundIndex = updated.findIndex(x => x.service_area_id === slID);
            if (foundIndex !== -1) {
                if (updated[foundIndex].margin !== 0)
                    updated[foundIndex].margin--;
            }
            else {
                updated.push({ service_area_id: slID, margin: 0 })
            }
            return updated;
        });
    }

    const FormatBillableHours = async () => {
        if (industry !== 0) {
            if (billableRates !== null && selectedOrg !== null && billableRates.length > 0) {
                var calcTradeTime = +hoursTrade + (+daysTrade * 8);
                var calcHelperTime = +hoursHelper + (+daysHelper * 8);
                let serviceLocationByOrg = selectedOrg.service_areas;
                if (serviceLocationByOrg.length > 0) {
                    const sls = serviceLocationByOrg.map((sl, i) => {
                        let extra_data = billableRates.find(rate => rate.id === sl.bhTemplateID).extra_data;
                        // For later reference:
                        //      extra_data:             Has the hourly rates for calculation
                        //      serviceLocationByOrg:   Has the service locations stored in it
                        //      marginCalc:             Has the margin calculation for the final calculation
                        //      billableRates:          All billableRates information from /getAllBH
                        if (extra_data.length > 0) {
                            let foundIndustry = extra_data.find(data => data.industry === industry);
                            if (foundIndustry) {
                                let laborCost = ((calcTradeTime + (+minutesTrade / 60)) * foundIndustry.standard) +
                                    ((calcHelperTime + (+minutesHelper / 60)) * foundIndustry.helper);
                                let manualMargin = manualMargins.find(x => x.service_area_id === sl.service_location_id);
                                if (manualMargin === undefined) {
                                    // Handle the case where no matching element is found
                                    manualMargin = { service_area_id: sl.service_location_id, margin: 0 }; // or any default value you prefer
                                    manualMargins.push(manualMargin); // Optionally add the new object to the array
                                }
                                let locationMargin = ((sl.margin + (manualMargin.margin ? manualMargin.margin : 0)) / 100 + 1);
                                let price = laborCost + materialCosts + utilitiesCosts;
                                let finalCost = price * locationMargin;
                                let gst = finalCost / 10;
                                return (
                                    <tr key={sl.service_location_id} id={sl.service_location_id} title={sl.label + " rate for " + (title !== "" ? title : "this service")}>
                                        <td style={{ padding: "8px 36px" }}>{sl.label}</td>
                                        <td style={{ padding: "8px 36px" }}>
                                            <span style={{ display: "block" }}>
                                                ${foundIndustry.standard.toFixed(2)
                                                    .toString()
                                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "/ph"}</span>
                                            {calcHelperTime !== 0 && <span style={{ display: "block", fontSize: "12px" }}>${foundIndustry.helper.toFixed(2)
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "/ph (Helper)"}</span>}
                                        </td>
                                        <td style={{ padding: "8px 36px" }}>
                                            <span style={{ display: "block" }}>
                                                {hoursTrade !== 0 || daysTrade !== 0
                                                    ? calcTradeTime + "hr"
                                                    : "0hr"}
                                                {minutesTrade !== 0 ? minutesTrade + "m" : ""}
                                            </span>
                                            {calcHelperTime !== 0 && <span style={{ fontSize: "12px", display: "block", height: "10px" }}>
                                                {hoursHelper !== 0 || daysHelper !== 0 ? calcHelperTime + "hr" : "0hr"}
                                                {minutesHelper !== 0 ? minutesHelper + "m" : ""}
                                                {hoursHelper !== 0 || daysHelper !== 0 || minutesHelper !== 0 ? "(Helper)" : ""}
                                            </span>}
                                        </td>
                                        <td style={{ padding: "8px 36px" }}>
                                            ${laborCost
                                                .toFixed(2)
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </td>
                                        <td style={{ padding: "8px 36px" }}>
                                            ${materialCosts
                                                .toFixed(2)
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </td>
                                        <td style={{ padding: "8px 36px" }}>
                                            ${utilitiesCosts
                                                .toFixed(2)
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </td>
                                        <td style={{ padding: "8px 36px" }}>
                                            ${gst
                                                .toFixed(2)
                                                .toString()
                                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                        </td>
                                        <td style={{ padding: "8px 36px" }}>
                                            <div className="flex flex-column">
                                                <p>${finalCost
                                                    .toFixed(2)
                                                    .toString()
                                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                                            </div>
                                        </td>
                                        <td style={{ padding: "8px 36px" }}>
                                            <div className="flex flex-row justify-between gap-10" style={{ justifyContent: "flex-end" }}>
                                                <b>+{manualMargin !== undefined ? manualMargin.margin : 0}%</b>
												<button onClick={() => decreaseMargin(sl.service_location_id)} className="margin_button">
													<svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
														<path d="M5.48242 9L14.8283 9" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
													</svg>
												</button>
												<button onClick={() => increaseMargin(sl.service_location_id)} className="margin_button" style={{ border: "1px solid #0094FF" }}>
													<svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
														<path d="M5.48242  9L14.8283 9" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
														<path d="M10 4L10 14" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
													</svg>
												</button>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            } else {
                                return (
                                    <BlankServiceLocationRow title={sl.label + " has no Billable Hour template"} />
                                );
                            }
                        } else {
                            return (
                                <BlankServiceLocationRow title={"No billable hours exist for " + sl.label} />
                            );
                        }
                    });
                    formatHourlyRates(sls);
                } else {
                    formatHourlyRates(
                        <BlankServiceLocationRow title={"No service locations found in " + selectedOrg.short_name} />
                    );
                }

            } else {
                formatHourlyRates(
                    <BlankServiceLocationRow title={"There was an error loading organisations. Please refresh"} />
                );
            }
        }
        else {
            formatHourlyRates(
                <BlankServiceLocationRow title={"Please select an industry to proceed."} />
            );
        }
    };

    const BlankServiceLocationRow = ({ title = "N/A" }) => {
        return (
            <tr>
                <td colSpan="9" style={{ textAlign: "center", paddingTop: "20px" }}>
                    <i>{title}</i>
                </td>
            </tr>
        )
    }

    const SaveService = async (duplicate) => {
        // if (duplicate) {
		// 	setSavingDuplicate(true)
		// }
        setIsDisabled(!isDisabled);
        setSubmitResult(
            <div className="search-spinner" >
                <i className="icon-search-loader animate-rotate"></i>
                Saving. Please wait
            </div>
        );
        let allFiles = [];
        for (let i = 0; i < filesToSend.length; i++) {
            allFiles.push({
                "file": await BlobToBase64(filesToSend[i].file),
                "file_name": filesToSend[i].file_name,
                "media_type": filesToSend[i].file_type,
                "activeThumbnail": filesToSend[i].is_thumbnail === 1 ? true : false,
            })
        }

        const invalidFilter =  myFiltersSelected.map(filter => {
			if (!filter.subID || filter.subID == 0) {
				toast.error("Please select sub filters");
				return true;
			}
			return false;
		});
		
		if (invalidFilter.includes(true)) {
			setSubmitResult();
			setIsDisabled(!isDisabled);
			return;
		};

        let bodyObj = JSON.stringify({
            inpTradeID: industry,
            org_id: 1,
            inpSerTitle: title,
            inpSerDesc: description,
            inpCatID: category,
            inpSubCatID: subCategory,
            // inpSerCode: code,
            inpSerNotes: specialNotes,
            inpSpiffID: spiff,
            // tagData: myTagsSelected.map(tag => ({ tag_id: tag.id })),
            tagData: tag ? [{ tag_id: tag }] : [],
            filtersData: myFiltersSelected.map(filter => ({ filter_id: filter.id, sub_filter_id: filter.subID })),
            serviceHours: {
                trademan_days: daysTrade,
                trademan_hours: hoursTrade,
                trademan_mins: minutesTrade,
                helper_days: daysHelper,
                helper_hours: hoursHelper,
                helper_mins: minutesHelper
            },
            utilData: myUtilitiesSelected.map(util => ({ util_id: util.id })),
            matData: myMaterialsSelected.map(mat => ({ mat_id: mat.id })),
            marginData: manualMargins,
            servicefiles: allFiles,
            status: active === true ? 1 : 0
        });
        if (AddServiceValidation(bodyObj)) {
            const response = await PostDB({
                branch: "/addService",
                json: {
                    method: "POST",
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json"
                    },
                    body: bodyObj
                }
            });
            if (response.inserted_service_id) {
                if (duplicate) {
                    setLastSavedServices(`${response.inserted_service_id}-${response.inserted_service_code}`);
                    setTitle(title => title + " - Duplicate");
                    // setSavingDuplicate(false);
                    setShowDuplicateMessage(true);
                }
                else {
                    toast.success("Service has been added successfully.")
                    setTimeout(() => {
                        navigate(-1);
                    }, 500);
                }
            } else {
                toast.error("There was an error submitting to the database.");
            }
        }
        setSubmitResult();
        setIsDisabled(!isDisabled);
    };

    const changeSelectedOrg = (value) => {
        let result = organisations.find((org) => org.short_name === value);
        setSelectedOrg(result);
    };

    const RunPreload = async () => {
        if (preload[0]) {
            await GetServiceLocationData();
            setIndustry(preload[0] !== 0 && preload[0]);
        }
        if (preload[1]) {
            await GetCategories(preload[0]);
            setCategory(preload[1] !== 0 && preload[1]);
        }
        if (preload[2]) {
            await GetSubCategories(preload[1]);
            setSubCategory(preload[2] !== 0 && preload[2]);
        }
        GetServiceLocationData();
        GetSpiffs();
        GetTags();
        GetFileTypes();
        setFilesToSend([]);
    }

    useEffect(() => {
        RunPreload(preload);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // Run only once, on load. No function here relies on industry or other factors to run

	useEffect(() => {
		if (industry && !modalAddCategoryOpen) {
			GetCategories(industry);
			GetSubCategories(category);
		}
	}, [modalAddCategoryOpen]);

    useEffect(() => {
		if (industry && !modalAddSubCategoryOpen) {
			GetSubCategories(category);
		}
	}, [modalAddSubCategoryOpen]);

    useEffect(() => {
        if (industry !== 0) {
            GetServiceLocationData();
			GetCategories(industry);

            const stickyElement = document.querySelector('.service-organisations-sticky');
            const floatingBox = document.querySelector('.floating-box-static.organisations');

            const handleScroll = () => {
                const stickyElementRect = stickyElement.getBoundingClientRect();
                const floatingBoxRect = floatingBox.getBoundingClientRect();

                if (stickyElementRect.top <= 70 && floatingBoxRect.top <= 70) {
                    stickyElement.classList.add('sticky');
                    stickyElement.style.width = `${floatingBoxRect.width}px`;
                    stickyElement.style.left = `${floatingBoxRect.left}px`;
                    stickyElement.style.boxShadow = '0px 6px 22px 0px rgba(231, 231, 231, 0.50)';
                    stickyElement.style.paddingBottom = `10px`;
                    stickyElement.style.paddingTop = `0px`;
                    floatingBox.style.paddingBottom = `${stickyElementRect.height}px`;
                } else {
                    stickyElement.classList.remove('sticky');
                    stickyElement.style.boxShadow = '';
                    stickyElement.style.paddingBottom = '21px';
                    stickyElement.style.paddingTop = '21px';
                    floatingBox.style.paddingBottom = '';
                }
            };

            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [industry]);

    useEffect(() => {
        FormatBillableHours();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [materialCosts, utilitiesCosts, hoursTrade, hoursHelper, daysTrade, daysHelper, minutesHelper, minutesTrade, industry, selectedOrg, manualMargins]); // update table when price, time or margin change

    const removeItem = (uniqueKey) => {
		setTempUploadedFiles(prevItems => prevItems.filter((item, index) => index !== uniqueKey));    
    }

    const parentRef = useRef(null);
    return (
        <div className="add-service">
            {uploadFileModal &&
                <WBModal title={"Upload Media"} parentRef={parentRef} closeFunction={() => { setUploadFileModal(toggle => !toggle); setTempUploadedFiles([]); setTempUploadedTypes([]); }} submitFunction={async () => { await handleFileInput(tempUploadedFiles); }} >
                    <div className="gap-30 p-40">
                        <WBDragDrop handleInput={(e) => {
                            setTempUploadedFiles(existingFiles => [...existingFiles, ...e]);
                            setTimeout(() => {
								const parent = parentRef.current?.closest(".parent-class");
								if (parent) {
									parent.scrollTop = parent.scrollHeight;
								}
							}, 100);
                        }} />
                        {tempUploadedFiles.length > 0 && <div className="uploaded-files">
                            <div className="uploaded-files-title">
                                <p className="uploaded-files-title-text">File Name</p>
                                <p className="uploaded-files-title-text">File Type</p>
                            </div>
                            <div className="uploaded-files-content">
                                {Array.from(tempUploadedFiles).map((file, index) => (
                                    <div className="uploaded-files-content-item" key={index} style={{ gap: "10px"}}>
                                        <p className="uploaded-files-content-item-text">{file.name}</p>
                                        <WBDropdown2 fixedHeight="210px" leftitem={allFileTypes.find(type => type.id === tempUploadedTypes[index]) !== undefined ? allFileTypes.find(type => type.id === tempUploadedTypes[index]).media_type : undefined} rightitem="arrow" color={"white"} defaultValue="Select File Type" >
                                            {allFileTypes.map((type, i) => (
                                                <WBDropdownOption key={i} title={type.media_type} func={() => setTempUploadedTypes(prev => { prev[index] = type.id; return prev; })} />
                                            ))}
                                        </WBDropdown2>
                                        <WBButton color="white" icon={<i className="icon-trash" />} func={() => removeItem(index)} width={"50px"} />
                                    </div>
                                ))}
                            </div>
                        </div>}
                    </div>
                </WBModal>
            }
            {fileTypeModal &&
                            <WBModal title={"File Type"} closeFunction={() => { setFileTypeModal(toggle => !toggle); }} submitFunction={() => { 
                                setFileTypeModal(toggle => !toggle); 
                                setFilesToSend(oldArray => oldArray.map((item, index) =>
                                    index === fileToChangeIndex ? { ...item, file_type: fileToChange.file_type, file_category: fileToChange.file_category, action: "update" } : item
                                ));
                            }} >
                                <div className="gap-30 p-40">
                                    {fileToChangeIndex != null && fileToChange && <div className="uploaded-files flex flex-column gap-10">
                                        <p className="uploaded-files-content-item-text">{fileToChange.file_name}</p>
                                        <WBDropdown2 leftitem={allFileTypes.find(type => type.id === fileToChange.file_type) !== undefined ? allFileTypes.find(type => type.id === fileToChange.file_type)?.media_type : undefined} rightitem="arrow" color={"white"} defaultValue="Select File Type" >
                                            {allFileTypes.map((type, i) => (
                                                <WBDropdownOption key={i} title={type.media_type} func={() => {
                                                    setFileToChange(file => ({ ...file, file_type: type.id, file_category: type.id === 3 ? "Image" : "File" }))
                                                }}/>
                                            ))}
                                        </WBDropdown2>
                                    </div>}
                                </div>
                            </WBModal>
                        }
            {showImageModal && <WBModal imagemodal={modalImage.file} closeFunction={() => setShowImageModal(toggle => !toggle)} />}
            {modalAddIndustryOpen && <AddIndustry closeModalMethod={toggleAddIndustryModal} setIndustry={setIndustry}/>}
			{modalAddCategoryOpen && <AddCategories closeModalMethod={toggleAddCategoryModal} preloadIndustryId={industry} preloadIndustryName={industryName} setCategory={setCategory}/>}
            {modalAddSubCategoryOpen && <EditCategories closeModalMethod={toggleAddSubCategoryModal} editedID={category} />}
            {savingDuplicate && (
				<div className="bg-darkblue popup">
					<div className="popup-window popup-container" style={{ width: "300px", height: "250px", display: "flex", flexDirection: "column", zIndex: 20, alignItems: "center", justifyContent: "center" }}> 
						<div className="search-spinner" >
							<h1>Saving Service</h1>
							<i className="icon-search-loader animate-rotate"></i>
						</div>
						<p style={{ padding: "20px"}}>A new duplicate service will be created.</p>
					</div>
				</div>
			)}
            {showDuplicateMessage && <div className="flex flex-column">
				<div className="flex flex-row" style={{ height: "auto"}}>
					<div className="floating-box" style={{ flexGrow: "0", width: "100%", overflowX: "auto", backgroundColor: "#FFF3E9", border: "1px solid #F6AE5B"}}>
						<div className="flex floating-box-item" style={{ alignItems: "flex-start" }}>
							<div className="flex flex-row w-full" style={{justifyContent: "space-between"}}>
								<div className="flex flew-row gap-10" style={{ alignItems: "center" }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
										<path d="M16.9707 12.25V16.75C16.9707 20.5 15.4707 22 11.7207 22H7.2207C3.4707 22 1.9707 20.5 1.9707 16.75V12.25C1.9707 8.5 3.4707 7 7.2207 7H11.7207C15.4707 7 16.9707 8.5 16.9707 12.25Z" fill="white" stroke="#FFA600" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
										<path d="M21.9707 5.85V9.15C21.9707 11.9 20.8707 13 18.1207 13H16.9707V12.25C16.9707 8.5 15.4707 7 11.7207 7H10.9707V5.85C10.9707 3.1 12.0707 2 14.8207 2H18.1207C20.8707 2 21.9707 3.1 21.9707 5.85Z" fill="white" stroke="#FFA600" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
									</svg>
									<p>
                                        Service <b>#{lastSavedServices}</b> has been successfully duplicated.
									</p>
								</div>
								<button style={{background: "none", border: "none", cursor: "pointer", fontSize: "14px", textDecorationLine: "underline" }} onClick={() => {
									setShowDuplicateMessage(false);
								}}>Dismiss</button>
							</div>
						</div>
					</div>
				</div>
			</div>}
            <div className="flex flex-row" style={{ height: "auto" }}>
                <div className="floating-box bg-white" style={{ flexGrow: "0", width: "100%", overflowX: "auto" }}>
                    <div className="flex justify-between floating-box-item">
                        <div className="text-base">
                            <b>Add Service</b>
                        </div>
                        <div className="button-row right gap-10">
                            {submitResult}
                            <WBButton title="Cancel" color="white" func={() => { 
                                if (industry !== 0 ) {
                                    if (window.confirm("You are about to leave this page? Are you sure?")) {
                                        navigate(-1);
                                    }  
                                } else {
                                    navigate(-1); 
                                }
                            }} />
                            <WBDropdown leftitem={"Save & Exit"} rightitem="arrow-white" color={"green"} dropdownOffset>
                                <WBDropdownOption title={"Save & Exit"} func={() => SaveService(false)} />
                                <WBDropdownOption title={"Save & Duplicate"} func={() => SaveService(true)} />
                            </WBDropdown>
                        </div>
                    </div>
                    <div className="flex border-bottom flex-column gap-10 floating-box-item">
                        <h3>Categories</h3>
                        <div className="categorieswarning">
                            <i className="icon-info-circle" />
                            <p>Choose the most appropriate Industry, category and subcategory to help users easily find and understand the service.</p>
                        </div>
                        <div className="flex flex-row gap-10" style={{ flexWrap: "wrap", paddingTop: "20px"}}>
                            <div className="flex flex-column">
                                <label className="cat_label" htmlFor="sel-category">Industry <span className="not-bold font-size-14">(Required)</span></label>
                                <select className="cat_select" name="sel-industry" id="sel-industry" value={industry} style={{ width: "18em" }} onChange={(selected) => {
                                        GetCategories(selected.target.value);
                                        const industryItem = industries?.find(indItem => indItem.id === parseInt(selected.target.value));
            							setIndustryName(industryItem?.name);
                                    }}>
                                    <option value="0" disabled>None</option>
                                    {industriesFormatted.reverse()}
                                </select>
                                {/* <button style={{ color: "#0094FF", background: "none", border: "none", cursor: "pointer", marginLeft: "auto", fontSize: "12px" }} onClick={toggleAddIndustryModal}>ADD NEW INDUSTRY</button> */}
                            </div>
                            <div className="flex flex-column">
                                <label className="cat_label" htmlFor="sel-category">Category</label>
                                <select className="cat_select" name="sel-category" id="sel-category" value={category} style={{ width: "18em" }} onChange={(selected) => GetSubCategories(selected.target.value)}>
                                    <option value="0">None</option>
                                    {categoriesFormatted}
                                </select>
                                {/* <button style={{ color: "#0094FF", background: "none", border: "none", cursor: "pointer", marginLeft: "auto", fontSize: "12px" }} onClick={toggleAddCategoryModal}>ADD NEW CATEGORY</button> */}
                            </div>
                            <div className="flex flex-column">
                                <label className="cat_label" htmlFor="sel-subcategory">Sub Category</label>
                                <select className="cat_select" name="sel-subcategory" id="sel-subcategory" value={subCategory} style={{ width: "18em" }} onChange={(selected) => { setSubCategory(parseInt(selected.target.value)) }} >
                                    <option value="0">None</option>
                                    {subCategoriesFormatted}
                                </select>
                                {/* <button style={{ color: "#0094FF", background: "none", border: "none", cursor: "pointer", marginLeft: "auto", fontSize: "12px" }} onClick={toggleAddSubCategoryModal}>ADD SUBCATEGORIES</button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {industry ?
                <>
                    <div className={`flex flex-row ${isCollapsed ? "largeServiceMedia" : "smallServiceMedia"}`} style={{ height: "auto" }}>
                        <div className="floating-box bg-white left-box" style={{ flexGrow: "0", width: "50%", overflowX: "auto" }}>
                            <div className="flex flex-column floating-box-item">
                                <div className="text-base">
                                    <b>Service Details</b>
                                </div>
                                <div className="flex flex-row" style={{ justifyContent: "space-between" }}>
									<p style={{ paddingTop: "12px" }}><b>Code:</b> </p>
								</div>
                            </div>
                            <div className="row border-bottom floating-box-item">
                                <div className="col" style={{ width: "100%" }}>
                                    <div className="pb-20">
                                        <label className="cat_label" htmlFor="service_title">Title <span className="not-bold font-size-14">(Required)</span></label>
                                        <input type="text" className="form-control" name="service_title" id="service_title" placeholder="enter title of the service" value={title} required onChange={(title) => setTitle(title.target.value)} />
                                    </div>

                                    <div className="pb-20">
                                        <label className="cat_label" htmlFor="service_desc">Description <span className="not-bold font-size-14">(Required)</span></label>
                                        <textarea className="form-control" name="service_desc" id="service_desc" cols="40" rows="5" placeholder="Enter description of the service" value={description} onChange={(desc) => setDescription(desc.target.value)} style={{ width: "100%", maxWidth: "100%" }}></textarea>
                                    </div>

                                    <div className="pb-20">
                                        <label className="cat_label" htmlFor="service_notes">Special Notes</label>
                                        <textarea className="form-control" name="service_notes" id="service_notes" cols="40" rows="2" placeholder="Enter special notes for technicians" value={specialNotes} onChange={(specNotes) => setSpecialNotes(specNotes.target.value)} style={{ width: "100%", maxWidth: "100%" }}></textarea>
                                    </div>

                                    <div className="row">
                                        <div className="col">
                                            <label className="sel-spiff" htmlFor="sel-spiff">Spiff</label>
                                            <select className="w-full" name="sel-spiff" id="sel-spiff" value={spiff} required onChange={(selected) => { setSpiff(parseInt(selected.target.value)) }}>
                                                <option value="0">-- Choose Spiff --</option>
                                                {spiffsFormatted}
                                            </select>
                                        </div>
                                        <div className="col">
                                            <label className="sel-tag" htmlFor="sel-tag">Tag</label>
                                            <select className="w-full" name="sel-tag" id="sel-tag" value={tag} required onChange={(selected) => { setTag(parseInt(selected.target.value)) }}>
                                                <option value="0">-- Choose Tag --</option>
                                                {tagsFormatted}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="floating-box bg-white right-box" style={{ flexGrow: "0", width: "50%", overflow: "visible" }}>
                            <div className="flex justify-between floating-box-item">
                                <div className="text-base">
                                    <b>Media</b>
                                </div>
                                <div className="button-row right gap-10" style={{ height: "40px" }}>
                                    <WBButton title="Upload" color="white" func={() => setUploadFileModal(toggle => !toggle)} />
                                </div>
                            </div>
                            <div className="border-bottom flex flex-column floating-box-item">
                                <div className="col gap-10">
                                    <label className="cat_label" htmlFor="service_codea">Files</label>
                                    {pdfsFormatted.length > 0 ? 
                                        <div style={{ whiteSpace: "nowrap", width: "100%" }}>
                                            <table style={{ width: "100%", borderCollapse: "collapse" }}>
                                                <thead >
													<tr className="comTableheader">
														<th style={{ padding: "8px", textAlign: "left" }}>Name</th>
														<th style={{ padding: "8px", textAlign: "left" }}>Type</th>
														<th style={{ padding: "8px", textAlign: "left" }}></th>
													</tr>
												</thead>

                                                <tbody>
													{pdfsFormatted.length > 0 ? 
														pdfsFormatted
														: 
														<tr>
															<td colSpan="3" style={{ textAlign: "center", paddingTop: "20px" }}>No Files Uploaded</td>
														</tr>}
												</tbody>
                                            </table>
                                        </div>
                                        :
                                        <p>No Files Uploaded</p>}
                                    <label className="cat_label" htmlFor="service_codea">Images</label>

                                    <div className="serviceImages">
                                        {imagesFormatted.length > 0 ? imagesFormatted : <p>No Images Selected</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-row" style={{ height: "auto" }}>
                        <div className="floating-box bg-white" style={{ flexGrow: "0", width: "100%", overflowX: "hidden", gap: "10px", marginBottom: "0px", borderBottomLeftRadius: "0px", borderBottomRightRadius: "0px", boxShadow: "none" }}>
                            <div className="flex justify-between floating-box-item">
                                <div className="text-base">
                                    <b>Organisations</b>
                                </div>
                            </div>
                            </div>
					</div>
                    <div className="flex flex-column w-full" style={{ position: "sticky", top: 0, zIndex: 10, backgroundColor: "#F7F9FB"}}> 
						<div className="flex flex-row" style={{ width: "100%", height: "auto"}}>
							<div className="floating-box bg-white" style={{ flexGrow: "0", width: "100%", overflowX: "hidden", gap: "10px", marginTop: "0px", borderTopLeftRadius: "0px", borderTopRightRadius: "0px", boxShadow: "none"}}>
                                <div className="border-bottom" style={{ padding: "23px 36px" }}>
                                    <WBTabs headers={organisations.map((org) => org.short_name)} func={(e) => changeSelectedOrg(e)} />
                                    <div style={{ overflowX: "scroll", whiteSpace: "nowrap", marginTop: "18px" }}>
                                        <table style={{ borderCollapse: "collapse", width: "100%" }}>
                                            <thead >
                                                <tr>
                                                    <th className="headerCell" style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", padding: "0px 36px", textAlign: "start", }}>Service Locations</th>
                                                    <th className="headerCell" style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", padding: "0px 36px", textAlign: "start", }}>Billable Hourly Rate</th>
                                                    <th className="headerCell" style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", padding: "0px 36px", textAlign: "start", }}>Time</th>
                                                    <th className="headerCell" style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", padding: "0px 36px", textAlign: "start", }}>Labor Cost</th>
                                                    <th className="headerCell" style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", padding: "0px 36px", textAlign: "start", }}>Material Cost</th>
                                                    <th className="headerCell" style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", padding: "0px 36px", textAlign: "start", }}>Utility Cost</th>
                                                    <th className="headerCell" style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", padding: "0px 36px", textAlign: "start", }}>Tax</th>
                                                    <th className="headerCell" style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", padding: "0px 36px", textAlign: "start", }}>Price</th>
                                                    <th className="headerCell" style={{ borderTopRightRadius: "10px",  borderBottomRightRadius: "10px", padding: "0px 36px", display: "flex", justifyContent: "flex-end", paddingRight: "36px", alignItems:"center" }}>Margin</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {hourlyRatesFormatted}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>           
                    </div>

                    <div className="flex flex-row" style={{ height: "auto" }}>
                        <div className="floating-box bg-white" style={{ flexGrow: "0", width: "100%", overflowX: "auto" }}>
                            <div className="flex justify-between floating-box-item">
                                <div className="text-base" style={{ whiteSpace: "nowrap", paddingRight: "10px" }}>
                                    <b>Workers</b>
                                    <p>Billable Hours (Time it takes to complete task)</p>
                                </div>
                            </div>
                            <div className="flex flex-column gap-10 border-bottom floating-box-item">
                                <h4>Tradesman</h4>
                                <div className="flex flex-row gap-10" style={{ flexWrap: "wrap"}}>
                                    <div className="flex flex-column">
                                        <select
                                            className="form-select"
                                            name="sel-days"
                                            value={daysTrade}
                                            id="sel-days"
                                            aria-label="Default select example"
                                            onChange={(daysT) =>
                                                setDaysTrade(parseInt(daysT.target.value))
                                            }
                                            style={{ width: "168px" }}
                                        >
                                            <option value="0">0 Days</option>
                                            <option value="1">1 Days</option>
                                            <option value="2">2 Days</option>
                                            <option value="3">3 Days</option>
                                        </select>
                                    </div>

                                    <div className="flex flex-column">
                                        <select
                                            className="form-select"
                                            name="sel-hours"
                                            value={hoursTrade}
                                            id="sel-hours"
                                            aria-label="Default select example"
                                            onChange={(hoursT) =>
                                                setHoursTrade(parseInt(hoursT.target.value))
                                            }
                                            style={{ width: "168px" }}
                                        >
                                            <option value="0">0 Hour</option>
                                            <option value="1">1 Hour</option>
                                            <option value="2">2 Hour</option>
                                            <option value="3">3 Hour</option>
                                            <option value="4">4 Hour</option>
                                            <option value="5">5 Hour</option>
                                            <option value="6">6 Hour</option>
                                            <option value="7">7 Hour</option>
                                            <option value="8">8 Hour</option>
                                        </select>
                                    </div>

                                    <div className="flex flex-column">
                                        <select
                                            className="form-select"
                                            name="sel-mins"
                                            value={minutesTrade}
                                            id="sel-mins"
                                            aria-label="Default select example"
                                            onChange={(minutesT) =>
                                                setMinutesTrade(parseInt(minutesT.target.value))
                                            }
                                            style={{ width: "168px" }}
                                        >
                                            <option value="0">0 Min</option>
                                            <option value="15">15 Min</option>
                                            <option value="30">30 Min</option>
                                            <option value="45">45 Min</option>
                                        </select>
                                    </div>
                                </div>

                                {!showHelperRate ? <WBButton title={"+ Add Helper"} color={"white"} func={() => setShowHelperRate(!showHelperRate)} /> :
                                    <>
                                        <h4>Helper</h4>
                                        <div className="hourly flex flex-row gap-10">
                                            <div className="flex flex-column">
                                                <select
                                                    className="form-select"
                                                    name="sel-days-helper"
                                                    value={daysHelper}
                                                    id="sel-days-helper"
                                                    aria-label="Default select example"
                                                    onChange={(daysH) =>
                                                        setDaysHelper(parseInt(daysH.target.value))
                                                    }
                                                    style={{ width: "168px" }}
                                                >
                                                    <option value="0">0 Days</option>
                                                    <option value="1">1 Days</option>
                                                    <option value="2">2 Days</option>
                                                    <option value="3">3 Days</option>
                                                </select>
                                            </div>

                                            <div className="flex flex-column">
                                                <select
                                                    className="form-select"
                                                    name="sel-hours-helper"
                                                    value={hoursHelper}
                                                    id="sel-hours-helper"
                                                    aria-label="Default select example"
                                                    onChange={(hoursH) =>
                                                        setHoursHelper(parseInt(hoursH.target.value))
                                                    }
                                                    style={{ width: "168px" }}
                                                >
                                                    <option value="0">0 Hour</option>
                                                    <option value="1">1 Hour</option>
                                                    <option value="2">2 Hour</option>
                                                    <option value="3">3 Hour</option>
                                                    <option value="4">4 Hour</option>
                                                    <option value="5">5 Hour</option>
                                                    <option value="6">6 Hour</option>
                                                    <option value="7">7 Hour</option>
                                                    <option value="8">8 Hour</option>
                                                </select>
                                            </div>

                                            <div className="flex flex-column">
                                                <select
                                                    className="form-select"
                                                    name="sel-mins-helper"
                                                    value={minutesHelper}
                                                    id="sel-mins-helper"
                                                    aria-label="Default select example"
                                                    onChange={(minutesH) =>
                                                        setMinutesHelper(parseInt(minutesH.target.value))
                                                    }
                                                    style={{ width: "168px" }}
                                                >
                                                    <option value="0">0 Min</option>
                                                    <option value="15">15 Min</option>
                                                    <option value="30">30 Min</option>
                                                    <option value="45">45 Min</option>
                                                </select>
                                            </div>
                                        </div>
                                    </>}
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-row" style={{ height: "auto" }}>
                        <div className="floating-box bg-white" style={{ flexGrow: "0", width: "100%", overflowX: "auto" }}>
                            <div className="flex justify-between floating-box-item">
                                <div className="text-base">
                                    <b>Filters</b>
                                </div>
                            </div>
                            <div className="border-bottom bg-white border-radius-bottom-10 floating-box-item">
                                <WBSelector title="Filter" isFilter={true} data="/filtersByIndustry" industry={industry} identifiers={["filter_id", "filter_name"]} subIdentifiers={["sub_filter_id", "filter_id", "sub_filter_name"]} returnFunc={setMyFiltersSelected} modal nosearchlimit isUnique="true"/>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-row" style={{ height: "auto" }}>
                        <div className="floating-box bg-white" style={{ flexGrow: "0", width: "100%", overflowX: "auto" }}>
                            <div className="flex justify-between floating-box-item">
                                <div className="text-base">
                                    <b>Materials</b>
                                </div>
                            </div>
                            <div className="border-bottom bg-white border-radius-bottom-10 floating-box-item">
                                <WBSelector title="Material" isMaterial="true" data="/materialsByKeyword" industry={industry} identifiers={["id", "title", "final_cost", "supplier"]} returnFunc={setMyMaterialsSelected} returnCostFunc={setMaterialCosts} modal nosearchlimit givenMinWidth="1100px"/>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-row" style={{ height: "auto" }}>
                        <div className="floating-box bg-white" style={{ flexGrow: "0", width: "100%", overflowX: "auto" }}>
                            <div className="flex justify-between floating-box-item">
                                <div className="text-base">
                                    <b>Utilities</b>
                                </div>
                            </div>
                            <div className="border-bottom bg-white border-radius-bottom-10 floating-box-item">
                                <WBSelector title="Utilities" data="/utilitiesByKeyword" industry={industry} identifiers={["id", "title", "cost"]} returnFunc={setMyUtilitiesSelected} returnCostFunc={setUtilitiesCosts} modal />
                            </div>
                        </div>
                    </div>
                </>
                : ""}
        </div>
    );
};

export default AddService;