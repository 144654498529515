import { useState, useEffect } from "react";
import TableItems from "../../../Components/UIComponents/TableItems";
import { WBButton, WBTable } from "../../../Components/UIComponents/WBComponents";
import AddMaterialMultiplier from "./Add/AddMaterialMultiplier";
import EditMaterialMultipliers from "./Edit/EditMaterialMultipliers";

const MaterialMultipliersPage = () => {
  const [tableData, setTableData] = useState();
  const [refreshTable, setRefreshTable] = useState(false);

  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [isModalEditOpen, setIsModalEditOpen] = useState(false);

  const toggleAddModal = () => { setIsModalAddOpen(!isModalAddOpen); }
  const toggleEditModal = () => { setIsModalEditOpen(!isModalEditOpen); }

  const [itemSelected, setItemSelected] = useState([]);

  const headers = ["From ($)", "To ($)", "Multiplier (%)", "Status", "Action"];

  useEffect(() => {
    setTableData(
      <WBTable tableDataOrg="/multiplierJson" tableHeaders={headers} isLocalSearch={true} filterBy="multiplier" itemsAt="multipliers">
          <TableItems 
            itemTitle="Customer Type" itemsAt="multipliers" identifiers={["from", "to", "multiplier"]} removeBranch="/removeMaterialMultiplier"
            editFunc={toggleEditModal} setRefreshTable={setRefreshTable}
            setItemSelected={setItemSelected}
          />
      </WBTable>
    );
  }, [refreshTable]);

  return (
    <div className="content-area-full">
      {isModalAddOpen && <AddMaterialMultiplier closeModalMethod={toggleAddModal} returnFunc={() => {
        setRefreshTable(!refreshTable)
      }}/>}
      {isModalEditOpen && <EditMaterialMultipliers closeModalMethod={toggleEditModal} itemSelected={itemSelected} returnFunc={() => {
        setRefreshTable(!refreshTable)
      }}/>}

      <div className="flex floating-box flex-column bg-white gap-10" style={{ padding: "42px 36px 35px 36px"}}>
        <div className="flex justify-between">
          <div className="text-base">
            <b>Material Multiplier</b>
          </div>
          <WBButton title="Add New Multiplier" func={toggleAddModal} color={"white"} />
        </div>
        {tableData}
      </div>
    </div>
  );
};

export default MaterialMultipliersPage;
