import NavigatePage from "../../Components/Settings/NavigatePage"

export const Pagination2 = (currentPageNum, totalPageNum, totalItemNum, setCurrentPageNum) => {
    var pageHeadInfo =
        <div className="pageCount">
            <div>
                <b>Page</b> {currentPageNum} of {totalPageNum}
            </div>
            <div>
                <b>Items</b> {(currentPageNum * 10 - 9) + " - " + (currentPageNum * 10 > totalItemNum ? totalItemNum : currentPageNum * 10)} of {totalItemNum}
            </div>
        </div>
    if (totalPageNum === 0 || currentPageNum === 0) { // Failsafe if bad data comes through. Not an issue if pages are not required
        return;
    }
    else if (totalPageNum === 1) { // if only one page exists
        return null;
    } else if (totalPageNum > 1 && totalPageNum < 8) {
        return (
            <div className="tableHeadInfo">
                <div id="pageInfo">
                    {pageHeadInfo}
                </div>
                <div className="servPagination" style={{ overflow: "auto" }}>
                    <div onClick={() => setCurrentPageNum(currentPageNum - 1)} className={currentPageNum === 1 ? "disabled" : ""}><NavigatePage pageNum={currentPageNum - 1} {...(currentPageNum === 1 ? { disabled: true } : {})} symbol="<" /></div>
                    {Array.from({ length: totalPageNum }, (_, index) => index + 1).map((page) => (  
                        <div onClick={() => setCurrentPageNum(page)}><NavigatePage pageNum={page} {...(currentPageNum === page ? { active: true } : {})} /></div>
                    ))}   
                    <div onClick={() => setCurrentPageNum(currentPageNum + 1)} className={currentPageNum === totalPageNum ? "disabled" : ""}><NavigatePage pageNum={currentPageNum + 1} {...(currentPageNum === totalPageNum ? { disabled: true } : {})} symbol=">" /></div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="tableHeadInfo">
                <div id="pageInfo">
                    {pageHeadInfo}
                </div>
                <div className="servPagination" style={{ overflow: "auto" }}>
                    <div onClick={() => setCurrentPageNum(currentPageNum - 1)} className={currentPageNum === 1 ? "disabled" : ""}><NavigatePage pageNum={currentPageNum - 1} {...(currentPageNum === 1 ? { disabled: true } : {})} symbol="<" /></div>
                    <div onClick={() => setCurrentPageNum(1)}><NavigatePage pageNum={1} {...(currentPageNum === 1 ? { active: true } : {})} /></div>
                    
                    {currentPageNum <= 4 && (
                        <>
                            {Array.from({ length: 4 }, (_, index) => index + 2).map((page) => (
                                <div onClick={() => setCurrentPageNum(page)} key={page}><NavigatePage pageNum={page} {...(currentPageNum === page ? { active: true } : {})} /></div>
                            ))}
                            <div onClick={() => setCurrentPageNum(6)}><NavigatePage pageNum={6} symbol="..." /></div>
                        </>
                    )}

                    {currentPageNum > 4 && currentPageNum <= totalPageNum - 4 && (
                        <>
                            <div onClick={() => setCurrentPageNum(currentPageNum - 2)}><NavigatePage pageNum={currentPageNum - 2} symbol="..." /></div>
                            <div onClick={() => setCurrentPageNum(currentPageNum - 1)}><NavigatePage pageNum={currentPageNum - 1} /></div>
                            <div onClick={() => setCurrentPageNum(currentPageNum)}><NavigatePage pageNum={currentPageNum} active /></div>
                            <div onClick={() => setCurrentPageNum(currentPageNum + 1)}><NavigatePage pageNum={currentPageNum + 1} /></div>
                            <div onClick={() => setCurrentPageNum(currentPageNum + 2)}><NavigatePage pageNum={currentPageNum + 2} symbol="..." /></div>
                        </>
                    )}

                    {currentPageNum > totalPageNum - 4 && (
                        <>
                            <div onClick={() => setCurrentPageNum(totalPageNum - 5)}><NavigatePage pageNum={totalPageNum - 5} symbol="..." /></div>
                            {Array.from({ length: 4 }, (_, index) => totalPageNum - 4 + index).map((page) => (
                                <div onClick={() => setCurrentPageNum(page)} key={page}><NavigatePage pageNum={page} {...(currentPageNum === page ? { active: true } : {})} /></div>
                            ))}
                        </>
                    )}

                    <div onClick={() => setCurrentPageNum(totalPageNum)}><NavigatePage pageNum={totalPageNum} {...(currentPageNum === totalPageNum ? { active: true } : {})} /></div>
                    <div onClick={() => setCurrentPageNum(currentPageNum + 1)} className={currentPageNum === totalPageNum ? "disabled" : ""}><NavigatePage pageNum={currentPageNum + 1} {...(currentPageNum === totalPageNum ? { disabled: true } : {})} symbol=">" /></div>
                </div>
            </div>
        )
    } 
}