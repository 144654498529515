import { useState, useContext } from 'react';
import JobList from './JobList';
import styles from './css/scheduler.module.css';
import { ScheduledJobsContext } from './SchedulePage';

const SchedulerSidebar = () => {


  const { unassignedJobs } = useContext(ScheduledJobsContext);
  // const [unassignedJobs, setunassignedJobs] = useState([]);
  const [showUnassignedJobs, setShowUnassignedJobs] = useState(true);


  const ongoingJobs = [
    // { location: 'Homebush', job_types: 'Leaking Tap / Toilet', time: '15 mins ago', trade: 'Plumbing' },
    // { location: 'Other', job_types: 'Plumbing', time: '15 mins ago', trade: 'Plumbing' },
    // { location: 'Manly', job_types: 'Leaking Tap / Toilet', time: '15 mins ago', trade: 'Plumbing' },
    // { location: 'Hornsby', job_types: 'Hot Water Service', time: '15 mins ago', trade: 'Plumbing' },
  ];

  const reminders = [
    // { location: 'Homebush', job_types: 'Leaking Tap / Toilet', time: '15 mins ago', trade: 'Plumbing' },
    // { location: 'Other', job_types: 'Plumbing', time: '15 mins ago', trade: 'Plumbing' },
    // { location: 'Manly', job_types: 'Leaking Tap / Toilet', time: '15 mins ago', trade: 'Plumbing' },
    // { location: 'Hornsby', job_types: 'Hot Water Service', time: '15 mins ago', trade: 'Plumbing' },
  ];

  return (
    <div className={styles.sidebarContainer}>
      <div className={styles.sidebarContainerInner}>
        <div className={styles.sideBarItem}>
          <div className={styles.sidebarHeader} onClick={() => setShowUnassignedJobs(!showUnassignedJobs)}>
            <p>Unassigned Jobs</p>
            <img style={showUnassignedJobs ? { transform: 'rotate(0deg)' } : { transform: 'rotate(180deg)' }} src="/images/scheduler/arrow-up.svg" alt='arrow' />
          </div>
          {
            showUnassignedJobs && (
              unassignedJobs.length > 0 ? (
                <JobList jobs={unassignedJobs} />
              ) :
                <p className={styles.noJobs}>No unassigned jobs</p>
            )
          }
        </div>
        <div className={styles.sideBarItem}>
          <div className={`${styles.sidebarHeader} ${styles.sidebarHeaderTop}`}>
            <p>Ongoing Jobs</p>
            <img src="/images/scheduler/arrow-up.svg" alt="arrow-up" />
          </div>
          <JobList jobs={ongoingJobs} />
        </div>
        <div className={styles.sideBarItem}>
          <div className={`${styles.sidebarHeader} ${styles.sidebarHeaderTop}`}>
            <div className={styles.remindersFlex}>
              <img src="/images/scheduler/bell.svg" alt="bell" />
              <p>Reminders</p>
            </div>
            <img src="/images/scheduler/arrow-up.svg" alt="arrow-up" />
          </div>
          <JobList title="Plumbing" jobs={reminders} />
        </div>
      </div>
    </div>
  );
};

export default SchedulerSidebar;
