/* eslint-disable no-unused-vars */
import LeftNavOption from "../Settings/LeftNavOption";
import LeftNavTitle from "../Settings/LeftNavTitle";
import LeftNavOrganisations from "./LeftNavOrganisations";
import { useEffect, useState, useRef } from "react";
import { WBButton, WBModal } from "../UIComponents/WBComponents";
import { PostDB } from "../DBTools";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
// import { useDebounce } from "../../lib/js/debounce";

const LeftNav = ({isCollapsed, setIsCollapsed, selectedOrgId}) => {
  const navigate = useNavigate();
   const location = useLocation();
  const [isAddOrgModalOpen, setIsAddOrgModalOpen] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [orgRefresh, setOrgRefresh] = useState(false);

  const [isPricebook, setIsPricebook] = useState(false);

  const [isLeftNavOpen, setIsLeftNavOpen] = useState(true);

  const [leftNavOrgs, setLeftNavOrgs] = useState();
  const parentRef = useRef(null);

  const toggleAddOrgModal = () => setIsAddOrgModalOpen(!isAddOrgModalOpen);
  const toggleLeftNav = () => setIsLeftNavOpen(!isLeftNavOpen);

  const BuildOrg = async (orgID) => {
    if (orgName.length > 0) {
      const response = await PostDB({
        branch: "/addOrg", json: {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ org_name: orgID })
        }
      });
      if (response.inserted_org_id !== null) {
        toast.success("Organisation has been successfully added");
        toggleAddOrgModal();
        window.location.href = `/settings/organisation/edit/${response.inserted_org_id}`; 
      }
    }
    else {
      toast.error("Please enter a valid name.")
    }
  }

  useEffect(() => {
    setLeftNavOrgs(<LeftNavOrganisations parentRef={parentRef}/>)
  }, [orgRefresh])


  useEffect(() => {
    const handleReize = () => {
      if (!isCollapsed) {
        if (window.innerWidth < 960) {
          setIsCollapsed(true);
        }
      }
    }
    window.addEventListener('resize', handleReize);
    handleReize();
    return () => window.removeEventListener('resize', handleReize);
  }, [])

  useEffect(() => {
    console.log(window.location.pathname)
    if (window.location.pathname.startsWith("/settings/pricebook")) {
      setIsPricebook(true);
    } else {
      setIsPricebook(false);
    }
  }, [location])

  return (
    <div id="leftnav">
      {isAddOrgModalOpen ? <WBModal title="Add New Organisation" closeFunction={toggleAddOrgModal} width={"20%"} customSubmit={<WBButton title="Create" color={"white"} func={async () => BuildOrg(orgName)} />}>
        <div className="popup-context" style={{ padding: "20px"}}>
          <h2>Name</h2>
          <h3 className="not-bold">Max 20 Characters</h3>
          <input type="text" maxLength={20} name="org_name" className="form-control" id="organisation_name" placeholder="Enter Organisation name" onChange={(myOrg) => setOrgName(myOrg.target.value)} autoFocus></input>
        </div>
      </WBModal> : ''}
      {/* {isAddOrgModalOpen ? <AddOrganisations closeAddOrgMethod={toggleAddOrgModal} /> : ''} */}
      {/* <nav id="leftNavBar" ref={scrollRef}
        onScroll={({ target }) => {
          setScrollPosition(target.scrollTop);
        }}> */}

      <nav id="leftNavBar" style={{ width: isCollapsed ? "60px" : "393px", scrollbarWidth: "none" }}>
        <div className="section" style={{ borderBottom: "none", paddingBottom: "0px", paddingTop: "23px", paddingLeft: isCollapsed ? "14px" : "370px", position: "fixed"}}>
          <button id="collapseButton" onClick={() => setIsCollapsed(!isCollapsed)}>
            {isCollapsed ?
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.94141 13.2788L10.2881 8.93208C10.8014 8.41875 10.8014 7.57875 10.2881 7.06542L5.94141 2.71875" stroke="#171717" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              :
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.0605 2.72125L5.71388 7.06792C5.20055 7.58125 5.20055 8.42125 5.71388 8.93458L10.0605 13.2812" stroke="#171717" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            }
          </button>
        </div>

        {!isCollapsed && (
          <div>
            {isPricebook &&
              <div className="section" style={{ paddingTop: "25px", paddingLeft: "20px"}}>
                <WBButton title="Back" func={() => {
                  setIsPricebook(false);
                  navigate(`/settings/organisation/edit/${selectedOrgId}`);
                }} icon={
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.0605 2.72125L5.71388 7.06792C5.20055 7.58125 5.20055 8.42125 5.71388 8.93458L10.0605 13.2812" stroke="#171717" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                } color={"white"} height="40px" />
              </div>
            }
            {!isPricebook && <>
              <div className="section">
                <div className="header">
                  <h4>Organisations</h4>
                  <WBButton title="Add Organisation" func={toggleAddOrgModal} color={"white"} height="40px" />
                </div>
                <div className="orgNavScroll" style={{ maxHeight: "300px", overflowY: "auto"}} ref={parentRef}>
                  {leftNavOrgs}
                </div>
              </div>

              </>
            }
            <div className="section">
              <div style={{ cursor: "pointer" }} onClick={() => {
                if (!isPricebook) {
                  setIsPricebook(true);
                  navigate("/settings/pricebook/services");
                }
              }}>
                <LeftNavTitle name="Price Book" />
              </div>
              {isPricebook && <ul>
                <LeftNavOption name="Services" link="/settings/pricebook/services" />
                <LeftNavOption name="Materials" link="/settings/pricebook/materials" />
                <LeftNavOption name="Utilities" link="/settings/pricebook/utilities" />
                <LeftNavOption name="Filters" link="/settings/pricebook/filters" />
                <LeftNavOption name="Tags" link="/settings/pricebook/tags" />
                <LeftNavOption name="Spiffs" link="/settings/pricebook/spiffs" />
                <LeftNavOption name="Categories" link="/settings/pricebook/categories" />
                <LeftNavOption name="Billable Hourly Rates" link="/settings/pricebook/billhours" />
                <LeftNavOption name="Industries" link="/settings/pricebook/industries" />
              </ul>}
            </div>

            {!isPricebook && <>
              <div className="section">
                <div className="header">
                  <h4>User Management</h4>
                </div>

                <ul>
                  <LeftNavOption
                    name="Office Users"
                    link="/settings/user_management/office_users"
                  />
                  <LeftNavOption
                    name="Field Users"
                    link="/settings/user_management/field_users"
                  />
                </ul>
              </div> 

              <div className="section">
                <LeftNavTitle name="Customer Type" />
                <ul>
                  <LeftNavOption
                    name="Customer Type"
                    link="/settings/customer_types"
                  />
                  <LeftNavOption name="Discount Logic" link="/settings/discounts" />
                  {/* <LeftNavOption
                    name="Payment Terms (temporarily removed)"
                    link="/settings/payment_terms"
                    disabled
                  /> */}
                  {/* IMPORTANT this has been temporarily removed as there is no data. This may change in the future */}
                </ul>
              </div>

              {/* <div className="section">
                <LeftNavTitle name="Pricing and Rates" />
                <ul>
                  
                  
                </ul>
              </div> */}

              <div className="section">
                <LeftNavTitle name="User Operational" />
                <ul>
                  <LeftNavOption
                    name="Service Types"
                    link="/settings/service_types"
                  />
                  <LeftNavOption name="Job Types" link="/settings/job_types" />
                  <LeftNavOption
                    name="Service Call Fees"
                    link="/settings/service_callout_fees"
                  />
                  <LeftNavOption
                    name="Service Responses"
                    link="/settings/service_response"
                  />
                </ul>
              </div>

              <div className="section">
                <LeftNavTitle name="Advanced Settings" />
                <ul>
                  <LeftNavOption name="Memberships" link="/settings/membership" />
                  <LeftNavOption
                    name="Material Multipliers"
                    link="/settings/material_multipliers"
                  />{" "}
                  {/*Spelling? */}
                </ul>
              </div>
              </>}
          </div>
        )}
      </nav>
    </div>
  );
};

export default LeftNav;