import GoogleMapReact from 'google-map-react';
import { useEffect, useState } from 'react';
import styles from './SiteMap.module.css';

const API_KEY = "AIzaSyDApmuc9zwXYEMB54qUJD3xcC4R5r9vFlk";

const Pin = () => (
    <div style={{
        position: 'absolute',
        transform: 'translate(-50%, -100%)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer'
    }}>
        <div style={{
            filter: 'drop-shadow(0 2px 2px rgba(0, 0, 0, 0.3))',
            transition: 'transform 0.2s',
            ':hover': {
                transform: 'scale(1.1)'
            }
        }}>
            <svg viewBox="0 0 24 24" width="24" height="24">
                <path
                    fill="#0094FF"
                    d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
                />
            </svg>
        </div>
    </div>
);

export default function SimpleMap({ className, address }) {

    const [coords, setCoords] = useState({
        center: {
            lat: null,
            lng: null
        },
        zoom: 11
    });

    const getLatLng = async (address) => {
        const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${API_KEY}`
        );
        const data = await response.json();

        if (data.status === "OK") {
            const { lat, lng } = data.results[0].geometry.location;
            console.log(`Latitude: ${lat}, Longitude: ${lng}`);
            setCoords(prevState => ({
                ...prevState,
                center: {
                    lat,
                    lng
                }
            }));
        } else {
            console.error("Geocoding failed:", data.status);
        }
    };

    useEffect(() => {
        getLatLng(address);
    }, [address]);

    if (coords.center.lat === null || coords.center.lng === null) {
        return null;
    }

    return (
        // Important! Always set the container height explicitly
        <div className={styles.mapImage} style={{ height: '137px', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: "" }}
                defaultCenter={coords.center}
                defaultZoom={coords.zoom}
            >
                <Pin
                    lat={coords.center.lat}
                    lng={coords.center.lng}
                />
            </GoogleMapReact>
        </div>
    );
}