import { useEffect, useContext } from "react";
import styles from "../addJobModals.module.css";
import { WBIOSToggle } from "../../../../Components/UIComponents/WBComponents";
import BookingDropdown from "./BookingDropdown";
import { JobBookingContext } from "../JobBookingContext";

const PropertyDetailsForm = ({
  isActive,
  formState,
  toggleActive,
  dispatch,
  customerIndex,
}) => {
  const { fetchedData } = useContext(JobBookingContext);
  const contactsRelationshipList = fetchedData.contactsRelationshipList || [];


  // const { bookingData } = useContext(JobBookingContext);


  useEffect(() => {
    if (customerIndex === 0 && !isActive) {
      toggleActive();
    }

    return () => {
      if (isActive === true) {
        toggleActive()
      }
    }





  }, []);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    dispatch({
      type: "UPDATE_CONTACT_FIELD",
      field: id,
      value,
      contactIndex: customerIndex,
    });
  };

  // useEffect(() => {

  //   console.log("isActive", isActive);
  //   if (isActive) {
  //     toggleActive()
  //   }

  // }, [isActive]);

  return (
    <>
      <div className={styles.propertyContactForm}>
        {customerIndex === 0 && (
          <div className={styles.sameCustomerDetails}>
            <p className={styles.fontSmall}>Same as Customer Details</p>
            <div className="switch">
              <WBIOSToggle
                active={isActive ? true : false}
                func={toggleActive}
              />
            </div>
          </div>
        )}
        <div className={styles.contactFormNames}>
          <div className={styles.contactFirstName}>
            <p className={`${styles.fontMedium} bold`}>First Name</p>
            <input
              type="text"
              id="firstName"
              value={
                formState.property_details.jobsite_contact[customerIndex]
                  ?.firstName || ""
              }
              onChange={handleInputChange}
              placeholder="Enter firstname"
            />
          </div>
          <div className={styles.contactLastName}>
            <p className={`${styles.fontMedium} bold`}>Surname</p>
            <input
              type="text"
              id="lastName"
              value={
                formState.property_details.jobsite_contact[customerIndex]
                  ?.lastName || ""
              }
              onChange={handleInputChange}
              placeholder="Enter surname"
            />
          </div>
        </div>
        <div className={styles.contactFormDetails}>
          <div className={styles.contactPhone}>
            <p className={`${styles.fontMedium} bold`}>Phone</p>
            <input
              type="text"
              id="phone"
              value={
                formState.property_details.jobsite_contact[customerIndex]
                  ?.phone || ""
              }
              onChange={handleInputChange}
              placeholder="Enter phone number"
            />
          </div>
          <div className={styles.contactMobile}>
            <p className={`${styles.fontMedium} bold`}>Mobile</p>
            <input
              type="text"
              id="mobile"
              value={
                formState.property_details.jobsite_contact[customerIndex]
                  ?.mobile || ""
              }
              onChange={handleInputChange}
              placeholder="Enter mobile number"
            />
          </div>
        </div>
        <div className={styles.contactFormDetails}>
          <div className={styles.contactEmail}>
            <p className={`${styles.fontMedium} bold`}>Email</p>
            <input
              type="text"
              id="email"
              value={
                formState.property_details.jobsite_contact[customerIndex]
                  ?.email || ""
              }
              onChange={handleInputChange}
              placeholder="Enter email"
            />
          </div>
          <div>
            <p className={`${styles.fontMedium} bold`}>
              Relationship to Customer
            </p>
            <BookingDropdown
              dropdownItems={contactsRelationshipList.map((item) => item.title)}
              width="303px"
              height="48px"
              leftitem={
                formState.property_details.jobsite_contact[customerIndex]
                  ?.relationship === ""
                  ? "Choose Relationship"
                  : contactsRelationshipList.find(
                    (item) =>
                      item.id ===
                      formState.property_details.jobsite_contact[customerIndex]
                        ?.relationship
                  )?.title || "Choose Relationship"
              }
              value={
                formState.property_details.jobsite_contact[customerIndex]
                  ?.relationship || ""
              }
              onChange={(value) =>
                dispatch({
                  type: "UPDATE_CONTACT_FIELD",
                  field: "relationship",
                  value,
                  contactIndex: customerIndex,
                })
              }
            />
          </div>
        </div>
        <div className={styles.contactFormRelationship}>
          <div className={styles.contactFormNotes}>
            <p className={`${styles.fontMedium} bold`}>Additional Notes</p>
            <input
              type="text"
              id="notes"
              value={
                formState.property_details.jobsite_contact[customerIndex]
                  ?.notes || ""
              }
              onChange={handleInputChange}
              placeholder="Enter additional notes"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyDetailsForm;
