import { useEffect, useState } from "react";
import { WBButton, WBIOSToggle } from "../../../../Components/UIComponents/WBComponents";
import { PostDB } from "../../../../Components/DBTools";
import { toast } from "react-toastify";
import { validateStr } from "../../../../lib/js/validateInput";

const EditIndustry = ({ closeModalMethod, itemSelected, returnFunc }) => {
    const [id, setID] = useState();
    const [industryName, setIndustryName] = useState();
    const [active, setActive] = useState(true);
    const [changesMade, setChangesMade] = useState(false);

    const toggleStatus = () => setActive(status => { setChangesMade(true); return !status });
    
    useEffect(() => {
        setID(itemSelected.id);
        setIndustryName(itemSelected.name);
        setActive(itemSelected.status === 1 ? true : false);
    }, []);

    const Save = async () => {
        if (!validateStr("Name", industryName)) return;

        let bodyObj = {
            id: id,
            name: industryName,
            status: active === true ? 1 : 0,
            org_id: 1,
            trade_id: 1
        }
        const response = await PostDB({
            branch: "/editIndustry", json: {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(bodyObj)
            }
        });
        if (response.api_status === 'success') {
            toast.success('Industry has been successfully added');
            if (returnFunc) returnFunc();
            closeModalMethod();
        } else {
            toast.error('There was an error saving this Industry. Check your fields and try again');
        }
    };

    const handleBGClick = (e) => {
        const background = document.querySelector(".bg-darkblue");
        if (background === e.target && !changesMade) {
            closeModalMethod();
        } else if (background === e.target && changesMade && window.confirm("You are about to leave this page? Are you sure?")) {
            closeModalMethod()
        }
    }

    return (
        <div className="add-utility-page">
            <div className="bg-darkblue" onClick={(e) => handleBGClick(e)}>
                <div className="content">
                    <div className="border-bottom bg-white p-20 gap-10" style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="button-row left">
                            <h4 className="title"><b>Edit Industry</b></h4>
                        </div>
                    </div>
                    <div className="border-bottom bg-white h-full gap-10">
                    <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="code">Industry ID:</label>
                                    <p className="pl-10" id="code">{id ? id : "N/A"}</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="code" className="form-label">
                                        Industry Name
                                    </label>
                                    <input
                                        type="text"
                                        name="code"
                                        className="half"
                                        id="code"
                                        placeholder="enter industry name"
                                        value={industryName}
                                        onChange={e => { setChangesMade(true); setIndustryName(e.target.value); }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input">
                                    <p>Note: This will update the industry name for every Category associated to this Industry. All services who use this Industry will have their Industry name updated to reflect this change.</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col">
                                <div className="input">
                                    <label htmlFor="tradesmanHours" className="form-label">
                                        Tradesman Hours
                                    </label>
                                    <input
                                        type="number"
                                        name="tradesmanHours"
                                        className="half"
                                        id="tradesmanHours"
                                        placeholder="Enter tradesman hourly rate ($)"
                                        value={tradesmanHours}
                                        onChange={e => { setChangesMade(true); setTradesmanHours(e.target.value); }}
                                    />
                                </div>
                                <div className="input">
                                    <label htmlFor="helperHours" className="form-label">
                                        Helper Hours
                                    </label>
                                    <input
                                        type="number"
                                        name="helperHours"
                                        className="half"
                                        id="helperHours"
                                        placeholder="Enter helper hourly rate ($)"
                                        value={helperHours}
                                        onChange={e => { setChangesMade(true); setHelperHours(e.target.value); }}
                                    />
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="footer flex flex-row space-between" style={{ flexWrap: "wrap" }}>
                        <div className="flex flex-row gap-10" style={{ alignItems: "center"}}>
                            <p>Status</p>
                            <WBIOSToggle active={active} func={() => toggleStatus()} />
                        </div>
                        <div className="flex flex-row gap-10">
                            <WBButton title="Cancel" color="white" func={() => { if (!changesMade) closeModalMethod(); else { if (window.confirm("You are about to leave this page? Are you sure?")) closeModalMethod() } }} />
                            <WBButton title="Save" color={"green"} func={() => Save()} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditIndustry;