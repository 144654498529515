import React from "react";
import { useState, useEffect, useContext } from "react";
import styles from "./addJobModals.module.css";
import CustomerDetails from "./components/CustomerDetails";
import ModalNavigation from "./components/ModalNavigation";
import NotesInput from "./components/NotesInput";
import { JobBookingContext } from "./JobBookingContext";
import EditCustomerDetailsForm from "./components/EditCustomerDetailsForm";
import CancellationConfirmation from "./components/CancellationConfirmation";
import { useJobCustomerModals } from "./hooks/useJobCustomerModals";
import SummaryCustomerDetails from "./components/SummaryCustomerDetails";
import EditPropertyDetailsForm from "./components/EditPropertyDetailsForm";
import SummaryPropertyDetails from "./components/SummaryPropertyDetails";
import EditJobDetailsForm from "./components/EditJobDetailsForm";

const JobSummaryModal = ({
  isOpen,
  closeModal,
  customerDetailsModal,
  jobDetailsModal,
  propertyDetailsModal,
  summaryModal,
}) => {
  const [isCreateBooking, setIsCreateBooking] = useState(false);
  const [isEditCustomerDetails, setIsEditCustomerDetails] = useState(false);
  const [isCancelBooking, setIsCancelBooking] = useState(false);
  const [isEditPropertyDetails, setIsEditPropertyDetails] = useState(false);
  const [isEditJobDetails, setIsEditJobDetails] = useState(false);
  const { bookingData, fetchedData, selectedJobAddress, jobsiteID } = useContext(JobBookingContext);
  const paymentTermsList = fetchedData.paymentTermsList;
  const customerTypesList = fetchedData.customerTypesList;
  const paymentTerms =
    paymentTermsList.find(
      (term) => term.id === bookingData.customerDetails.payment_term_id
    )?.title || "N/A";
  const customerTypes =
    customerTypesList.find(
      (type) => type.id === bookingData.customerDetails.customer_type_id
    )?.title || "N/A";

  const toggleState = (setter) => () => setter((prev) => !prev);

  const handleCreateBooking = toggleState(setIsCreateBooking);
  const handleCancelBooking = toggleState(setIsCreateBooking);
  const handleCloseBooking = toggleState(setIsCancelBooking);
  const handleHideCloseBooking = toggleState(setIsCancelBooking);
  const handleEditCustomerDetails = toggleState(setIsEditCustomerDetails);
  const cancelEditCustomerDetails = toggleState(setIsEditCustomerDetails);
  const handleEditPropertyDetails = toggleState(setIsEditPropertyDetails);
  const cancelEditPropertyDetails = toggleState(setIsEditPropertyDetails);
  const handleEditJobDetails = () => setIsEditJobDetails(true);

  const { addJobBooking } = useJobCustomerModals();

  const handleConfirmBooking = async () => {
    console.log("Booking data:", bookingData);
    await addJobBooking(bookingData.jobDetails);
    closeModal();
    setTimeout(() => {
      window.location.reload();
    }, 750);
  };

  useEffect(() => {
    if (!isOpen) {
      setIsCancelBooking(false);
      setIsCreateBooking(false);
    }
    console.log(getJobsiteAddress());
    console.log(bookingData);
  }, [isOpen]);

  const getPropertyDetails = () =>
    bookingData?.propertyDetails?.property_details || bookingData?.propertyDetails
  const getJobsiteContact = () => getPropertyDetails()?.jobsite_contact || {};
  const getJobsiteAddress = () => getPropertyDetails()?.jobsite_address || getPropertyDetails() || {};

  const mappedJobDetails = {
    cust_id: bookingData.jobDetails.cust_id,
    side_id: jobsiteID,
    industry: fetchedData.industriesList?.find(ind => ind.id === bookingData.jobDetails.industryId)?.name || 'N/A',
    category: fetchedData.categoryTypesList?.find(cat => cat.id === bookingData.jobDetails.categoryId)?.title || 'N/A',
    jobPriority: fetchedData.jobPriorityList?.find(jp => jp.id === bookingData.jobDetails.jobPriorityId)?.title || 'N/A',
    serviceType: fetchedData.serviceTypesList?.find(st => st.id === bookingData.jobDetails.serviceTypeId)?.title || 'N/A',
    jobTypes: bookingData.jobDetails.jobTypeIds?.map(id => {
      const jobType = fetchedData.industriesList
        ?.flatMap(industry => industry.jobTypes || [])
        .find(jt => jt.id === id);
      return jobType ? jobType.title : 'N/A';
    }) || [],
    callOutFee: fetchedData.callOutFeesList?.find(cof => cof.id === bookingData.jobDetails.callOutFeeId)?.price || 'N/A',
    preferredDate: bookingData.jobDetails.preferredDate || 'N/A',
    preferredTime: bookingData.jobDetails.preferredTime || 'N/A',
    jobDescription: bookingData.jobDetails.jobDescription || 'N/A',
    specialBooking: fetchedData.specialBookingsList?.find(sb => sb.id === bookingData.jobDetails.specialBookingId)?.title || 'N/A'
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContentJobDetails}>
        <div className={styles.modalBody}>
          <ModalNavigation
            customerDetailsModal={customerDetailsModal}
            propertyDetailsModal={propertyDetailsModal}
            jobDetailsModal={jobDetailsModal}
            summaryModal={summaryModal}
            closeModal={closeModal}
            selectedTab={4}
          />
          <div className={styles.modalInformationContainer}>
            <div className={styles.modalLeft}>
              <h3 className={`${styles.fontBig} bold`}>Booking Summary</h3>
              {isEditCustomerDetails ? (
                <div>
                  <EditCustomerDetailsForm
                    handleEditCustomerDetails={handleEditCustomerDetails}
                    isEdit={isEditCustomerDetails}
                    cancel={cancelEditCustomerDetails}
                    customerIndex={0}
                  />
                </div>
              ) : (
                <SummaryCustomerDetails
                  bookingData={bookingData}
                  paymentTerms={paymentTerms}
                  customerTypes={customerTypes}
                  handleEditCustomerDetails={handleEditCustomerDetails}
                />
              )}
              <div className={styles.summaryPropertyAddress}>
                <div className={styles.headerContainer}>
                  <p className="bold">Property Address</p>
                </div>
                <div className={styles.summaryPropertyFlexContainer}>
                  <img src="/images/client/locationpin.svg" alt="location" />
                  <p>
                    {/* {`${getJobsiteAddress().address_line || ""}, 
                    ${getJobsiteAddress().suburb || ""}, 
                    ${getJobsiteAddress().state || ""} 
                    ${getJobsiteAddress().postcode || ""}`} */}
                    {selectedJobAddress}
                  </p>
                </div>
                <div className={styles.summaryMap}>
                  <img src="/images/client/map.svg" alt="map" />
                </div>
              </div>
              {isEditPropertyDetails ? (
                <EditPropertyDetailsForm
                  handleEditPropertyDetails={handleEditPropertyDetails}
                  isEdit={isEditPropertyDetails}
                  cancel={cancelEditPropertyDetails}
                />
              ) : (
                <SummaryPropertyDetails
                  handleEditPropertyDetails={handleEditPropertyDetails}
                  isEdit={isEditPropertyDetails}
                  cancel={cancelEditPropertyDetails}
                  getJobsiteContact={getJobsiteContact}
                  getJobsiteAddress={getJobsiteAddress}
                />
              )}
              {isEditJobDetails ? (
                <EditJobDetailsForm
                  handleEditJobDetails={handleEditJobDetails}
                  isEdit={isEditJobDetails}
                  cancel={() => setIsEditJobDetails(false)}
                />
              ) : (
                <div className={styles.summaryJobDetails}>
                  <div className={styles.headerContainer}>
                    <p className={`${styles.fontMedium} bold`}>Job Details</p>
                    <div className={styles.editButton} onClick={handleEditJobDetails}>
                      <img src="/images/client/edit.svg" alt="edit" />
                    </div>
                  </div>
                  <div className={styles.jobDetailsSection}>
                    <div className={styles.jobDetailsLeft}>
                      <div className={styles.jobDetailItem}>
                        <p className="bold">Job Type</p>
                        <p className={styles.jobDetailsGap}>
                          {mappedJobDetails.industry}
                        </p>
                      </div>
                      <div className={styles.jobDetailItem}>
                        <p className="bold">Job Priority</p>
                        <p className={styles.jobDetailsGap}>
                          {mappedJobDetails.jobPriority}
                        </p>
                      </div>
                      <div className={styles.jobDetailItem}>
                        <p className="bold">Industry</p>
                        <p className={styles.jobDetailsGap}>
                          {mappedJobDetails.industry}
                        </p>
                      </div>
                    </div>
                    <div className={styles.jobDetailsRight}>
                      <div className={styles.jobDetailItem}>
                        <p className="bold">Service Type</p>
                        <p className={styles.jobDetailsGap}>
                          {mappedJobDetails.serviceType}
                        </p>
                      </div>
                      <div className={styles.jobDetailItem}>
                        <p className="bold">Callout Fee</p>
                        <p className={styles.jobDetailsGap}>
                          {mappedJobDetails.callOutFee}
                        </p>
                      </div>
                      <div className={styles.jobDetailItem}>
                        <p className="bold">Preferred Date & Time</p>
                        <div className={styles.jobDetailsFlexItem}>
                          <img
                            src="/images/client/job-calendar.svg"
                            alt="calendar"
                          />
                          <p>
                            {mappedJobDetails.preferredDate
                              ? new Date(mappedJobDetails.preferredDate).toLocaleDateString()
                              : "No date selected"}
                          </p>
                          <p> - </p>
                          <img src="/images/client/job-clock.svg" alt="clock" />
                          <p>{mappedJobDetails.preferredTime}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.jobDescriptionSection}>
                    <p className={`${styles.fontMedium} bold`}>Job Description</p>
                    <div className={styles.jobDescriptionText}>
                      <p>{mappedJobDetails.jobDescription}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.modalRight}>
              <CustomerDetails />
              <NotesInput />
              {!isCreateBooking ? (
                <>
                  <div className={styles.buttonContainer}>
                    <div
                      className={styles.cancelButton}
                      onClick={handleCloseBooking}
                    >
                      <p>Cancel</p>
                    </div>
                    <div className={styles.subButtonContainer}>
                      <div
                        className={styles.backButton}
                        onClick={jobDetailsModal}
                      >
                        <p>Back</p>
                      </div>
                      <div
                        className={`${styles.nextButton} ${styles.createBookingButton}`}
                        onClick={handleCreateBooking}
                      >
                        <p>Create Booking</p>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className={styles.confirmationSection}>
                  <p className={`${styles.fontBig} bold`}>Please Confirm</p>
                  <p className={styles.margin12}>
                    Are you sure you want to create this booking?
                  </p>
                  <div className={styles.confirmButtonContainer}>
                    <div
                      className={styles.confirmButtonCancel}
                      onClick={handleCancelBooking}
                    >
                      <p>Cancel</p>
                    </div>
                    <div
                      className={styles.confirmButtonConfirm}
                      onClick={handleConfirmBooking}
                    >
                      <p>Confirm</p>
                    </div>
                  </div>
                </div>
              )}
              {isCancelBooking && (
                <CancellationConfirmation
                  closeModal={closeModal}
                  handleHideCloseBooking={handleHideCloseBooking}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobSummaryModal;