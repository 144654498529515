import { useState, useEffect, createContext } from 'react';
import styles from './css/scheduler.module.css';
import SchedulerSidebar from './SchedulerSidebar';
import SchedulerMenubar from './SchedulerMenubar';
import SchedulerCalendar from './SchedulerCalendar';
import MiniTechProfileModal from "./MiniTechProfileModal";
import { ScheduleProvider } from "./context/ScheduleContext";
import TimeBlockerModal from "./TimeBlockerModal";
import SMSModal from './components/SMSModal/SMSModal';
import { useOrganisationContext } from "../../lib/contexts/OrganisationContext";

const ScheduledJobsContext = createContext();


const SchedulePage = () => {
  const [isGridLocked, setIsGridLocked] = useState(true);
  const [workspaceItems, setWorkspaceItems] = useState([]);
  const [unassignedJobs, setunassignedJobs] = useState([]);
  const [draggedItem, setDraggedItem] = useState(0);

  // const [schedules, setSchedules] = useState([
  //   // {
  //   //   id: 1,
  //   //   startIndex: 2,
  //   //   span: 2,
  //   //   status: 'scheduled',
  //   //   rowIndex: 1,
  //   //   location: 'Liverpool',
  //   //   issue: 'Leaking Tap/Toilet',
  //   // },
  //   // {
  //   //   id: 2,
  //   //   startIndex: 0,
  //   //   span: 2,
  //   //   status: 'completed',
  //   //   rowIndex: 4,
  //   //   location: 'Liverpool',
  //   //   issue: 'Leaking Tap/Toilet',
  //   // },
  //   // {
  //   //   id: 3,
  //   //   startIndex: 3,
  //   //   span: 2,
  //   //   status: 'started',
  //   //   rowIndex: 2,
  //   //   location: 'Manchester',
  //   //   issue: 'Faulty Lighting',
  //   // },
  //   // {
  //   //   id: 4,
  //   //   startIndex: 5,
  //   //   span: 2,
  //   //   status: 'ongoing',
  //   //   rowIndex: 3,
  //   //   location: 'Birmingham',
  //   //   issue: 'Leaking Pipe',
  //   // },
  //   // {
  //   //   id: 5,
  //   //   startIndex: 7,
  //   //   span: 2,
  //   //   status: 'scheduled',
  //   //   rowIndex: 1,
  //   //   location: 'London',
  //   //   issue: 'Faulty Electrical Outlet',
  //   // },
  //   // {
  //   //   id: 6,
  //   //   startIndex: 2,
  //   //   span: 2,
  //   //   status: 'completed',
  //   //   rowIndex: 9,
  //   //   location: 'Leeds',
  //   //   issue: 'Clogged Drain',
  //   // },
  //   // {
  //   //   id: 7,
  //   //   startIndex: 3,
  //   //   span: 3,
  //   //   status: 'completed',
  //   //   rowIndex: 10,
  //   //   location: 'Liverpool',
  //   //   issue: 'Leaking Tap/Toilet',
  //   // },
  // ]);

  const [workers, setWorkers] = useState([]);

  const organisation_id = useOrganisationContext();


  useEffect(() => {

    console.log("---- LOADING all jobs ----");
    const fetchUnassignedJobs = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_NODEJSAPI}/jobs/getAllJobs`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          }
        }).then(data => data.json());

        const updatedResponse = response.map(job => {

          const time = new Date(job.time);
          return {
            ...job,
            time: timeSince(time)
          }
        })

        setunassignedJobs(updatedResponse);
      } catch (error) {
        console.log("Error fetching jobs:", error);
      }
    }

    fetchUnassignedJobs();

  }, [draggedItem])

  function timeSince(date) {
    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);

    if (seconds < 60) return `${seconds} seconds ago`;
    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) return `${minutes} minutes ago`;
    const hours = Math.floor(minutes / 60);
    if (hours < 24) return `${hours} hours ago`;
    const days = Math.floor(hours / 24);
    return `${days} days ago`;
  }


  const handleWorkspaceDragOver = e => {
    e.preventDefault();
    e.currentTarget.classList.add(styles.workspaceDragOver);
  };

  const handleWorkspaceDragLeave = e => {
    e.currentTarget.classList.remove(styles.workspaceDragOver);
  };

  // const handleWorkspaceDrop = e => {
  //   e.preventDefault();
  //   e.currentTarget.classList.remove(styles.workspaceDragOver);

  //   if (workspaceItems.length >= 10) {
  //     console.warn('Maximum workspace items reached (2 rows)');
  //     return;
  //   }

  //   try {
  //     const schedule = JSON.parse(e.dataTransfer.getData('application/json'));
  //     console.log('Dropped schedule:', schedule);

  //     // Check if item is already in workspace
  //     const isAlreadyInWorkspace = workspaceItems.some(item => item.id === schedule.id);
  //     if (isAlreadyInWorkspace) {
  //       console.warn('Item already exists in workspace');
  //       return;
  //     }

  //     // Add to workspace
  //     setWorkspaceItems(prev => [...prev, schedule]);

  //     // Remove from calendar if it came from calendar
  //     if (schedule.fromCalendar) {
  //       setSchedules(prev => prev.filter(item => item.id !== schedule.id));
  //     }
  //   } catch (error) {
  //     console.error('Error parsing drag data:', error);
  //   }
  // };

  useEffect(() => {
    const fetchTechnicians = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_NODEJSAPI}/technicians/${organisation_id}`);
        const data = await response.json();
        let sortedTechnicians = {};
        data.forEach(tech => {
          const trade = tech.trade_type.toLowerCase();
          if (!sortedTechnicians.hasOwnProperty(trade)) {
            sortedTechnicians[trade] = [];
          }
          sortedTechnicians[trade].push({
            id: tech.tech_id,
            firstName: tech.first_name,
            lastName: tech.last_name,
            status: "Available",
            apprentices: [],
            trade: tech.trade_type
          })
        })
        setWorkers(sortedTechnicians);
      } catch (error) {
        console.error('Error fetching user data:', error);
        return [];
      }

    };

    fetchTechnicians();

  }, [organisation_id]);

  return (
    <ScheduleProvider>
      <ScheduledJobsContext.Provider value={{ unassignedJobs, setunassignedJobs, setDraggedItem }}>
        <div className={styles.schedulerContainer}>
          <SchedulerSidebar />
          <div className={styles.schedulerContent}>
            <div className={styles.workspaceContainer}>
              <div className={styles.workspaceHeader}>
                <img src="/images/scheduler/workspace.svg" alt="workspace" />
                <p>Workspace</p>
              </div>
              <div
                className={styles.workspaceContent}
                onDragOver={handleWorkspaceDragOver}
                onDragLeave={handleWorkspaceDragLeave}
              // onDrop={handleWorkspaceDrop}
              >
                {workspaceItems.map((item, index) => (
                  <div
                    key={index}
                    className={styles.workspaceItem}
                    draggable="true"
                    onDragStart={e => {
                      e.dataTransfer.setData(
                        'application/json',
                        JSON.stringify({
                          ...item,
                          fromWorkspace: true,
                        })
                      );
                    }}
                  >
                    <div className={styles.flexMinutes}>
                      <p className={styles.workspaceRegular}>{item.location || 'Unknown Location'}</p>
                      <p className={styles.workspaceRegular}>15 mins ago</p>
                    </div>
                    <p className={styles.workspaceBold}>{item.issue || 'No Description'}</p>
                  </div>
                ))}
              </div>
            </div>
            <SchedulerMenubar isGridLocked={isGridLocked} setIsGridLocked={setIsGridLocked} />

            <SchedulerCalendar
              isGridLocked={isGridLocked}
              onScheduleMove={schedule => {
                setWorkspaceItems(prev => prev.filter(item => item.id !== schedule.id));
              }}
              workers={workers}
            />
          </div>
          <MiniTechProfileModal />
          <TimeBlockerModal />
          <SMSModal />
        </div>
      </ScheduledJobsContext.Provider>
    </ScheduleProvider>
  );
};

export default SchedulePage;

export {
  ScheduledJobsContext
}
